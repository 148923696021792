import { useSelector } from "react-redux";
import Router from "next/router";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getScript } from "@kikoff/client-utils/src/dom";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { useBackendExperiment } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import { selectFeatureFlag } from "./page";
import { selectIsBasic } from "./shopping";
import { selectHasActiveSecuredCard } from "./user";

export type TaxFilingMethod = "basic" | "premium";

export namespace TaxFiling {
  export const feeCents = 900;
  export const filingYear = 2023;
}

const initialState = {
  currentYearFiling: null as web.public_.IFiling,
  taxRefunds: null as web.public_.ITaxRefund[],
};

export type ColumnTaxState = typeof initialState;

const taxFilingSlice = createSlice({
  name: "columnTax",
  initialState,
  reducers: {
    setCurrentYearFiling(
      state,
      { payload }: PayloadAction<ColumnTaxState["currentYearFiling"]>
    ) {
      state.currentYearFiling = payload;
    },
    setTaxRefunds(
      state,
      { payload }: PayloadAction<ColumnTaxState["taxRefunds"]>
    ) {
      state.taxRefunds = payload;
    },
  },
});

const { actions } = taxFilingSlice;
export const {} = actions;
export default taxFilingSlice.reducer;

export const selectCurrentYearFiling = createLoadableSelector(
  () => (state: RootState) => state.taxFiling.currentYearFiling,
  {
    loadAction: () => fetchCurrentYearTaxFiling(),
  }
);

export const selectTaxRefunds = () => (state: RootState) =>
  state.taxFiling.taxRefunds;

export const fetchCurrentYearTaxFiling = () =>
  thunk((dispatch) =>
    webRPC.ColumnTax.getFiling({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCurrentYearFiling(data.filing));
          return data;
        },
        _DEFAULT: handleFailedStatus("Failed get tax return link"),
      })
    )
  );

export const fetchTaxRefunds = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.ColumnTax.getFinalizedTaxRefunds({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setTaxRefunds(data.taxRefunds));
            return data;
          },
          _DEFAULT: handleFailedStatus("Failed to get tax refunds"),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { taxRefunds } = getState().taxFiling;

        return Promise.resolve(taxRefunds || dispatch(fetchTaxRefunds()));
      }),
  }
);

declare const ColumnTax: {
  openModule(options: {
    userUrl: string;
    environment: string;
    onClose: () => void;
    style?: React.CSSProperties;
  }): void;
};

export const openColumnTaxModule = ({
  onClose,
  onCloseUrl,
}: { onClose?: () => void; onCloseUrl?: string } = {}) =>
  thunk((dispatch) =>
    webRPC.ColumnTax.submitFiling({}).then(
      handleProtoStatus({
        SUCCESS({ url }) {
          return getScript("https://app.columnapi.com/column-tax.js").then(
            () => {
              ColumnTax.openModule({
                userUrl: url,
                environment: process.env.REALLY_PRODUCTION
                  ? "production"
                  : "sandbox",
                onClose() {
                  dispatch(fetchCurrentYearTaxFiling());
                  onClose?.();
                  if (onCloseUrl) {
                    Router.replace(onCloseUrl);
                  }
                },
              });
            }
          );
        },
        _DEFAULT: handleFailedStatus("Failed to fetch tax return URL"),
      })
    )
  );

type TaxFilingVariant = "control" | "no_sc" | "has_sc";

export const useTaxFilingVariant = () => {
  const variant = useBackendExperiment("taxFiling") as TaxFilingVariant;
  const hasScFF = useSelector(
    selectFeatureFlag("tax_filing_experiment_variant_2")
  );

  if (variant === "has_sc" && !hasScFF) {
    return "control";
  }

  return variant;
};

export const useTaxFilingEligible = () => {
  const variant = useTaxFilingVariant();
  const [isBasic, loading] = useSelector(selectIsBasic.load());
  const hasActiveSecuredCard = useSelector(selectHasActiveSecuredCard());

  const eligible = (() => {
    if (variant === "no_sc") {
      return !isBasic;
    }

    if (variant === "has_sc") {
      return hasActiveSecuredCard;
    }

    return false;
  })();

  return [eligible, loading];
};
