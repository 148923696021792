import React, { forwardRef, useRef, useState } from "react";
import InputMask, { Props as InputMaskProps } from "react-input-mask";

import { createPropsProvider, mergeRefs } from "@kikoff/client-utils/src/react";
import useId from "@kikoff/hooks/src/useId";
import useUpdateEffect from "@kikoff/hooks/src/useUpdateEffect";
import { combineClasses } from "@kikoff/utils/src/string";

import ContainerButton from "../buttons/ContainerButton";
import Collapsible from "../containers/Collapsible";

import styles from "./TextInput.module.scss";

declare module "react-input-mask" {
  export interface Props {
    formatChars?: Record<string, string>;
  }
}

declare namespace TextInput {
  type Props<As extends ElementCreatable = "input"> = (
    | InputMaskProps
    | (React.ComponentPropsWithoutRef<As> & {
        format?(str: string): string;
        as?: As;
      })
  ) & {
    variant: "outlined";
    label?: string;
    abbreviation?: string;
    leadingIcon?: React.ReactNode;
    valid?: boolean;
    error?: Error;
    actions?: any;
    centered?: boolean;
    contentFor?: {
      containerEnd?: React.ReactNode;
    };
  };
  namespace TextOnly {
    type Props = React.ComponentPropsWithoutRef<"input"> & {
      format?(str: string): string;
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const TextInput = Object.assign(
  forwardRef(
    <As extends ElementCreatable = "input">(
      argProps: TextInput.Props<As>,
      _ref: React.ForwardedRef<HTMLDivElement>
    ) => {
      const {
        className,
        variant,
        label,
        abbreviation,
        valid = true,
        error: _error,
        actions,
        leadingIcon,
        id: _id,
        style,
        defaultValue,
        contentFor,
        centered,
        ..._props
      } = TextInput.PropsProvider.useMerge(argProps);

      const format = ((): ((str: string) => string) => {
        if ("format" in _props && _props.format) {
          const fmt = _props.format;
          delete _props.format;
          return fmt;
        }
        return (str) => str;
      })();

      const [open, setOpen] = useState(!!(defaultValue || _props.value));
      const [focused, setFocused] = useState(false);

      const error = useRef(_error);
      if (_error) error.current = _error;

      const ref = useRef(null);

      useUpdateEffect(() => {
        setOpen(focused || !!props.value);
      }, [!!_props.value]);

      const props = {
        ..._props,
        defaultValue:
          defaultValue == null ? defaultValue : format(defaultValue as string),
        id: useId(_id),
        onChange(e) {
          const { value } = e.currentTarget;

          if (!focused) setOpen(!!value);

          e.currentTarget.value = format(e.currentTarget.value);

          // @ts-expect-error
          _props.onChange?.(e);
        },
        onFocus(e) {
          setOpen(true);
          setFocused(true);

          // @ts-expect-error
          _props.onFocus?.(e);
        },
        onBlur(e) {
          if (e.currentTarget) setOpen(!!e.currentTarget.value);
          setFocused(false);

          // @ts-expect-error
          _props.onBlur?.(e);
        },
        className: combineClasses(
          styles["input"],
          !label && styles["no-label"],
          centered && styles["centered-input"]
        ),
      };

      return (
        <div
          className={combineClasses(
            styles["text-input"],
            !valid && styles.invalid,
            styles[variant],
            open && styles.open,
            focused && styles.focused,
            leadingIcon && styles["has-icon"],
            className
          )}
          style={style}
          ref={mergeRefs(ref, _ref)}
        >
          <div className={styles.inner}>
            {leadingIcon && (
              <label htmlFor={props.id} className={styles.icon}>
                {leadingIcon}
              </label>
            )}
            <div className={styles["input-container"]}>
              {"mask" in props ? (
                <InputMask {...(props as InputMaskProps)} />
              ) : (
                (() => {
                  const Component = props.as || "input";
                  return (
                    // @ts-expect-error
                    <Component {...props} />
                  );
                })()
              )}
              {label && (
                <label htmlFor={props.id} className={styles.label}>
                  {label}
                </label>
              )}
            </div>
            {actions && (
              <div className={styles.actions}>
                {React.Children.toArray(actions)}
              </div>
            )}
          </div>
          <Collapsible collapse={!_error} innerClassName={styles.error}>
            {error.current?.message}
          </Collapsible>
          {contentFor?.containerEnd}
        </div>
      );
    }
  ),
  {
    PropsProvider: createPropsProvider<TextInput.Props>("TextInput"),
    TextOnly: forwardRef(
      (
        {
          format,
          onChange,
          className,
          defaultValue,
          ...props
        }: TextInput.TextOnly.Props,
        ref: React.Ref<HTMLInputElement>
      ) => {
        return (
          <input
            ref={ref}
            type="text"
            className={combineClasses(styles["text-only"], className)}
            {...(defaultValue != null && {
              defaultValue: format ? format(`${defaultValue}`) : defaultValue,
            })}
            onChange={(e) => {
              if (format) e.currentTarget.value = format(e.currentTarget.value);
              onChange?.(e);
            }}
            {...props}
          />
        );
      }
    ),
  }
);

export default TextInput;

export declare namespace TextInputAction {
  export type Props = React.ComponentProps<typeof ContainerButton>;
}

export function TextInputAction({
  className,
  children,
  ...props
}: TextInputAction.Props) {
  return (
    <ContainerButton
      tabIndex={-1}
      className={combineClasses(styles["text-input-action"], className)}
      {...props}
    >
      {children}
    </ContainerButton>
  );
}

export declare namespace TextInputAction.Visibility {
  export interface Props {
    onClick(): void;
    hidden: boolean;
  }
}

TextInputAction.Visibility = Object.assign(
  ({ onClick, hidden }: TextInputAction.Visibility.Props) => (
    <TextInputAction onClick={onClick} className={styles.visibility}>
      {hidden ? "" : ""}
    </TextInputAction>
  ),
  {
    propsWithState<T extends Partial<TextInput.Props> = {}>(
      [hidden, setHidden],
      props?: T
    ) {
      // destructuring here instead of in parameter because of TS2322: '{}'
      // is assignable to the constraint of type 'T', but 'T' could be
      // instantiated with a different subtype of constraint
      // 'Partial<TextInputProps>'
      const { type = "text", actions = [] } = props || {};
      return {
        ...props,
        type: hidden ? "password" : type,
        actions: actions.concat(
          <TextInputAction.Visibility
            key="Visibility"
            hidden={hidden}
            onClick={() => {
              setHidden(!hidden);
            }}
          />
        ),
      };
    },
  }
);

export declare namespace TextInputAction.Clear {
  export interface Props {
    onClick(): void;
  }
}
TextInputAction.Clear = Object.assign(
  ({ onClick }: TextInputAction.Clear.Props) => (
    <TextInputAction onClick={onClick} className={styles.visibility}>
      
    </TextInputAction>
  )
);
