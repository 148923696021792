import { useMemo } from "react";

let currentId = 0;

export function resetId() {
  currentId = 0;
}
export default function useId(defaultId?: string) {
  const appId = useMemo(() => `appId-${currentId++}`, []);
  return defaultId || appId;
}
