import { Middleware } from "redux";

const protoToObject: Middleware = (store) => (next) => (action) => {
  action.payload = (function convertDeep(payload) {
    if (!payload) return payload;
    if (payload.$type)
      return payload.$type.toObject(payload, { defaults: true });
    if (payload instanceof Array) return payload.map(convertDeep);
    if (typeof payload === "object")
      return Object.fromEntries(
        Object.entries(payload).map(([key, value]) => [key, convertDeep(value)])
      );
    return payload;
  })(action.payload);

  next(action);
};

export default protoToObject;
