import { LayoutComponent, OverlayComponent, OverlayConfig } from "../types";

const createOverlayBuilder = <
  RenderContext extends any,
  Extensions extends Pick<
    OverlayComponent,
    "Layout" | "config" | "returnValueSchema"
  > = {}
>(
  extensions?: Extensions
) =>
  Object.assign(
    <C extends React.FC<any>>(Component: C) =>
      Object.assign(Component, extensions),
    {
      config: <Config extends OverlayConfig<RenderContext>>(config: Config) =>
        createOverlayBuilder<RenderContext, Extensions & { config: Config }>({
          ...extensions,
          config,
        }),
      layout: <LayoutType extends LayoutComponent>(Layout: LayoutType) =>
        createOverlayBuilder<
          RenderContext,
          Extensions & { Layout: LayoutType }
        >({ ...extensions, Layout }),
      returnValue: <T extends any>() =>
        createOverlayBuilder<
          RenderContext,
          Extensions & { returnValueSchema: T }
        >(extensions as typeof extensions & { returnValueSchema: T }),
    }
  );
export default createOverlayBuilder;
