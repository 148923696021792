import { useEffect } from "react";

import usePersisted from "./usePersisted";

export default function useTheme(
  defaultValue: usePersisted.StoreKeys["KIKOFF:theme"] = "light"
) {
  // Enable when dark theme is stable
  // const [theme] = usePersisted("KIKOFF:theme", "system");
  const [theme] = usePersisted("KIKOFF:theme", defaultValue);

  useEffect(() => {
    if (theme) document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);
}
