import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  form: {
    message: "",
    recipient: {
      name: "",
      streetAddress_1: "",
      streetAddress_2: "",
      city: "",
      state: "",
      zipCode: "",
    } as Address,
  },
  letterToken: null as string,
  letterMd: null as string,
  isMailingEligible: false,
  submittedLetters: null as Letter[],
};

type Letter = web.public_.IUserLetter;
type Address = web.public_.StartLetterRequest.IAddress;

export type MailingState = typeof initialState;

const mailingSlice = createSlice({
  name: "mailing",
  initialState,
  reducers: {
    updateRecipientForm(
      state,
      { payload }: PayloadAction<Partial<MailingState["form"]["recipient"]>>
    ) {
      state.form.recipient = {
        ...state.form.recipient,
        ...payload,
      };
    },
    setMailingMessage(state, { payload }: PayloadAction<string>) {
      state.form.message = payload;
    },
    setLetterToken(state, { payload }: PayloadAction<string>) {
      state.letterToken = payload;
    },
    setLetterMd(state, { payload }: PayloadAction<string>) {
      state.letterMd = payload;
    },
    setIsMailingEligible(state, { payload }: PayloadAction<boolean>) {
      state.isMailingEligible = payload;
    },
    setSubmittedLetters(state, { payload }: PayloadAction<Letter[]>) {
      state.submittedLetters = payload;
    },
  },
});

const { actions } = mailingSlice;
export const {
  updateRecipientForm,
  setMailingMessage,
  setLetterToken,
} = actions;
export default mailingSlice.reducer;

export const selectMailingRecipientForm = () => (state: RootState) => {
  return state.mailing.form.recipient;
};

export const selectMailingMessage = () => (state: RootState) => {
  return state.mailing.form.message;
};

export const selectMailingLetterMd = () => (state: RootState) => {
  return state.mailing.letterMd;
};

export const selectSubmittedLetters = createLoadableSelector(
  () => (state) => state.mailing.submittedLetters,
  { loadAction: () => fetchSubmittedLetters() }
);

export const selectMailingEligibility = createLoadableSelector(
  () => (state) => state.mailing.isMailingEligible,
  { loadAction: () => fetchMailingEligibility() }
);

export const fetchSubmittedLetters = () =>
  thunk((dispatch) =>
    webRPC.Mailing.getSubmittedLetters({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          const sortedLetters = data.submittedLetters.sort(
            (a, b) => b.submittedAt.seconds - a.submittedAt.seconds
          );
          dispatch(actions.setSubmittedLetters(sortedLetters));
        },
        _DEFAULT: handleFailedStatus("Failed to load submitted letters."),
      })
    )
  );

export const fetchMailingEligibility = () =>
  thunk((dispatch) =>
    webRPC.Mailing.getMailingEligibility({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setIsMailingEligible(data.eligible));
        },
        _DEFAULT: handleFailedStatus("Failed to load mailing eligibility."),
      })
    )
  );

export const startMailingLetter = (message: string) =>
  thunk((dispatch, getState) =>
    webRPC.Mailing.startLetter({
      recipientAddress: getState().mailing.form.recipient,
      message,
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setLetterToken(data.letter.token));
          dispatch(actions.setLetterMd(data.letter.letterMd));
        },
        _DEFAULT: handleFailedStatus("Failed to start letter."),
      })
    )
  );

export const submitMailingLetter = (paymentMethodToken = "") =>
  thunk((dispatch, getState) =>
    webRPC.Mailing.submitLetter({
      paymentMethodToken,
      userLetterToken: getState().mailing.letterToken,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          dispatch(fetchMailingEligibility());
          dispatch(fetchSubmittedLetters());
        },
        _DEFAULT: handleFailedStatus("Failed to submit letter."),
      })
    )
  );
