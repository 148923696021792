import { combineReducers } from "@reduxjs/toolkit";

import banking from "@feature/banking";
import billReporting from "@feature/bill_reporting";
import credit from "@feature/credit";
import creditAlerts from "@feature/credit_alerts";
import creditDisputes from "@feature/credit_disputes";
import creditLine from "@feature/credit_line";
import debtSettlement from "@feature/debt_settlement";
import forgotLogin from "@feature/forgot_login";
import funds from "@feature/funds";
import loans from "@feature/loans";
import mailing from "@feature/mailing";
import negotiations from "@feature/negotiations";
import onboarding from "@feature/onboarding";
import page from "@feature/page";
import privacyProtection from "@feature/privacy_protection";
import recommendations from "@feature/recommendations";
import rentReporting from "@feature/rent_reporting";
import shopping from "@feature/shopping";
import subscription from "@feature/subscription";
import subscriptionCancellation from "@feature/subscription_cancellation";
import taxFiling from "@feature/tax_filing";
import user from "@feature/user";
import viewProtos from "@feature/view_protos";

export default combineReducers({
  banking,
  billReporting,
  credit,
  creditAlerts,
  creditDisputes,
  creditLine,
  debtSettlement,
  funds,
  loans,
  negotiations,
  onboarding,
  page,
  privacyProtection,
  recommendations,
  rentReporting,
  shopping,
  subscription,
  subscriptionCancellation,
  user,
  forgotLogin,
  viewProtos,
  mailing,
  taxFiling,
});
