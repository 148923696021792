import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Cookie from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import { maxInt } from "@kikoff/utils/src/number";

import { updateUrlContext } from "@src/kikoff_url";

import { ExperimentAttributes } from "./createExperiment";
import randomizeVariant from "./randomizeVariant";
import * as experiments from ".";

export type Experiments = {
  [ExperimentName in keyof typeof experiments]: keyof typeof experiments[ExperimentName]["variants"];
};

type ExperimentData<ExperimentName extends keyof Experiments> = {
  variant: Experiments[ExperimentName];
  attributes: ExperimentAttributes<typeof experiments[ExperimentName]>;
};

export const getVariant = <T extends keyof Experiments>(experimentName: T) => {
  const variants = Object.keys(experiments[experimentName].variants);
  const cookieKey = `kikoff-experiment-${experimentName}`;
  const assignment =
    Cookie.get(cookieKey) ||
    (() => {
      const trackingId =
        Cookie.get("kikoff-id") ||
        (() => {
          Cookie.set("kikoff-id", uuidv4());
          return Cookie.get("kikoff-id");
        })();

      if (!trackingId) return variants[0];

      Cookie.set(cookieKey, randomizeVariant(experimentName, trackingId));

      return Cookie.get(cookieKey) || variants[0];
    })();

  return assignment as Experiments[T];
};

export const getAllVariants = () =>
  Object.fromEntries(
    Object.keys(experiments).map((experimentName: keyof Experiments) => [
      experimentName,
      getVariant(experimentName),
    ])
  ) as Experiments;

const backendExperimentPrefix = "kikoff-backend-experiment-";

export const setVariantCookies = (
  assignments: Partial<Experiments> | Entries<Experiments>
) => {
  const assignmentEntries =
    assignments instanceof Array ? assignments : Object.entries(assignments);
  for (const [experiment, variant] of assignmentEntries) {
    Cookie.set(`${backendExperimentPrefix}${experiment}`, variant, {
      path: "/",
      expires: maxInt,
    });
  }
};

export const getBackendExperimentCookie = (experimentName: string) => {
  return Cookie.get(`${backendExperimentPrefix}${experimentName}`) || "control";
};

export const removeUnusedVariantCookies = (experimentsInUse: string[][]) => {
  const experimentNames = new Set(
    experimentsInUse.map((experiment) => experiment[0])
  );

  for (const cookie of Object.keys(Cookie.get())) {
    if (
      cookie.startsWith(backendExperimentPrefix) &&
      !experimentNames.has(cookie.slice(backendExperimentPrefix.length))
    ) {
      Cookie.remove(cookie);
    }
  }
};

export const useBackendExperiment = getBackendExperimentCookie;

export const ExperimentsContext = React.createContext(
  Object.fromEntries(
    Object.entries(experiments).map(([key, config]) => [
      key,
      { variant: Object.keys(config.variants)[0], attributes: {} },
    ])
  ) as { [ExperimentName in keyof Experiments]: ExperimentData<ExperimentName> }
);

export const useExperiment = <ExperimentName extends keyof Experiments>(
  experimentName: ExperimentName
) => useContext(ExperimentsContext)[experimentName];

export const useExperiments = () => useContext(ExperimentsContext);

interface ExperimentProviderProps<
  Name extends keyof Experiments,
  Variant extends Experiments[Name]
> {
  experimentName: Name;
  variant: Variant;
  attributes: Record<string, any>;
  children: React.ReactNode;
}

export function ExperimentProvider<
  Name extends keyof Experiments,
  Variant extends Experiments[Name]
>({
  experimentName,
  variant,
  attributes,
  children,
}: ExperimentProviderProps<Name, Variant>) {
  const contextExperiments = useContext(ExperimentsContext);

  return (
    <ExperimentsContext.Provider
      value={{
        ...contextExperiments,
        [experimentName]: { variant, attributes },
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  );
}

interface ExperimentsProviderProps {
  children: React.ReactNode;
}

export function ExperimentsProvider({ children }: ExperimentsProviderProps) {
  const contextExperiments = useContext(ExperimentsContext);

  const refreshKey = useSelector((state) => state.page.experiments.refreshKey);

  const [assignments, setAssignments] = useState(
    null as React.ContextType<typeof ExperimentsContext>
  );

  const contextValue = useMemo(
    () => ({
      ...contextExperiments,
      ...assignments,
    }),
    [assignments]
  );

  useEffect(() => {
    const variants = getAllVariants();
    const variantEntries = Object.entries(variants) as Entries<
      ReturnType<typeof getAllVariants>
    >;

    const newAssignments = Object.fromEntries(
      variantEntries.map(([experiment, variant]) => [
        experiment,
        {
          variant,
          attributes: {
            ...experiments[experiment].defaultAttributes,
            ...experiments[experiment].variants[variant].attributes,
          },
        },
      ])
    ) as typeof assignments;

    updateUrlContext({ experiments: newAssignments });

    setAssignments(newAssignments);
  }, [refreshKey]);

  return (
    <ExperimentsContext.Provider value={contextValue}>
      {children}
    </ExperimentsContext.Provider>
  );
}
