import { useMemo, useState } from "react";

const arrayMutators = [
  "copyWithin",
  "fill",
  "pop",
  "push",
  "reverse",
  "shift",
  "sort",
  "splice",
  "unshift",
] as const;

type NativeMutators<T> = {
  [Method in typeof arrayMutators[number]]: T[][Method];
};

declare namespace useArray {
  export type Type<T> = (
    initialState: T[] | (() => T[])
  ) => readonly [
    T[],
    React.Dispatch<React.SetStateAction<T[]>> & {
      at(
        index: number | ((prevState: T[]) => number),
        value: T | ((prevState: T[]) => T)
      ): T;
      find(
        predicate: (item: T) => boolean,
        value: T | ((prevState: T[]) => T)
      ): number;
    } & NativeMutators<T>
  ];
}

// eslint-disable-next-line no-redeclare
function useArray<T>(
  initialState: Parameters<useArray.Type<T>>[0]
): ReturnType<useArray.Type<T>> {
  return useArray.wrap(useState(initialState));
}

useArray.wrap = <T>([array, setArray]: ReturnType<useState.Type<T[]>>) => {
  const setArrayEnriched = Object.assign(
    setArray,
    useMemo(
      () => ({
        at(
          index: number | ((prevState: T[]) => number),
          value: T | ((prevState: T[]) => T)
        ) {
          let v: T;

          setArray((a) => {
            let i = typeof index === "function" ? index(a) : index;
            i = i >= 0 ? i : a.length + i;
            v = typeof value === "function" ? (value as Function)(a) : value;

            return [...a.slice(0, i), v, ...a.slice(i + 1)];
          });

          return v;
        },
        find(
          predicate: (value: T, index: number, obj: T[]) => boolean,
          value: T | ((prevState: T[]) => T)
        ) {
          let found = -1;

          setArray((a) => {
            let i = a.findIndex(predicate);
            if (i === -1) return a;

            found = i;
            i = i >= 0 ? i : a.length + i;
            const v =
              typeof value === "function" ? (value as Function)(a) : value;

            return [...a.slice(0, i), v, ...a.slice(i + 1)];
          });

          return found;
        },
        ...((Object.fromEntries(
          // TODO(kcirtaptrick): Can't map tuple types strictly so this
          // can't be typed properly, fix this when that is possible
          arrayMutators.map((method) => [
            method,
            (...args: Parameters<typeof array[typeof method]>) => {
              let res;
              setArray(([...a]) => {
                res = a[method](
                  // @ts-expect-error
                  ...args.map((arg) =>
                    typeof arg === "function" ? arg(a) : arg
                  )
                );
                return a;
              });
              return res;
            },
          ])
        ) as any) as NativeMutators<T>),
      }),
      []
    )
  );

  return [array, setArrayEnriched] as ReturnType<useArray.Type<T>>;
};

export default useArray;
