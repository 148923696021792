import createExperiment from "./createExperiment";

export default createExperiment({
  variants: {
    control: {},
    webflow: {},
  },
  customAssignment(req) {
    if (req.nextUrl.search) {
      return "control";
    }
  },
}).weigh({
  control: 50,
  webflow: 50,
});
