import { useEffect } from "react";
import gsap from "gsap";

import {
  createDragHandler,
  createDragHandlerProps,
  NativeMatrix,
} from "@kikoff/client-utils/src/dom";

interface useDismissableOptions {
  onDismiss?(element: HTMLElement): void;
  clamp?: createDragHandlerProps["clamp"];
}

export default function useDismissable(
  ref: React.MutableRefObject<HTMLElement>,
  { onDismiss, clamp }: useDismissableOptions = {}
) {
  useEffect(() => {
    const element = ref.current;
    const initial = (({ e, f }) => ({ x: e, y: f }))(
      new NativeMatrix(getComputedStyle(element).transform)
    );
    return createDragHandler({
      ref,
      clamp,
      allowDefault: true,
      onDrag({ pos }) {
        // eslint-disable-next-line no-param-reassign
        element.style.transform = `translate(${pos.dx + initial.x}px, ${
          pos.dy + initial.y
        }px)`;
      },
      onDrop({ pos }) {
        const rect = element.getBoundingClientRect();
        if (Math.abs(pos.dx) > element.scrollWidth / 2) {
          gsap
            .timeline()
            .fromTo(
              element,
              { translateX: pos.dx + initial.x },
              {
                duration: 0.3,
                translateX: Math.sign(pos.dx) * window.innerWidth,
              }
            )
            .call(() => {
              onDismiss?.(element);
            })
            .set(element, {
              translateX: initial.x,
              translateY: initial.y,
            });
        } else if (Math.abs(pos.dy) > element.scrollHeight / 2) {
          gsap
            .timeline()
            .fromTo(
              element,
              { translateY: pos.dy + initial.y },
              {
                duration: 0.3,
                translateY:
                  pos.dy +
                  Math.sign(pos.dy) *
                    (element.scrollHeight +
                      rect[pos.dy < 0 ? "top" : "bottom"]),
              }
            )
            .set(element, {
              translateX: initial.x,
              translateY: initial.y,
            })
            .call(() => {
              onDismiss?.(element);
            });
        } else {
          gsap.fromTo(
            element,
            {
              translateX: pos.dx + initial.x,
              translateY: pos.dy + initial.y,
            },
            {
              duration: 0.3,
              ease: "back",
              translateX: initial.x,
              translateY: initial.y,
            }
          );
        }
      },
    }).cleanup;
  }, []);
}
