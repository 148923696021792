import { pick } from "./object";

/**
 * @file Contains number related utility functions
 */

declare global {
  interface Window {
    serverTimeDiff: number;
  }
}

export const maxInt = 2147483647;

/**
 * Clamps number within the inclusive lower and/or upper bounds. {@link ./number.js|Test}
 *
 * @function
 * @param {number} num Number to clamp
 * @param {Object} bounds lower and/or upper bounds
 * @param {number} [bounds.min] lower bounds
 * @param {number} [bounds.max] upper bounds
 * @returns {number} Clamped number
 */
export const clamp = Object.assign(
  (num: number, { min, max }: { min?: number; max?: number } = {}) => {
    /* eslint-disable no-param-reassign */
    num = typeof min === "number" && num < min ? min : num;
    num = typeof max === "number" && num > max ? max : num;
    /* eslint-enable no-param-reassign */

    return num;
  },
  {
    bounds: (num: number, bounds: readonly [number, number]) =>
      clamp(
        num,
        bounds[0] < bounds[1]
          ? {
              min: bounds[0],
              max: bounds[1],
            }
          : {
              min: bounds[1],
              max: bounds[0],
            }
      ),
  }
);

export const daysLeft = (date: Date) =>
  Math.floor((date.getTime() - Date.now()) / 1000 / 60 / 60 / 24);

// TODO: Move to client utils
// @ts-ignore
export const serverNow = () => Date.now() + window.serverTimeDiff;

/**
 * Finds max numerical value of specified or all properties in an object
 * Can also be used to find index of max value in array
 */
export const propsMax = <T extends readonly string[]>(
  obj: { [key in T[number]]: any },
  props?: T
) => {
  const entries = Object.entries(props ? pick(obj, props) : obj);
  let max = entries[0][1];
  let maxProp = entries[0][0];
  for (const [key, value] of entries) {
    if (value > max) {
      max = value;
      maxProp = key;
    }
  }
  return maxProp as T[number];
};

export const getProgress = Object.assign(
  ([from, to]: readonly [number, number], current: number) => {
    const res = (current - from) / (to - from);
    if (Number.isNaN(res)) return 0;
    return clamp(res, { min: 0, max: 1 });
  },
  {
    toValue(
      [from, to]: readonly [number, number],
      progress: number,
      step: number = 1
    ) {
      const res = Math.round((progress * (to - from)) / step) * step + from;
      if (Number.isNaN(res)) return 0;
      return res;
    },
  }
);

export const roundDecimal = (number: number, precision: number) =>
  Math.round(number * 10 ** precision) / 10 ** precision;

const tierOperations = ["<=", "<", ">=", ">", "="] as const;
type TierOperations = typeof tierOperations[number];

type Result<T> = ((value: number) => T) | T;

export const createTierFinder = <
  T,
  DefaultOperation extends TierOperations | undefined = undefined
>(
  // Tiers mapped operation, will be sorted at runtime
  tiers: Partial<Record<`${TierOperations}${number}` | "default", Result<T>>> &
    (DefaultOperation extends undefined ? {} : Record<number, Result<T>>),
  defaultOperation?: DefaultOperation
) => {
  let defaultResult: Result<T>;

  const tierData = Object.entries(tiers)
    .filter(([key, value]) => {
      if (key === "default") {
        defaultResult = value;
        return false;
      }
      return true;
    })
    .map(([boundStr, result]) => {
      const operation = tierOperations.find((op) => boundStr.startsWith(op));

      return {
        operation: operation || defaultOperation,
        bound: +boundStr.slice(operation?.length),
        result,
      };
    })
    .sort((a, b) => {
      if (a.operation[0] === b.operation[0]) {
        const multiplier = {
          "<": 1,
          "=": 0,
          ">": -1,
        }[a.operation[0]];

        return a.bound * multiplier - b.bound * multiplier;
      }
      if (a.operation === "=") return -1;
      if (b.operation === "=") return 1;
      return 0;
    });

  return (value: number): T | undefined => {
    const getResult = (result: Result<T>, bound: number = null) =>
      typeof result === "function" ? (result as any)(bound) : result;

    if (tiers[NaN] != null && Number.isNaN(value)) return getResult(tiers[NaN]);

    for (const { bound, operation, result } of tierData) {
      const opResult = {
        "<=": value <= bound,
        "<": value < bound,
        ">=": value >= bound,
        ">": value > bound,
        "=": value === bound,
      }[operation];

      if (opResult) return getResult(result, bound);
    }
    return getResult(defaultResult);
  };
};

export const isBetween = (num: number, bounds: [number, number]) =>
  num > bounds[0] && num < bounds[1];

export const Money = {
  ceil(cents: number) {
    return Math.ceil(cents / 100) * 100;
  },
};

const Range = Object.assign(
  (from: number, to: number, step: number = 1) => {
    const direction = Math.sign(to - from);
    return Array.from(
      { length: ((to - from) * direction) / step },
      (_, i) => from + i * direction * step
    );
  },
  {
    inclusive(from: number, to: number, step?: number): number[] {
      return Range(from, to + Math.sign(to - from) * step, step);
    },
  }
);

export { Range };

export const degToRad = (deg: number) => (deg / 360) * 2 * Math.PI;
export const radToDeg = (deg: number) => (deg / (2 * Math.PI)) * 360;
