import React from "react";

import {
  ComponentFromLazy,
  OverlayEntry,
  OverlaysSchema,
  PropsFromLazy,
} from "../types";

type RequiredKeys<T> = {
  [Key in keyof T]-?: {} extends Pick<T, Key> ? never : Key;
}[keyof T];

export type PushArgs<Props, Context> = RequiredKeys<Props> extends never
  ? [props?: Props, context?: Context]
  : [props: Props, context?: Context];

export default function createOverlaysContext<
  Overlays extends OverlaysSchema,
  Contexts extends { push?: any; render?: any }
>() {
  return React.createContext({
    push<Key extends keyof Overlays>(
      key: Key,
      ...[props, context]: PushArgs<
        PropsFromLazy<Overlays[Key]>,
        Contexts["push"]
      >
    ): Promise<ComponentFromLazy<Overlays[Key]>["returnValueSchema"]> {
      throw new Error(
        `OverlaysContext: Attempted to push overlay outside of OverlayContextProvider`
      );
    },
    removeAll(): void {
      throw new Error(
        "OverlaysContext: Attempted to remove all overlays outside of OverlayContextProvider"
      );
    },
    stack: [] as OverlayEntry<Overlays>[],
    current: { id: -1, index: -1 },
    render(context: Contexts["render"]): React.ReactNode {
      return null;
    },
  });
}
