import { forwardRef } from "react";

import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import { combineClasses } from "@kikoff/utils/src/string";

import { useOverlayExternal } from "@src/overlay";

import styles from "./bottom_sheet.module.scss";

type BottomSheetProps = JSX.IntrinsicElements["div"] & {
  includeClose?: boolean;
  height?:
    | React.CSSProperties["height"]
    | {
        min?: React.CSSProperties["minHeight"];
        max?: React.CSSProperties["maxHeight"];
      };
  children: React.ReactNode;
};

const BottomSheet = forwardRef(
  (
    { className, includeClose, height, children, ...props }: BottomSheetProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const overlay = useOverlayExternal();

    return (
      <div
        ref={ref}
        className={combineClasses(styles["bottom-sheet"], className)}
        style={
          typeof height === "object"
            ? { maxHeight: height.max, minHeight: height.min }
            : { height }
        }
        {...props}
      >
        {includeClose && (
          <ContainerButton
            className={styles.close}
            fit
            onClick={() => {
              overlay.removeSelf();
            }}
          >
            
          </ContainerButton>
        )}
        {children}
      </div>
    );
  }
);

export default BottomSheet;
