import { FluentResource } from "@fluent/bundle";

import {
  KikoffLoader,
  MessageLoader,
} from "@component/loading_indicators/loading_indicators";
import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`loading = Loading...`),
  es: new FluentResource(`loading = Cargando...`),
};

const l10n = newReactLocalization(RESOURCES);

function AuthenticationLoader() {
  return (
    <MessageLoader
      Spinner={KikoffLoader}
      main={l10n.getString("loading")}
      className={styles.loader}
    />
  );
}

export default AuthenticationLoader;
