import { createPropsProvider } from "@kikoff/client-utils/src/react";
import { cls } from "@kikoff/utils/src/string";

import styles from "./ListTile.module.scss";

declare namespace ListTile {
  interface Props {
    className?: string;
    leading?: React.ReactNode;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    trailing?: React.ReactNode;
    textOverflow?: "ellipsis" | "clip";
    propsFor?: {
      leading?: React.ComponentProps<"div">;
      title?: React.ComponentProps<"div">;
      subtitle?: React.ComponentProps<"div">;
      trailing?: React.ComponentProps<"div">;
    };
    alignment?: "top" | "center" | "bottom";
    noPadding?: boolean;
  }
}

export default ListTile;
function ListTile(props: ListTile.Props) {
  const {
    className,
    leading,
    trailing,
    title,
    subtitle,
    textOverflow,
    propsFor = {},
    noPadding,
    alignment = "center",
  } = ListTile.PropsProvider.useMerge(props);

  return (
    <div
      className={cls(
        styles["list-tile"],
        textOverflow && styles["text-overflow"],
        noPadding && styles["no-padding"],
        className
      )}
      style={{
        alignItems: { top: "flex-start", center: "center", bottom: "flex-end" }[
          alignment
        ],
      }}
    >
      {leading && (
        <div
          {...propsFor.leading}
          className={cls(styles["leading"], propsFor.leading?.className)}
        >
          {leading}
        </div>
      )}
      <div className={styles["inner"]}>
        <div
          {...propsFor.title}
          className={cls(
            styles["title"],
            "color:strong",
            "text:regular+",
            propsFor.title?.className
          )}
        >
          {title}
        </div>
        {subtitle && (
          <div
            {...propsFor.subtitle}
            className={cls(
              styles["subtitle"],
              "text:small",
              "color:moderate",
              propsFor.subtitle?.className
            )}
          >
            {subtitle}
          </div>
        )}
      </div>
      <div
        {...propsFor.trailing}
        className={cls(styles["trailing"], propsFor.trailing?.className)}
      >
        {trailing}
      </div>
    </div>
  );
}

ListTile.PropsProvider = createPropsProvider<ListTile.Props>("ListTile");
