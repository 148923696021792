import UFunction from "./function";
import { UObject } from "./object";

type Table = UObject.Any[];

declare namespace Table {
  type Index<
    T extends Table,
    Layers extends readonly Index.Layer<T>[],
    Key extends keyof T[number]
  > = Layers extends readonly [infer Layer, ...infer Rest]
    ? {
        [_ in (
          Layer extends keyof T[number]
            ? T[number][Layer]
            : ReturnType<Layer extends UFunction.Any ? Layer : never>
        ) extends infer Res
          ? Res extends UObject.Key
            ? Res
            : never
          : never]: Rest extends []
          ? T[number][Key]
          : Index<T, Rest extends Index.Layer<T>[] ? Rest : never, Key>;
      }
    : never;
  namespace Index {
    type Layer<T extends Table> =
      | keyof {
          [Key in keyof T[number] as T[number][Key] extends UObject.Key
            ? Key
            : never]: any;
        }
      | ((item: T[number]) => UObject.Key);
  }
}
const Table = {
  createIndex<
    T extends Table,
    Layers extends readonly Table.Index.Layer<T>[],
    Key extends keyof T[number] | null
  >(table: T, layers: Layers, key: Key = null): Table.Index<T, Layers, Key> {
    const res = {};

    for (const item of table) {
      UObject.mut.setByPath.createParents(
        res,
        layers.map((layer) =>
          typeof layer === "function"
            ? layer(item as any)
            : (item as any)[layer]
        ),
        key != null ? (item as any)[key] : item
      );
    }

    return res as any;
  },
};

export default Table;
