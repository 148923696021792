import { capitalize } from "lodash-es";

import { Bureau } from "@feature/credit";

interface NewHeaderProps {
  bureau: Bureau;
  type: string;
}

export default function NewHeader({ bureau, type }: NewHeaderProps) {
  return (
    <header className="text:large+">
      {capitalize(bureau)} has reported a new {type}. Does this look accurate?
    </header>
  );
}
