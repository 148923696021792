import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Color from "color";
import gsap from "gsap";

import { combineClasses } from "@kikoff/utils/src/string";

import { CheckPathAnimation } from "@component/animations/svg_animations";
import { GenericSpinner } from "@component/loading_indicators/loading_indicators";

import styles from "./buttons.module.scss";

export interface ContainerButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

/**
 * @deprecated Use @kikoff/components/src/v1/buttons/ContainerButton
 */
export const ContainerButton: React.FC<ContainerButtonProps> = ({
  className,
  style,
  onClick,
  children,
  ...props
}) => {
  return (
    <button
      className={combineClasses(styles.container, className)}
      style={{ cursor: onClick ? "pointer" : undefined, ...style }}
      onClick={onClick}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

interface UnderlineButtonProps extends ContainerButtonProps {}

export const UnderlineButton: React.FC<UnderlineButtonProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <ContainerButton
      className={combineClasses(styles["underline-button"], className)}
      {...props}
    >
      {children}
    </ContainerButton>
  );
};

export type ButtonStatus = "initial" | "pending" | "success";

export interface StatusButtonContent {
  initial: string;
  pending?: string;
  success?: string;
}

export interface StatusButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: StatusButtonContent;
  sourceColor?: string;
  status: ButtonStatus;
  noDisable?: boolean;
}

/**
 * @deprecated Use @kikoff/components/src/v1/buttons/LoadableButton
 */
export const StatusButton: React.FC<StatusButtonProps> = ({
  text = {},
  className,
  status = "initial",
  noDisable,
  sourceColor = "--new-primary-color",
  style,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null!);
  const [showStatus, setShowStatus] = useState<ButtonStatus[]>([]);

  useEffect(() => {
    const root = ref.current;

    setShowStatus(showStatus.concat(status));

    const statusAnims: {
      [key in ButtonStatus]?: (target: HTMLElement) => GSAPTimeline;
    } = {
      pending: (target) =>
        gsap.timeline().to(target, {
          duration: 0.3,
          opacity: 1,
        }),
      success: (target) =>
        gsap
          .timeline()
          .to(target, {
            duration: 0.5,
            width: "100%",
            left: 0,
          })
          .to(target.querySelector(`.${styles.text}`), {
            delay: 0.2,
            duration: 0.3,
            width: target.querySelector(`.${styles.text}`).scrollWidth,
          }),
    };

    if (statusAnims[status]) {
      let selected;
      let opacity;
      let tl;

      const timeout = setTimeout(() => {
        selected = root.querySelector(`.${styles.status}.${status}`);
        opacity = getComputedStyle(selected).getPropertyValue("opacity");
        tl = statusAnims[status](selected);
      });

      return () => {
        clearTimeout(timeout);
        if (tl)
          gsap
            .timeline()
            .call(tl.pause)
            .to(selected, {
              duration: 0.3,
              opacity: 0,
            })
            .call(() => tl.progress(0).kill())
            .set(selected, { opacity })
            .call(setShowStatus, [
              (statuses) => {
                const clone = statuses.slice();
                clone.splice(statuses.indexOf(status), 1);
                return clone;
              },
            ]);
      };
    }
    return () => {
      setShowStatus((statuses) => {
        statuses.splice(statuses.indexOf(status), 1);
        return statuses;
      });
    };
  }, [status]);

  return (
    <button
      className={combineClasses(styles["status-button"], className, status)}
      disabled={status !== "initial"}
      style={
        {
          ...(sourceColor.startsWith("--")
            ? {
                "--source-color": `var(${sourceColor})`,
                "--source-color-rgb": `var(${sourceColor}-rgb)`,
              }
            : {
                "--source-color": sourceColor,
                "--source-color-rgb": (Color(sourceColor) as any).color.join(
                  ", "
                ),
              }),
          ...style,
        } as React.CSSProperties
      }
      type="button"
      {...props}
      ref={ref}
    >
      {text.initial}
      {showStatus.includes("pending") && (
        <div className={combineClasses("pending", styles.status)}>
          <div className={styles["center-wrapper"]}>
            <GenericSpinner
              color="var(--source-color)"
              size="20px"
              style={{ zIndex: 0 }}
            />
            &nbsp;
            <div className={styles.text}>{text.pending}</div>
          </div>
        </div>
      )}
      {showStatus.includes("success") && (
        <div
          className={combineClasses(
            "success",
            styles.status,
            styles["center-wrapper"]
          )}
        >
          <CheckPathAnimation
            show={status === "success"}
            delay={300}
            width="6px"
          />
          &nbsp;
          <div className={styles.text}>{text.success}</div>
        </div>
      )}
    </button>
  );
};

export type StyledButtonShape =
  | "default"
  | "square"
  | "rounded-square"
  | "round";
export type StyledButtonType =
  | "active"
  | "disabled"
  | "pending"
  | "inverted"
  | "success";

export interface StyledButtonProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  shape?: StyledButtonShape;
  styleType?: StyledButtonType;
  sourceColor?: string;
  variant?: "primary" | "secondary";
  style?: CSSProperties;
  Element?: string | React.FC;
  disabled?: boolean;
  id?: string;
}

/**
 * @deprecated Use @kikoff/components/src/v1/buttons/KButton
 */
export const StyledButton: React.FC<StyledButtonProps> = ({
  className,
  shape = "default",
  styleType = "active",
  Element = "button",
  sourceColor = "--new-primary-color",
  variant = "primary",
  children,
  disabled,
  style,
  id,
  ...props
}) => {
  return React.createElement(
    Element,
    {
      type: "button",
      id,
      "data-cy": "styled-button",
      className: combineClasses(
        styles["styled-button"],
        className,
        `${shape}-shape`,
        `${styleType}-type`,
        `${variant}-variant`
      ),
      style: {
        ...(sourceColor.startsWith("--")
          ? {
              "--source-color": `var(${sourceColor})`,
              "--source-color-rgb": `var(${sourceColor}-rgb)`,
            }
          : {
              "--source-color": sourceColor,
              "--source-color-rgb": (Color(sourceColor) as any).color.join(
                ", "
              ),
            }),
        ...style,
      },
      ...(styleType === "disabled" && { disabled: true }),
      ...(typeof disabled === "boolean" && { disabled }),
      ...props,
    } as React.Attributes,
    children
  );
};
