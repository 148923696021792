import React, { useContext } from "react";

import useError, { InteractiveError } from "./useError";

interface ErrorProviderProps {
  children: ((error: InteractiveError) => React.ReactNode) | React.ReactNode;
  error?: InteractiveError;
}

const ErrorContext = React.createContext<InteractiveError>(null);

export const ErrorConsumer = ErrorContext.Consumer;

export const ErrorProvider = ({
  children,
  error = useError(),
}: ErrorProviderProps) => (
  <ErrorContext.Provider value={error}>
    {typeof children === "function" ? children(error) : children}
  </ErrorContext.Provider>
);

const useErrorConsumer = () => useContext(ErrorContext);
export default useErrorConsumer;
