import React, { forwardRef, useEffect } from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./ContainerButton.module.scss";

export type ContainerButtonProps = JSX.IntrinsicElements["div"] & {
  disabled?: boolean;
} & (
    | {
        // Useful when wrapping elements that need to be direct children, like
        // table elements
        passProps: true;
      }
    | {
        passProps?: false;
        fit?: boolean;
        inline?: boolean;
      }
  );

type Keys<T> = T extends T ? keyof T : never;
type AllProps<T> = {
  [Key in Keys<T>]: Key extends keyof T ? T[Key] : any;
};

const ContainerButton = forwardRef<HTMLDivElement, ContainerButtonProps>(
  (
    {
      className,
      style,
      disabled = false,
      onClick,
      children,
      passProps,
      inline,
      fit,
      ..._props
    }: AllProps<ContainerButtonProps>,
    ref
  ) => {
    // For some reason, this prevents double click handling bug
    useEffect(() => {});

    const props = {
      ref,
      className: combineClasses(styles["container-button"], className),
      onClick: (!disabled && onClick) || null,
      style: { cursor: !disabled && onClick ? "pointer" : undefined, ...style },
      disabled,
      tabIndex: 0,
      role: "button",
      ..._props,
    };

    // Must compare to true for type narrowing
    if (passProps) {
      if (!React.isValidElement(children))
        throw new Error(
          "ContainerButton with passProps enabled must have a single child."
        );
      return React.cloneElement(children, {
        ...props,
        className: combineClasses(props.className, children.props.className),
      });
    }

    const Element = inline ? "span" : "div";

    return (
      <Element
        {...props}
        className={combineClasses(fit && styles.fit, props.className)}
      >
        {children}
      </Element>
    );
  }
);

export default ContainerButton;
