import { ChangeEvent, useState } from "react";

import { renderFirstTruthyNode } from "@kikoff/client-utils/src/react";
import { cls, format } from "@kikoff/utils/src/string";

import GenericSpinner from "../animations/GenericSpinner";
import KButton from "../buttons/KButton";

import styles from "./AttachmentsUpload.module.scss";

const MB = 1024 * 1024;
const DEFAULT_MAX_FILE_SIZE = 16 * MB;
const DEFAULT_FILE_COUNT_LIMIT = 3;

type AttachmentsUploadProps = {
  files: File[];
  onSelectFiles: React.Dispatch<React.SetStateAction<File[]>>;
  maxFiles?: number;
  maxFileSize?: number;
  uploading?: boolean;
  className?: string;
};

export const AttachmentsUpload = ({
  files,
  onSelectFiles,
  uploading,
  maxFiles = DEFAULT_FILE_COUNT_LIMIT,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  className,
}: AttachmentsUploadProps) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = [...(event.target.files ?? [])];
    if (uploadedFiles.length === 0) return;

    const allFiles = [...files, ...uploadedFiles];
    const uniqueFileNames = new Set(allFiles.map((f) => f.name));
    const newFiles = [...uniqueFileNames].map((filename) =>
      allFiles.find((f) => f.name === filename)
    );

    if (newFiles.length > maxFiles) {
      setError(`You can only upload ${maxFiles} files at a time`);
      return;
    }

    if (newFiles.some((file) => file.size > maxFileSize)) {
      setError(`File size must be less than ${maxFileSize / MB} MB`);
      return;
    }

    onSelectFiles(newFiles);
    setError(null);
  };

  const handleRemoveFile = (idx: number) => {
    onSelectFiles((prev) => prev.filter((_, i) => i !== idx));
  };

  const uploadBtnDisabled = !inputEl || files.length >= maxFiles || uploading;

  return (
    <div className={cls(styles["upload-attachments"], className)}>
      <input
        type="file"
        multiple
        accept="image/jpeg, image/png, image/tiff, image/heic, image/heif, image/webp, application/pdf"
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={setInputEl}
      />

      {error && <div className="color:error">{error}</div>}

      <KButton
        onClick={() => inputEl?.click()}
        size="small"
        variant="black"
        wide
        state={uploadBtnDisabled ? "disabled" : "active"}
      >
        {renderFirstTruthyNode(
          uploading && "Uploading...",
          files.length >= maxFiles &&
            `You can only upload ${maxFiles} files at a time`,
          () =>
            ` Upload up to ${maxFiles - files.length} ${format.pluralize(
              "file",
              maxFiles - files.length
            )}`
        )}
      </KButton>

      <div className={styles["attachments"]}>
        {files.map((file, index) => (
          <div
            key={file.name}
            className={cls(styles["attachment"], "text:regular++")}
          >
            <div>{file.name}</div>

            {uploading ? (
              <GenericSpinner size={16} color="var(--primary)" />
            ) : (
              <KButton
                onClick={() => handleRemoveFile(index)}
                size="hug"
                variant="text-discouraged"
                className={cls(styles["delete-attachment"], "text:mini++")}
              >
                
              </KButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
