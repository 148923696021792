import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";

import { getQueryParam } from "@kikoff/client-utils/src/dom";

import { INFLUENCER_UTM_MEDIUM } from "@constant/promos";
import { exposeIntroTestPromo } from "@feature/promo";
import { updateUserState } from "@feature/user";
import { track } from "@util/analytics";

// When unauthenticated intro promo depends on user leads we have to poll,
// because user leads are populated asynchronously.
//
// Use the promos under user protos if user is authenticated.
//
// Look for utm_campaign when first-time user on homepage. Use cookie for
// returning anonymous users on any unauthenticated page.
export function useIntroPromoPoller() {
  const dispatch = useDispatch();
  const [pollingPromoCounter, setPollingPromoCounter] = useState(0);
  const introPromo = useSelector((state) => state.user.introPromo);

  useEffect(() => {
    const utmMedium = getQueryParam("utm_medium");
    setTimeout(() => {
      if (
        ([INFLUENCER_UTM_MEDIUM, "partner", "podcast"].includes(utmMedium) ||
          Cookie.get(INFLUENCER_UTM_MEDIUM)) &&
        !introPromo &&
        pollingPromoCounter < 5
      ) {
        dispatch(exposeIntroTestPromo()).then((introPromo) => {
          if (introPromo) {
            Cookie.set(INFLUENCER_UTM_MEDIUM, "true", { expires: 30 });
            dispatch(updateUserState({ introPromo }));
            track.impression("User is eligible for Intro Promo", {
              promo: introPromo.name,
              pollingPromoCounter,
            });
          }
        });

        setPollingPromoCounter(pollingPromoCounter + 1);
      }
    }, 2000);
  }, [pollingPromoCounter]);
}

export function useIntroPromoCleanup() {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.authenticated) {
      Cookie.remove(INFLUENCER_UTM_MEDIUM);
    }
  }, [user.authenticated]);
}
