import md5 from "md5";

import { Variant } from "./createExperiment";
import * as experiments from ".";

export default function randomizeVariant<T extends keyof typeof experiments>(
  experimentName: T,
  trackingId: string
) {
  const { variants } = experiments[experimentName];
  const variantWeights = Object.values(variants as Record<string, Variant>).map(
    ({ weight }) => weight
  );
  const sumOfWeights = variantWeights.reduce((acc, curr) => acc + curr, 0);
  const hash = Number.parseInt(
    // We are truncating this hash since this number gets approximated when the input is too big
    md5(`${experimentName}${trackingId}`).toString().slice(-8),
    16
  );
  let randomValue = hash % sumOfWeights;

  let variantIdx = 0;
  while (randomValue >= 0) {
    randomValue -= variantWeights[variantIdx];
    variantIdx++;
  }

  return Object.keys(variants)[variantIdx - 1];
}
