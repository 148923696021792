import { useMemo } from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import Card from "../cards/Card";

import styles from "./Alert.module.scss";

const iconMap = {
  info: "",
  warning: "",
  error: "",
  discount: "",
};

declare namespace Alert {
  type Props = Omit<React.ComponentPropsWithoutRef<"div">, "title"> & {
    type: keyof typeof iconMap;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
    dugout?: boolean;
    outline?: boolean | React.CSSProperties["color"];
  };
}

export default Alert;
function Alert({
  type,
  className,
  icon = iconMap[type],
  title,
  description,
  children,
  dugout,
  outline: _outline,
  ...props
}: Alert.Props) {
  const outline = useMemo(() => {
    if (_outline === true) return `var(--${type})`;
    if (_outline === false) return false;
    if (typeof _outline === "string") return _outline;
    if (["info", "discount"].includes(type)) return !dugout;

    return `var(--${type})`;
  }, [_outline, type, dugout]);

  return (
    <Card
      outline={outline}
      className={combineClasses(
        styles.alert,
        styles[type],
        dugout && [
          "color-base:dugout",
          type === "discount" ? "bg:discount-dugout" : "bg:dugout",
        ],
        className
      )}
      {...props}
    >
      <div className={`color:${type === "info" ? "strong" : type}`}>{icon}</div>
      <div className={styles.content}>
        {title && (
          <header className="text:regular+ color:strong">{title}</header>
        )}
        {description && (
          <div className="text:small color:moderate">{description}</div>
        )}
        {children}
      </div>
    </Card>
  );
}
