import { useEffect } from "react";

import useInitial from "@kikoff/hooks/src/useInitial";
import { combineClasses } from "@kikoff/utils/src/string";

import { useOverlayLayout } from "@src/overlay";

import DarkenBackdrop from "../backdrop/darken";

import styles from "./bottom_sheet.module.scss";

interface BottomSheetLayoutProps {
  className?: string;
  height?: React.CSSProperties["height"];
  children: React.ReactNode;
}

export default function BottomSheetLayout({
  className,
  height,
  children,
}: BottomSheetLayoutProps) {
  const layout = useOverlayLayout();
  const initial = useInitial({ rerender: true });

  const prev = layout.getRelative(-1);

  const left = layout.index < layout.current.index;
  const right = layout.index > layout.current.index;
  const enterRight = layout.isCurrent && prev && initial && layout.isPresent;
  const cleared = layout.current.index === -1;

  useEffect(() => {
    if (!layout.isPresent)
      setTimeout(() => {
        layout.safeToRemove();
      }, 500);
  }, [layout.isPresent]);

  return (
    <div
      className={combineClasses(
        styles["bottom-sheet-layout"],
        layout.isCurrent && styles.current,
        layout.isPresent && styles.present,
        left && styles.left,
        (enterRight || right) && !cleared && styles.right,
        className
      )}
      style={{ height }}
    >
      {children}
    </div>
  );
}

BottomSheetLayout.Backdrop = DarkenBackdrop;
