import { NextRequest } from "next/server";

import { Experiments } from "./context";

const variantConfigPropKeys = ["routes", "redirects", "attributes"];

export type Variant<
  Variants extends string = string,
  Attributes extends Record<string, any> = never,
  IsConfig = false
> = {
  routes?: Record<string, string>;
  redirects?: Record<string, string>;
  attributes?: Partial<Attributes>;
} & (IsConfig extends true
  ? {
      extends?: NoInfer<Variants>;
    }
  : { weight: number });

export interface ExperimentData<
  Variants extends string,
  Attributes extends Record<string, any>,
  IsConfig = false
> {
  variants: Record<Variants, Variant<Variants, Attributes, IsConfig>>;
  defaultAttributes?: Attributes;
  customAssignment?(
    req: NextRequest,
    context: {
      currentVariant: string | undefined;
      assignments: Partial<Experiments>;
    }
  ): NoInfer<Variants> | undefined;
}

// shorthand usage so that we dont have to write variants a second time during
// common usecase of blank attributes.
export function experimentWithWeights(weightsConfig: Record<string, number>) {
  const blankAttributes = Object.keys(weightsConfig).reduce(
    (memo, experimentName) => {
      memo[experimentName] = {};
      return memo;
    },
    {}
  );

  return createExperiment({ variants: blankAttributes }).weigh(weightsConfig);
}

export type ExperimentAttributes<
  Config extends ExperimentData<string, any>
> = Config extends ExperimentData<string, infer Attributes>
  ? Attributes
  : never;

export function createExperiment<
  Variants extends string,
  Attributes extends Record<string, any> = never
>(config: ExperimentData<Variants, Attributes, true>) {
  const { variants } = config;

  // Resolve inheritance (from variant.extends)
  for (const variant of Object.values(
    variants
  ) as typeof variants[Variants][]) {
    if (!variant.extends) continue;

    const parent = variants[variant.extends];
    for (const key of variantConfigPropKeys) {
      variant[key] = {
        ...parent[key],
        ...variant[key],
      };
    }
  }

  return {
    weigh(weights: Record<Variants, number>) {
      for (const [variantName, weight] of Object.entries(weights)) {
        variants[variantName].weight = weight;
      }

      return config as ExperimentData<Variants, Attributes>;
    },
  };
}

export default createExperiment;
