import { useEffect, useRef } from "react";

const useUpdateEffect: typeof useEffect = (callback, deps) => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current && callback) return callback();
    ref.current = true;
  }, deps);
};

export default useUpdateEffect;
