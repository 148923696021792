import React, { forwardRef, useRef, useState } from "react";
import gsap from "gsap";

import { Color } from "@kikoff/client-utils/src/dom";
import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import useCombinedRefs from "@kikoff/hooks/src/useCombinedRefs";
import useDismissable from "@kikoff/hooks/src/useDismissable";
import useUpdateEffect from "@kikoff/hooks/src/useUpdateEffect";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./errors.module.scss";

export const ErrorPanel = forwardRef(
  (
    {
      color = "red", // TODO(tom): use red from palette
      duration = 600,
      className,
      show = false,
      maxWidth = 500,
      type = "Error",
      message,
      fontSize = 15,
      style,
      onClose,
      defaultExpanded,
      borderWidth = 4,
      // eslint-disable-next-line no-unused-vars
      swipeable = false, // TODO: implmenet this
      ...props
    },
    _ref
  ) => {
    // const [expanded, setExpanded] = useState(false); TODO: implement this
    const [tl] = useState(
      gsap.timeline({
        paused: true,
        defaults: { duration: duration / 2000, ease: "sine.inOut" },
      })
    );
    const ref = useRef(null);
    const refs = useCombinedRefs(ref, _ref);

    useUpdateEffect(() => {
      const root = ref.current;
      const msg = root.querySelector(`.${styles.message}`);
      if (show) {
        Object.assign(msg.style, {
          width: `${root.parentElement.clientWidth - 29}px`,
          flex: "1 0 auto",
        });
        setTimeout(() =>
          Object.assign(root.style, {
            transitionProperty: "height, padding",
            transitionDuration: `${duration / 3000}s`,
            height: `${msg.scrollHeight}px`,
            padding: `${fontSize}px 0`,
          })
        );
        setTimeout(
          () =>
            Object.assign(root.style, {
              transition: `flex ${duration / 1500}s`,
              flex: 1,
            }),
          duration / 3
        );
        setTimeout(() => {
          Object.assign(msg.style, {
            width: "",
            flex: 1,
          });
          root.style.height = "auto";
        }, duration);
      } else {
        root.style.height = `${msg.scrollHeight}px`;
        setTimeout(() =>
          Object.assign(root.style, {
            transitionProperty: "height, padding",
            transitionDuration: `${duration / 1500}s`,
            height: 0,
            padding: 0,
          })
        );
        setTimeout(
          () =>
            Object.assign(root.style, {
              transition: "initial",
              flex: 0,
            }),
          duration / 1.5
        );
      }
    }, [show, message]);

    useDismissable(ref, {
      clamp: { y: { min: 0, max: 0 } },
      onDismiss(element) {
        element.style.opacity = 0;
        setTimeout(() => {
          element.style.opacity = 1;
        }, duration);
        tl.seek("width").reverse();
        onClose?.();
      },
    });

    return (
      <div className={styles["error-wrapper"]}>
        <div
          {...props}
          className={combineClasses([
            styles.error,
            styles.panel,
            className,
            { show },
          ])}
          style={{
            borderLeft: `${borderWidth}px solid ${color}`,
            background: Color(color).lighten(0.9),
            maxWidth,
            ...style,
          }}
          ref={refs}
        >
          <div
            className={styles.message}
            data-cy="error-message"
            style={{
              fontSize: `${fontSize}px`,
              lineHeight: `${fontSize}px`,
            }}
          >
            <span
              className={styles.type}
              style={{
                color,
              }}
            >
              {`${type}: `}
            </span>
            {message}
          </div>
          <div
            className={styles.options}
            style={{
              background: Color(color).lighten(0.9),
              maxHeight: "20px",
            }}
          >
            <ContainerButton
              data-cy="error-option-icon"
              style={{ color }}
              onClick={onClose}
            >
              
            </ContainerButton>
          </div>
        </div>
      </div>
    );
  }
);
