import React, { useEffect, useRef, useState } from "react";
import { FluentResource } from "@fluent/bundle";

import { KeyCode, setNativeValue } from "@kikoff/client-utils/src/dom";
import { mergeRefs } from "@kikoff/client-utils/src/react";
import { TextInputAction } from "@kikoff/components/src/v1/inputs/TextInput";
import useUpdateEffect from "@kikoff/hooks/src/useUpdateEffect";
import {
  combineClasses,
  getMatches,
  joinTruthy,
} from "@kikoff/utils/src/string";

import { track } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";
import { getService } from "@util/services";

import { TextInput, TextInputProps } from "./index";

import styles from "./address.module.scss";

const RESOURCES = {
  en: new FluentResource(`idv-address-apt-suite-num = Apt/Suite number (optional)
`),
  es: new FluentResource(`idv-address-apt-suite-num = Número de apartamento/suite (opcional)
`),
};

async function getPlace(
  placeId: string,
  {
    sessionToken,
    fields,
    map = document.createElement("div"),
  }: Omit<google.maps.places.PlaceDetailsRequest, "placeId"> & {
    map?: HTMLDivElement;
  } = {}
) {
  const maps = await getService("googleMaps");

  const placesService = new maps.places.PlacesService(map);
  return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    placesService.getDetails(
      {
        placeId,
        sessionToken,
        fields,
      },
      (result, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          track("error: received non-OK places service status", { status });
          reject(new Error("Google maps query failed"));
        }
        resolve(result);
      }
    );
  });
}

export type BasicAddress = {
  [key in "street" | "zip" | "city" | "state" | "number"]: string;
};

interface MapsAutocompleteProps extends TextInputProps {
  className?: string;
  onSelectPrediction?(
    arg0: {
      placeId: string;
      sessionToken: google.maps.places.AutocompleteSessionToken;
      place?: google.maps.places.PlaceResult;
      address?: BasicAddress;
      injection: { [key: string]: any };
    },
    err?: Error
  ): void;
  options?: (Omit<React.HTMLProps<HTMLLIElement>, "onSelect"> & {
    onSelect?(): void;
  })[];
  mapRef?: React.RefObject<HTMLDivElement>;
  fields?: google.maps.places.PlaceDetailsRequest["fields"];
  InputComponent?: React.FC;
  inputRef?: ReactProps<typeof TextInput>["ref"];
  removeClear?: boolean;
  showMapAndNumber?: boolean;
  externalAddress?: string;
}

export const MapsAutocompleteInput: React.FC<MapsAutocompleteProps> = ({
  id,
  className,
  onSelectPrediction,
  label = "Street address",
  value,
  onChange,
  mapRef,
  onKeyDown,
  injection,
  fields,
  actions = [],
  options = [],
  InputComponent = TextInput,
  inputRef,
  removeClear = false,
  showMapAndNumber = true,
  externalAddress = "",
  ...props
}) => {
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [
    sessionToken,
    setSessionToken,
  ] = useState<google.maps.places.AutocompleteSessionToken>(null);
  const ref = useRef(null);
  const [input, setInput] = useState(externalAddress);
  const [highlight, setHighlight] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const inputEl = ref.current?.querySelector("input");
    if (!inputEl) return;

    const ctrl = new AbortController();
    inputEl.addEventListener("focus", () => setIsFocused(true), {
      signal: ctrl.signal,
    });
    inputEl.addEventListener("blur", () => setIsFocused(false), {
      signal: ctrl.signal,
    });

    return () => ctrl.abort();
  }, [ref]);

  useEffect(() => {
    if (externalAddress) setInput(externalAddress);
  }, [externalAddress]);

  function renewSessionToken() {
    getService("googleMaps").then(async (maps: typeof window.google.maps) => {
      setSessionToken(new maps.places.AutocompleteSessionToken());
    });
  }

  useEffect(renewSessionToken, []);

  useUpdateEffect(() => {
    if (input)
      getService("googleMaps").then(async (maps: typeof window.google.maps) => {
        const autocompleteService = new maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
          {
            input,
            types: ["address"],
            sessionToken,
          },
          (_predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              track("error: received non-OK places service status", {
                status,
              });
              setPredictions([]);
              return;
            }

            setPredictions(_predictions?.slice(0, 5) || []);
          }
        );
      });
    else setPredictions([]);
  }, [input]);

  function select() {
    const prediction = predictions[highlight - 1];
    setHighlight(0);
    if (prediction) {
      setShowSuggestions(false);
      setInput(prediction.description);

      getPlace(prediction.place_id, {
        map: mapRef.current,
        sessionToken,
        fields,
      })
        .then((place) => {
          /* eslint-disable camelcase */
          const { address_components } = place;

          const address = parseGoogleAddress(address_components);
          /* eslint-enable no-restricted-syntax */
          /* eslint-enable camelcase */
          onSelectPrediction?.({
            placeId: prediction.place_id,
            sessionToken,
            injection,
            place,
            address,
          });
        })
        .catch((err) => {
          onSelectPrediction(
            {
              placeId: prediction.place_id,
              sessionToken,
              injection,
            },
            err
          );
        });
      renewSessionToken();
    } else if (highlight > predictions.length) {
      options[highlight - predictions.length - 1].onSelect?.();
    }
  }

  // Selections include text input, predictions and address not found option
  const entriesCount = (predictions?.length || 0) + options.length + 1;

  return (
    <div
      className={combineClasses(styles.autocomplete, className)}
      onFocus={() => {
        setShowSuggestions(true);
      }}
      onBlur={() => {
        setShowSuggestions(false);
        setHighlight(0);
      }}
    >
      <InputComponent
        autoComplete="off"
        style={{ marginBottom: 0 }}
        label={label}
        ref={mergeRefs(ref, inputRef)}
        className={styles.input}
        value={input}
        injection={injection}
        onChange={(e, extra) => {
          setInput(e.currentTarget.value);
          setHighlight(0);
          setShowSuggestions(true);
          onChange?.(e, extra);
        }}
        open={input.length > 0 || isFocused}
        onKeyDown={(e) => {
          onKeyDown?.(e);
          if (
            e.keyCode === KeyCode.DownArrow ||
            e.keyCode === KeyCode.UpArrow
          ) {
            e.preventDefault();
            setHighlight(
              (highlight +
                entriesCount +
                ({ [KeyCode.DownArrow]: 1, [KeyCode.UpArrow]: -1 }[e.keyCode] ||
                  0)) %
                entriesCount
            );
          }
          if (e.keyCode === KeyCode.Enter && showSuggestions) {
            e.preventDefault();
            select();
          }
        }}
        actions={[
          (() => {
            if (removeClear) return;

            const onClick = () => {
              onChange?.(null, null);
              setInput("");
              const inputEl = ref.current?.querySelector?.("input");
              if (!inputEl) return;
              setNativeValue(inputEl, "");
              inputEl.focus();
            };
            return InputComponent === TextInput
              ? {
                  onClick,
                  component: "",
                }
              : ((<TextInputAction.Clear onClick={onClick} />) as any);
          })(),
          ...actions,
        ]}
        {...props}
      />
      <div>
        {input && showSuggestions && (
          <ul
            className={styles.predictions}
            role="listbox"
            onMouseLeave={() => {
              setHighlight(0);
            }}
          >
            {predictions.map(
              // eslint-disable-next-line camelcase
              ({ description, matched_substrings }, index) => (
                <Suggestion
                  key={description}
                  index={index}
                  onMouseDown={select}
                >
                  <span className={styles.marker}></span>
                  <span>
                    {getMatches(
                      description,
                      matched_substrings
                    ).map((substr, i) =>
                      i % 2 ? <b key={i}>{substr}</b> : substr
                    )}
                  </span>
                </Suggestion>
              )
            )}
            {options.map(
              ({ onSelect: _onSelect, onMouseDown, ..._props }, i) => (
                <Suggestion
                  index={(predictions?.length || 0) + i}
                  onMouseDown={(e) => {
                    onMouseDown?.(e);
                    select();
                  }}
                  {..._props}
                />
              )
            )}
          </ul>
        )}
      </div>
    </div>
  );

  function Suggestion({ index, children = null, ..._props }) {
    const highlighted = highlight === index + 1;
    return (
      <li
        key={index}
        role="option"
        aria-selected={highlighted}
        onMouseEnter={() => {
          setHighlight(index + 1);
        }}
        {..._props}
        className={combineClasses(_props.className, {
          [styles.highlight]: highlighted,
        })}
      >
        <div className={styles.wrapper}>{children}</div>
      </li>
    );
  }
};

interface AutocompletePreviewProps extends MapsAutocompleteProps {
  onAddressUpdate?(
    arg0: {
      address: BasicAddress;
      injection: { [key: string]: any };
    },
    err?: Error
  ): void;
  margin?: React.CSSProperties["margin"];
  inputProps?: TextInputProps;
}

export const AutocompletePreview: React.FC<AutocompletePreviewProps> = ({
  onSelectPrediction,
  onAddressUpdate,
  onChange,
  injection,
  fields = [],
  inputProps,
  margin = "10px",
  InputComponent = TextInput,
  showMapAndNumber = true,
  externalAddress,
  ...props
}) => {
  const [place, setPlace] = useState(null);
  const [address, setAddress] = useState(null);
  const mapRef = useRef(null);
  const l10n = newReactLocalization(RESOURCES);

  return (
    <div
      className={styles["autocomplete-preview"]}
      style={
        {
          "--margin": margin,
        } as React.CSSProperties
      }
    >
      <div
        ref={mapRef}
        className={combineClasses(styles.map, {
          [styles.expand]: !!place && showMapAndNumber,
        })}
      />
      <MapsAutocompleteInput
        mapRef={mapRef}
        InputComponent={InputComponent}
        fields={["address_components", "geometry", ...fields]}
        onSelectPrediction={(info, err) => {
          onSelectPrediction?.(info, err);
          onAddressUpdate?.({ injection, address: info.address }, err);

          if (err) return;

          setPlace(info.place);
          setAddress(info.address);
          getService("googleMaps").then((maps) => {
            const map = new maps.Map(mapRef.current, {
              zoom: 16,
              center: info.place.geometry.location,
              clickableIcons: false,
              disableDefaultUI: true,
              draggable: false,
            });
            const marker = new maps.Marker({
              map,
              position: info.place.geometry.location,
            });
          });
        }}
        onChange={(...args) => {
          setPlace(null);
          setAddress(null);
          onAddressUpdate?.({ injection, address: null });
          onChange?.(...args);
        }}
        injection={injection}
        externalAddress={externalAddress}
        {...inputProps}
        {...props}
      />
      {showMapAndNumber && place && (
        <InputComponent
          className={styles.input}
          label={l10n.getString("idv-address-apt-suite-num")}
          abbreviation="Apt/Suite (optional)"
          name="number"
          onChange={(e) => {
            onAddressUpdate?.({
              injection,
              address: { ...address, number: e.currentTarget.value },
            });
          }}
          {...inputProps}
        />
      )}
    </div>
  );
};

/* eslint-disable camelcase */
export const parseGoogleAddress = (
  address_components: google.maps.GeocoderAddressComponent[]
) => {
  const address = {} as BasicAddress;

  const fieldComponents = {
    street: {
      street_number: "short_name",
      route: "long_name",
    },
    zip: {
      postal_code: "long_name",
    },
    city: {
      locality: "long_name",
    },
    state: {
      administrative_area_level_1: "short_name",
    },
  };

  /* eslint-disable no-restricted-syntax */
  for (const address_component of address_components) {
    for (const [name, components] of Object.entries(fieldComponents)) {
      const component =
        address_component[components[address_component.types[0]]];
      if (component) {
        address[name] = joinTruthy([address[name], component], " ");
      }
    }
  }

  if (!address.city) {
    track("account: use sublocality for city");
    const sublocalities: google.maps.GeocoderAddressComponent[] = [];
    for (const address_component of address_components) {
      if (address_component.types[0].startsWith("sublocality")) {
        sublocalities.push(address_component);
      }
    }
    sublocalities.sort(
      ({ types: [a] }, { types: [b] }) => a.localeCompare(b) || -1
    );

    // eslint-disable-next-line prefer-destructuring
    address.city = sublocalities[0].long_name;
  }
  return address;
};

export const formatBasicAddress = {
  oneLine: (address: BasicAddress) => {
    const { street, number, city, state, zip } = address;
    const addressLine = [number, street].filter(Boolean).join(" ");
    return `${addressLine}, ${city}, ${state}${zip ? ` ${zip}` : ""}`;
  },

  twoLine: (address: BasicAddress) => {
    const firstLine = [address.number, address.street]
      .filter(Boolean)
      .join(" ");
    const secondLine = `${address.city}, ${address.state} ${address.zip}`;
    return `${firstLine}\n${secondLine}`;
  },
};
