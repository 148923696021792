import { useEffect, useRef } from "react";

export default function useCombinedRefs(
  ...refs: (React.MutableRefObject<any> | React.ForwardedRef<any>)[]
) {
  const targetRef = useRef();

  useEffect(() => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === "function") {
          ref(targetRef.current);
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = targetRef.current;
        }
      }
    }
  }, [refs]);

  return targetRef;
}
