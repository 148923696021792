import { useState } from "react";
import createPersistedState from "use-persisted-state";

import useInitial from "@kikoff/hooks/src/useInitial";
import { isClient } from "@kikoff/utils/src/general";

import { RootState } from "@store";

interface Keys {
  theme: "light" | "dark" | "system";
}

interface DevtoolKeys {
  featureFlags: RootState["page"]["featureFlags"];
}

type GenerateKeys<
  T extends Record<string, Record<string, any>>
> = UnionToIntersection<
  {
    [Prefix in keyof T]: Prefix extends string
      ? {
          [Key in keyof T[Prefix] as Key extends string
            ? `${Prefix}${Key}`
            : never]: T[Prefix][Key];
        }
      : never;
  }[keyof T]
>;

const cache: Partial<
  Record<keyof usePersisted.StoreKeys, ReturnType<typeof createPersistedState>>
> = {};

declare namespace usePersisted {
  type StoreKeys = GenerateKeys<{
    "KIKOFF:": Keys;
    "KIKOFF_DEVTOOL:": DevtoolKeys;
  }>;
}

if (isClient) window.global = window;

export default usePersisted;
function usePersisted<Key extends keyof usePersisted.StoreKeys>(
  key: Key,
  defaultValue?: usePersisted.StoreKeys[Key]
) {
  if (!cache[key]) {
    try {
      cache[key] = createPersistedState(key);
    } catch (e) {
      cache[key] = useState;
    }
  }

  // SSR compat
  const initial = useInitial({ rerender: true });
  const [state, setState] = cache[key](defaultValue);

  return (initial
    ? [defaultValue, setState]
    : [state, setState]) as useState.Result<usePersisted.StoreKeys[Key]>;
}
