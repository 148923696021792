import axios from "axios";
import { capitalize } from "lodash-es";
import { setUser as setSentryUser } from "@sentry/nextjs";

import Analytics from "@kikoff/client-utils/src/analytics";
import { web } from "@kikoff/proto/src/protos";
import { isClient } from "@kikoff/utils/src/general";

import { AppStore } from "@store";
import { isWebview, nativeDispatch } from "@util/mobile";

import { Events } from "./events";
import Impact from "./impact";

declare global {
  interface Window {
    // Analytics globals
    dataLayer: object[]; // Google Tag Manager
    fbq(...args: any): void; // Facebook
    hj: any; // Hotjar
    tatari: any; // Tatari
    ttq: any; // Tiktok
    pdst: any; // Podsight
    clarity: any; // Clarity
    amzn: any; // Amazon
    qp: any; // Podscribe
    rdt: any; // Reddit
    liQ: any; // Live Intent
    criteo_q: any; // Criteo
    podscribe: any; // Podscribe
    snaptr: any; // Snapchat
    twq: any; // Twitter
  }
}

function sha256(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  return crypto.subtle.digest("SHA-256", data).then((buffer) => {
    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  });
}

function getCreditLineLtv(user, store) {
  const subscription = store?.getState().subscription.activeSubscription;

  let value = user?.expectedLtv;

  if (!value) {
    switch (subscription?.plan) {
      case web.public_.SubscriptionPlan.PREMIUM:
        value = 200;
        break;
      case web.public_.SubscriptionPlan.ULTIMATE:
        value = 300;
        break;
      default:
        value = 44;
    }
  }

  return value;
}

const analytics = !isClient
  ? (new Analytics() as never)
  : new Analytics<
      Events,
      {
        context: {
          user: web.public_.IUser;
          store: AppStore;
          featureFlags: Record<string, boolean>;
        };
      }
    >()
      .addClient(
        "conversion",
        "Facebook",
        (() => {
          let eventID = null;

          return {
            identify(payload, store) {
              if ("userId" in payload) eventID = payload.userId;

              // https://kikoff.atlassian.net/browse/AMF-1688
              if (store?.user?.info) {
                window.fbq?.("init", "759796597839521", {
                  em: store?.user?.info.email,
                  fn: store?.user?.info.givenName,
                  ln: store?.user?.info.surname,
                  ph: store?.user?.info.phone,
                  ct: store?.user?.info.city.toLowerCase().replace(/\s+/g, ""), // has to be lowercase with no spaces
                  zp: store?.user?.info.zip,
                });
              }
            },
            track(name, _properties, { user, store }) {
              if (!window.fbq) return;

              // Run these events only from the backend API:
              // https://kikoff.atlassian.net/browse/AMF-2727?atlOrigin=eyJpIjoiNjIwYjU2YzQzMmNlNGMzYzk5NWI3ZDBiZDBlMmNkNGMiLCJwIjoiaiJ9
              if (name === "Secured Card Account Open") return;

              const value = getCreditLineLtv(user, store);

              const payload = user ? { currency: "USD", value } : _properties;

              switch (name) {
                case "Make Credit Line Payment":
                  window.fbq("track", "Purchase", payload, { eventID });
                  break;
                case "CashAdvanceLeadSignUp":
                case "Credit Account Open":
                  window.fbq("trackCustom", name, payload, { eventID });
                  break;
                default:
                  window.fbq("track", name, payload, { eventID });
              }
            },
          };
        })()
      )
      .addClient("conversion", "Podsights", {
        identify() {
          // do nothing
        },
        track(name, _properties, { user, store }) {
          if (!window.pdst) return;

          if (name === "Credit Account Open") {
            window.pdst("conf", {
              key: "56b67400dc3d49efa0cf7dbb7ffcf23b",
            });
            window.pdst("purchase", {
              order_id: user.token,
              discount_code: "",
              is_new_customer: true,
              value: getCreditLineLtv(user, store),
              currency: "USD",
            });
          }
        },
      })
      .addClient(
        "conversion",
        "Google Tag Manager",
        (() => {
          window.dataLayer ??= [];

          return {
            identify() {
              // Do nothing
            },
            track(name, properties, { user, store }) {
              const convValue = getCreditLineLtv(user, store);

              const res = {
                event: name,
                currency: "USD",
                // TODO(tom): remove the extra value param that is unused
                //   after confirming receiving data.

                // FOLLOW UP(COLE): It seems that only value (not conversionValue) is used in {{Conv Value Data Layer Variable}} in GTM
                // I am just unsure if conversionValue is used elsewhere so not removing for now
                conversionValue: convValue,
                name: `${user?.info?.givenName} ${user?.info?.surname}`,
                email: user?.info?.email,
                value: convValue,
                ...properties,
              };

              if (name === "Credit Account Open") {
                const subscription = store.getState().subscription
                  .activeSubscription;
                const isPremium =
                  subscription?.plan === web.public_.SubscriptionPlan.PREMIUM;

                res["plan"] = isPremium ? "premium" : "basic";

                // purposely sending a duplicate event per request of https://kikoff.atlassian.net/browse/AMF-1124
                window.dataLayer.push({
                  ...res,
                  event: `CAO - ${capitalize(res["plan"])}`,
                });
              }
              window.dataLayer.push(res);
            },
          };
        })()
      )
      .addClient(
        "conversion",
        "Tiktok",
        (() => {
          window.ttq ??= [];

          return {
            identify() {
              // Do nothing
            },
            async track(name, _properties, { user, store }) {
              // https://ads.tiktok.com/marketing_api/docs?rid=5ipocbxyw8v&id=1701890973258754
              const eventCodes = {
                "Credit Account Open": "CompletePayment",
                "Make Credit Line Payment": "Subscribe",
                "Freshdesk Help Me Button: Tap": "Contact",
                "view page": "ViewContent",
                "signup: create account": "SubmitForm",
              };

              if (eventCodes[name] === undefined || !window.ttq?.track) {
                return;
              }

              const [emailHash, phoneHash] = await Promise.all([
                sha256(user.info.email.toLowerCase()),
                sha256("+" + user.info.phone),
              ]);

              let token: string;
              let value: number;

              if (["CompletePayment", "Subscribe"].includes(eventCodes[name])) {
                const { creditLine } = store.getState();

                token = creditLine.account?.token;
                value = getCreditLineLtv(user, store);
              } else {
                const order = store.getState().shopping?.checkoutResponse
                  ?.order;

                token = order?.token;
                value = order?.totalPriceCents;
              }

              const params = {
                pixel_code: "C11A1S104P0HGS266FGG",
                event_id: token,
                event: eventCodes[name],
                context: {
                  user: {
                    external_id: user.token,
                    email: emailHash,
                    phone_number: phoneHash,
                  },
                  page: { url: window.location.href },
                },
                properties: {
                  content_type: "product",
                  contents: [{ content_id: token }],
                  value,
                  currency: "USD",
                },
              };

              axios.post(
                `${window.location.origin}/api/tiktok/tiktok-events`,
                params
              );

              window.ttq.identify({
                email: emailHash,
                phone_number: phoneHash,
                external_id: user.token,
              });

              window.ttq.track(
                eventCodes[name],
                {
                  currency: "USD",
                  value,
                  content_type: "product",
                  contents: [{ content_id: token }],
                },
                { event_id: token }
              );
            },
          };
        })()
      )
      // https://docs.google.com/document/d/1ge3qHWU0_3Mk1u9px9d9G60InmTvrSKBY1AN2_CE5EM/edit#
      .addClient("conversion", "Tatari", {
        identify({ userId }) {
          if (!window.tatari) return;
          window.tatari.identify(userId);
        },
        track(name, properties) {
          if (!window.tatari) return;
          window.tatari.track(name, properties);
        },
      })
      .addClient("conversion", "Impact", {
        identify() {
          // Do nothing, handled by Impact.identify
        },
        track(name) {
          const actions = {
            "Credit Account Open": "openedCreditAccount",
            "Secured Credit Card Signup": "cashCardSignup",
            Signup: "onboardingInfoSubmitted",
          } as const;

          if (!(name in actions)) return;

          Impact[actions[name]]();
        },
      })
      .addClient("conversion", "Amazon", {
        identify() {
          // Do nothing
        },
        track(name) {
          if (!window.amzn) return;

          if (name === "Make Credit Line Payment") {
            window.amzn("trackEvent", "Purchase");
          }
        },
      })
      .addClient("conversion", "Quora", {
        identify() {
          // Do nothing
        },
        track(name) {
          if (!window.qp) return;

          if (name === "Make Credit Line Payment") {
            window.qp("track", "Purchase");
          }
        },
      })
      .addClient("conversion", "Reddit", {
        identify(_, store) {
          window.rdt?.("init", "t2_o7rw0dvr", {
            email: store?.user?.info.email,
          });
        },
        track(name, _properties, { user, store }) {
          if (!window.rdt) return;

          const { creditLine } = store.getState();

          const token = creditLine.account?.token;

          if (name === "Make Credit Line Payment") {
            window.rdt("track", "Purchase", {
              currency: "USD",
              itemCount: 1,
              transactionId: token || _properties?.creditLineToken,
              value: getCreditLineLtv(user, store),
            });
          }
        },
      })
      .addClient("conversion", "Live Intent", {
        identify() {},
        track(name, _properties, { user, store }) {
          window.liQ = window.liQ || [];

          const { creditLine } = store.getState();

          const token =
            creditLine.account?.token || _properties?.creditLineToken;
          const value = getCreditLineLtv(user, store);

          const email = user.info.email;
          const items = [
            {
              id: token,
              price: value,
              quantity: "1",
              currency: "USD",
            },
          ];

          switch (name) {
            case "Signup":
              window.liQ.push({
                event: "conversion",
                name: "form_fill",
                email,
              });
              break;
            case "Credit Account Open":
              window.liQ.push({
                event: "addToCart",
                email,
                items,
              });
              break;
            case "Make Credit Line Payment":
              window.liQ.push({
                event: "conversion",
                name: "product_purchase",
                conversionId: token,
                amount: value,
                currency: "USD",
                email,
                items,
              });
              break;
            default:
          }
        },
      })
      .addClient("conversion", "Criteo", {
        async identify(_, store) {
          window.criteo_q = window.criteo_q ?? [];

          const user = store?.user;
          const emailHash = user
            ? await sha256(user.info.email.toLowerCase())
            : "";

          const deviceType = /iPad/.test(navigator.userAgent)
            ? "t"
            : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                navigator.userAgent
              )
            ? "m"
            : "d";
          window.criteo_q.push(
            { event: "setAccount", account: 112298 },
            { event: "setEmail", email: emailHash, hash_method: "sha256" },
            { event: "setZipcode", zipcode: user?.info.zip },
            { event: "setSiteType", type: deviceType }
          );
        },
        track(name, _properties, params) {
          window.criteo_q = window.criteo_q ?? [];

          const eventCodes = {
            "View Homepage": "viewHome",
            "Low Intent": "viewList",
            "High Intent": "viewItem",
            "Hybrid Intent": "viewList",
            "Pre Checkout": "viewBasket",
            "Make Credit Line Payment": "trackTransaction",
          };

          if (eventCodes[name] === undefined) {
            return;
          }

          const event: any = {
            event: eventCodes[name],
            tms: "custom-guide",
          };

          switch (eventCodes[name]) {
            case "viewList":
              event.item = ["1"];
              break;
            case "viewItem":
              event.item = "1";
              break;
            case "viewBasket":
              event.item = [
                {
                  id: "1",
                  price: getCreditLineLtv(params?.user, params?.store),
                  quantity: "1",
                },
              ];
              break;
            case "trackTransaction":
              event.id = _properties?.creditLineToken;
              event.item = [
                {
                  id: "1",
                  price: getCreditLineLtv(params?.user, params?.store),
                  quantity: "1",
                },
              ];
              break;
            default:
          }
          window.criteo_q.push(event);

          // for hybrid event, push additional event
          if (name === "Hybrid Intent") {
            window.criteo_q.push({
              event: "viewItem",
              tms: "custom-guide",
              item: "1",
            });
          }
        },
      })
      .addClient("conversion", "Podscribe", {
        identify() {},
        async track(name, _properties, { user, store }) {
          if (!window.podscribe) {
            return;
          }

          const emailHash =
            (await sha256(user?.info.email.toLowerCase())) || "";

          if (name === "Signup") {
            window.podscribe("signup", {
              hashed_email: emailHash,
            });
          }
          if (name === "Credit Account Open") {
            window.podscribe("lead", {
              hashed_email: emailHash,
            });
          }
          if (name === "Make Credit Line Payment") {
            window.podscribe("purchase", {
              value: getCreditLineLtv(user, store),
              order_number: _properties.creditLineToken,
              hashed_email: emailHash,
            });
          }
        },
      })
      .addClient("conversion", "Twitter", {
        identify() {
          // do nothing
        },
        async track(name, _properties, { user, store }) {
          if (!window.twq) return;

          if (name === "Make Credit Line Payment") {
            window.twq("event", "tw-onqto-onqtp", {
              value: getCreditLineLtv(user, store),
              currency: "CAD",
              email_address: user.info.email,
              phone_number: user.info.phone,
            });
          }
        },
      })
      .addClient(
        "analytics",
        "Hotjar",
        (() => {
          if (!process.env.REALLY_PRODUCTION) return;
          return {
            identify({ userId, properties }) {
              if (properties && Object.keys(properties).length > 100) {
                console.warn(
                  "Over 100 user attributes may be ignored by Hotjar."
                );
              }
              window.hj?.("identify", userId, properties);
            },
            track() {
              // Do nothing, we only use hotjar for recordings
            },
          };
        })()
      )
      .addClient("analytics", "Clarity", {
        identify({ userId }) {
          window.clarity?.("identify", userId);
        },
        track(name) {
          window.clarity?.("event", name);
        },
      })
      .addClient("conversion", "Snapchat", {
        identify(_, store) {
          window.snaptr?.("init", "cbced8f2-b7b3-4625-a265-1b47f05ca056", {
            user_email: store?.user?.info.email,
          });
        },
        async track(name, _properties, params) {
          if (!window.snaptr) return;

          const eventCodes = {
            "View Homepage": "PAGE_VIEW",
            "Credit Account Open": "PURCHASE",
            "Make Credit Line Payment": "SUBSCRIBE",
            Signup: "SIGN_UP",
            Login: "LOGIN",
          };

          if (eventCodes[name] === undefined) {
            return;
          }

          const [emailHash, phoneHash] = await Promise.all([
            sha256(params?.user.info.email.toLowerCase()),
            sha256(params?.user.info.phone.replace(/\D/g, "")),
          ]);

          // Pixel
          const additionalProperties = {};

          if (eventCodes[name] === "SIGN_UP") {
            additionalProperties["sign_up_method"] = "EMAIL";
          } else if (
            ["PURCHASE", "SUBSCRIBE", "PAGE_VIEW"].includes(eventCodes[name])
          ) {
            additionalProperties["item_ids"] = ["1"];
            additionalProperties["item_category"] = "SUBSCRIPTION";

            if (["PURCHASE", "SUBSCRIBE"].includes(eventCodes[name])) {
              additionalProperties["price"] = getCreditLineLtv(
                params?.user,
                params?.store
              );
              additionalProperties["currency"] = "USD";
              additionalProperties["number_items"] = 1;

              if (eventCodes[name] === "PURCHASE") {
                additionalProperties["transaction_id"] =
                  _properties?.creditLineToken;
              }
            }
          }

          window.snaptr("track", eventCodes[name], {
            uuid_c1: params?.user.token,
            user_hashed_email: emailHash,
            user_hashed_phone_number: phoneHash,
            ...additionalProperties,
          });

          // Server to Server
          const body: any = {
            pixel_id: "80571514-1786-4e89-9457-78ceb1066010",
            timestamp: Date.now(),
            event_type: eventCodes[name],
            event_conversion_type: "WEB",
            hashed_email: emailHash,
            hashed_phone_number: phoneHash,
          };

          if (
            ["Credit Account Open", "Make Credit Line Payment"].includes(name)
          ) {
            const { creditLine } = params?.store.getState();

            body.transaction_id =
              _properties?.creditLineToken || creditLine.account?.token;
            body.price = getCreditLineLtv(params?.user, params?.store);
          }

          return axios.post(
            `${window.location.origin}/api/snapchat/snapchat-events`,
            body
          );
        },
      })
      .addClient("other", "Sentry", {
        identify({ userId }) {
          setSentryUser(userId && { id: userId, ip_address: "{{auto}}" });
        },
        track() {
          // Do nothing, handle exception capture separately
        },
      })
      .addClient(
        "analytics",
        "Amplitude",
        (() => {
          if (window.Cypress) {
            return;
          }

          if (isWebview) {
            let userPropertyAccumulator = {};
            return {
              track(name, properties) {
                nativeDispatch("trackEvent", {
                  name,
                  properties,
                  userProperties: userPropertyAccumulator,
                });
                userPropertyAccumulator = {};
              },
              identify({ properties }) {
                Object.assign(userPropertyAccumulator, properties);
              },
            };
          }

          return import("amplitude-js").then((amplitude) => {
            const instance = amplitude.getInstance();
            instance.init(process.env.AMPLITUDE_API_KEY, null, {
              includeGclid: true,
              includeReferrer: true,
              includeUtm: true,
              includeFbclid: true,
              saveEvents: true,
            });
            analytics.on("anonymize", () => {
              instance.regenerateDeviceId();
            });
            return {
              track(name, properties) {
                instance.logEvent(name, properties);
              },
              identify(payload) {
                if ("userId" in payload) instance.setUserId(payload.userId);
                if (payload.properties)
                  instance.setUserProperties(payload.properties);
              },
            };
          });
        })()
      );

if (isClient)
  analytics.on("provideBaseEventProperties", () => ({
    path: window.location.pathname,
    "user agent": window.navigator.userAgent,
  }));

export default analytics;

type EventKey = keyof Events;

const wrapTrack = <Name extends EventKey>(
  eventName: Name,
  properties?: Events[Name],
  postfix = ""
) => {
  analytics.track(`${eventName}${postfix}`, properties);
};

export const track = Object.assign(wrapTrack, {
  tap: <K extends EventKey>(eventName: K, properties?: Events[K]) => {
    wrapTrack(eventName, properties, ": Tap");
  },
  impression: <K extends EventKey>(eventName: K, properties?: Events[K]) => {
    wrapTrack(eventName, properties, ": Impression");
  },
});

/**
 * NOTE: no mobile equivalent ATM
 * Used for responses from the server, i.e. Success, Failure, etc.
 * */
export const logResponse = (eventName: EventKey, response: string) => {
  analytics.track(`${eventName}: ${response}`);
};
