/* eslint-disable no-console */

import React, { useMemo } from "react";

import { DEBUG } from "../../client-utils/src/general";

import useUpdate from "./useUpdate";

type IInteractiveError = Omit<Error, "message"> & {
  show: boolean;
  message: React.ReactNode;
};

export class InteractiveError implements IInteractiveError {
  name = "InteractiveError";

  message: React.ReactNode;

  show: boolean;

  stack?: string;

  constructor(private update: () => void) {
    this.hide = this.hide.bind(this);
    this.throw = this.throw.bind(this);
    this.setError = this.setError.bind(this);
  }

  setError(error: IInteractiveError) {
    Object.assign(this, error);
  }

  throw(error: string | Error) {
    if (typeof error === "string" || React.isValidElement(error))
      this.message = error;
    else {
      if (!error?.message) return;
      this.message = error.message;
      this.stack = error.stack;
    }
    this.show = true;
    this.update();

    if (DEBUG) {
      console.groupCollapsed(`useError: ${this.message}`);
      console.trace();
      console.groupEnd();
    }
  }

  hide() {
    this.show = false;
    this.update();
  }
}

const useError = (): InteractiveError => {
  const update = useUpdate();

  return useMemo(() => new InteractiveError(update), []);
};

export default useError;
