import { createPropsProvider } from "@kikoff/client-utils/src/react";
import Card from "@kikoff/components/src/v1/cards/Card";
import { cls } from "@kikoff/utils/src/string";

import styles from "./list_tile.module.scss";

declare namespace ListTile {
  interface Props {
    className?: string;
    leading?: React.ReactNode;
    trailing?: React.ReactNode;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    separator?: boolean;
    allowWrap?: boolean;
    propsFor?: {
      leading?: React.ComponentProps<"div">;
      title?: React.ComponentProps<"div">;
      subtitle?: React.ComponentProps<"div">;
      trailing?: React.ComponentProps<"div">;
    };
    noPadding?: boolean;
  }
}

export default ListTile;
function ListTile(props: ListTile.Props) {
  const {
    className,
    leading,
    trailing,
    title,
    subtitle,
    separator = true,
    allowWrap,
    propsFor = {},
    noPadding,
  } = ListTile.PropsProvider.useMerge(props);

  return (
    <div
      className={cls(
        styles["list-tile"],
        allowWrap && styles["allow-wrap"],
        separator && styles["with-separator"],
        noPadding && styles["no-padding"],
        className
      )}
    >
      {leading && (
        <div
          {...propsFor.leading}
          className={cls(styles["leading"], propsFor.leading?.className)}
        >
          {leading}
        </div>
      )}
      <div className={styles["inner"]}>
        <div
          data-cy="title"
          {...propsFor.title}
          className={cls(
            styles["title"],
            "color:strong",
            "text:regular+",
            propsFor.title?.className
          )}
        >
          {title}
        </div>
        {subtitle && (
          <div
            {...propsFor.subtitle}
            className={cls(
              styles["subtitle"],
              "text:small",
              "color:moderate",
              propsFor.subtitle?.className
            )}
          >
            {subtitle}
          </div>
        )}
      </div>
      <div
        {...propsFor.trailing}
        className={cls(styles["trailing"], propsFor.trailing?.className)}
      >
        {trailing}
      </div>
    </div>
  );
}

ListTile.PropsProvider = createPropsProvider<ListTile.Props>(
  "ListTile:deprecated"
);

declare namespace ListTile {
  namespace Group {
    interface Props {
      variant: "outlined";
      propsFor?: ListTile.Props["propsFor"] & {
        listTile?: Partial<Omit<ListTile.Props, "propsFor">>;
      };
      children: React.ReactNode;
    }
  }
}

ListTile.Group = ({
  variant,
  propsFor = {},
  children,
}: ListTile.Group.Props) => {
  return (
    <Card className={`${styles.group} ${styles[variant]}`} outline>
      <ListTile.PropsProvider
        {...propsFor.listTile}
        propsFor={propsFor}
        separator={false}
      >
        {children}
      </ListTile.PropsProvider>
    </Card>
  );
};
