import React from "react";

import styles from "./line.module.scss";

interface LineProps {
  className?: string;
  spacing?: React.CSSProperties["margin"];
  style?: React.CSSProperties;
  color?: string;
  text?: string;
}

const Line: React.FC<LineProps> = ({
  className,
  style,
  spacing,
  text,
  color,
}) => {
  return (
    <div
      className={className}
      style={{ marginTop: spacing, marginBottom: spacing, ...style }}
    >
      <div className={styles.separator} style={{ "--line-color": color }}>
        {text && <div>{text}</div>}
      </div>
    </div>
  );
};
export default Line;
