import Card from "@kikoff/components/src/v1/cards/Card";
import WithTrailing from "@kikoff/components/src/v1/wrappers/WithTrailing";
import { format } from "@kikoff/utils/src/string";

import { CreditAlert, CreditAlerts } from "@feature/credit_alerts";

import NewContent from "./content";
import NewHeader from "./header";

interface NewTradelineProps {
  creditAlert: CreditAlert;
}

export default function NewTradeline({ creditAlert }: NewTradelineProps) {
  const details = creditAlert.tradeLineAlert;

  return (
    <>
      <NewHeader
        bureau={CreditAlerts.bureau}
        type={CreditAlert.typeToString(creditAlert)}
      />
      <Card className="my-3" raised>
        <WithTrailing
          center
          trailing={`reported ${format.date(
            details.dateReported,
            "mm/dd/yyyy"
          )}`}
        >
          <div className="text:regular+ mr-2">{details.creditorName}</div>
        </WithTrailing>
      </Card>
      <NewContent creditAlert={creditAlert} />
    </>
  );
}
