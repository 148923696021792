import { addMonths, setDate } from "date-fns";
import { maxBy } from "lodash-es";
import { common } from "protobufjs";

import { web } from "@kikoff/proto/src/protos";

export function dateRange(startDate: number | Date, endDate: number | Date) {
  const dateRangeList: number[] = [];
  while (startDate < endDate) {
    dateRangeList.push(+startDate);
    startDate = addMonths(startDate, 1);
  }
  return dateRangeList;
}

export const toDate = (
  dateLike: string | number | Date | common.ITimestamp
) => {
  const d: any = dateLike;
  return typeof d.seconds === "number"
    ? new Date(d.seconds * 1000)
    : new Date(d);
};

export const dayOfMonthAfter = (date: Date, dayOfMonth: number) => {
  let newDate = setDate(date, dayOfMonth);
  if (newDate < date) newDate = addMonths(newDate, 1);
  return newDate;
};

export const lastRefreshDate = (bureaus: web.public_.ICreditV2[]) => {
  const compactedBureaus = bureaus.filter(Boolean);
  if (compactedBureaus.length === 0) {
    return null;
  }

  const freshestBureau = maxBy(compactedBureaus, (b) => {
    return b.currentCreditScore.generatedDate?.seconds;
  });

  if (freshestBureau) {
    return freshestBureau.currentCreditScore.generatedDate;
  }

  return null;
};

export const msIn = (() => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  return {
    second,
    minute,
    hour,
    day,
    week,
    month,
    year,
  };
})();
