import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { hash } from "@kikoff/utils/src/string";
import Table from "@kikoff/utils/src/table";

import { useOverlaysController } from "@src/overlay";
import { RootState } from "@store";
import { accountAutoPayEnabled } from "@util/payments";

import { createLoadableSelector, thunk } from "../utils";

import { selectCreditAccount } from "./credit_line";

const initialState = {
  recommendations: null as web.public_.IRecommendation[],
  recommendationByVariantById: {} as Recommendation.ByVariant.ById,
  recommendationIdx: 0 as number,
};

export type RecommendationsState = typeof initialState;

const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState,
  reducers: {
    setRecommendations(
      state,
      { payload }: PayloadAction<RecommendationsState["recommendations"]>
    ) {
      const recommendations = payload.filter(
        (recommendation) => Recommendation.id(recommendation) != null
      );

      state.recommendations = recommendations;
      state.recommendationByVariantById = Recommendation.ByVariant.ById.fromRecommendationList(
        recommendations
      );
      state.recommendationIdx = 0;
    },
    setRecommendationIdx(
      state,
      { payload }: PayloadAction<RecommendationsState["recommendationIdx"]>
    ) {
      state.recommendationIdx = payload;
    },
  },
});

const { actions } = recommendationsSlice;
export const {} = actions;
export default recommendationsSlice.reducer;

export const selectRecommendations = createLoadableSelector(
  () => (state: RootState) => state.recommendations.recommendations,
  {
    loadAction: () => fetchRecommendations(),
  }
);

export const selectRecommendationByVariantById = createLoadableSelector(
  (variant: web.public_.Recommendation.Variant, id: Recommendation.Id) => (
    state: RootState
  ) => state.recommendations.recommendationByVariantById[id]?.[variant],
  {
    loadAction: (_variant, _id) => fetchRecommendations(),
  }
);

export const selectRecommendationIdx = () => (state: RootState) =>
  state.recommendations.recommendationIdx;

export const setRecommendationIdx = (idx: number) =>
  thunk((dispatch) => {
    dispatch(actions.setRecommendationIdx(idx));
  });

export const fetchRecommendations = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.Recommendations.getRecommendations({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setRecommendations(data.recommendations));
            return data.recommendations;
          },
          _DEFAULT: handleFailedStatus("Failed to load recommendations."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { recommendations } = getState().recommendations;

        return Promise.resolve(
          recommendations || dispatch(fetchRecommendations())
        );
      }),
  }
);

export const useRecommendationsMakePayment = () => {
  const dispatch = useDispatch();
  const overlays = useOverlaysController();
  const creditLine = useSelector(selectCreditAccount());
  const autopay = accountAutoPayEnabled(creditLine);

  return useCallback(
    () =>
      autopay
        ? overlays
            .push("src/components/dashboard/v2/payment_failed", {
              variant: "generic",
              accountType: "credit_line",
            })
            .then(async (res) => {
              if (res?.paymentSuccess) {
                await overlays.push(
                  "src/pages/dashboard/credit-score/_views/recommendations/ca_payment_30_overdue_overlays/autopay_payment_success"
                );
                Router.replace("/dashboard/credit-score");
                dispatch(fetchRecommendations());
              }
            })
        : overlays.push("credit_line/make_payment").then(async (res) => {
            if (res?.paymentSuccess) {
              await overlays.push(
                "src/pages/dashboard/credit-score/_views/recommendations/ca_payment_30_overdue_overlays/payment_success"
              );
              Router.replace("/dashboard/credit-score");
              dispatch(fetchRecommendations());
            }
          }),
    [autopay, overlays]
  );
};

export type Recommendation = web.public_.IRecommendation;
export namespace Recommendation {
  export type Id = string & {};

  export const id = ({
    variant,
    subject,
  }: Recommendation): Recommendation.Id => {
    switch (variant) {
      case web.public_.Recommendation.Variant.DEBT_RELIEF_ELIGIBLE:
        return subject.debtReliefEligible.token;
      case web.public_.Recommendation.Variant.DEBT_RELIEF_INELIGIBLE:
        return subject.debtReliefIneligible.number;
      case web.public_.Recommendation.Variant.DEBT_RELIEF_TIME_BARRED:
        return subject.debtReliefTimeBarred.number;
      case web.public_.Recommendation.Variant.DISPUTES_LATE_PAYMENT:
        return subject.disputesLatePayment.number;
      case web.public_.Recommendation.Variant.DISPUTES_OLD:
        return `${hash(
          subject.disputesOld.accounts.map((account) => account.number).join("")
        )}`;
      case web.public_.Recommendation.Variant.DISPUTES_REDISPUTE:
        return subject.disputesRedispute.token;
      case web.public_.Recommendation.Variant.CA_PAYMENT_30_OVERDUE:
        return subject.caPayment_30Overdue.number;
      case web.public_.Recommendation.Variant.CA_PAYMENT_90_OVERDUE:
        return subject.caPayment_90Overdue.number;
      case web.public_.Recommendation.Variant.PAYMENT_90_OVERDUE:
        return subject.payment_90Overdue.number;
      case web.public_.Recommendation.Variant.PAYMENT_120_OVERDUE:
        return subject.payment_120Overdue.number;
      case web.public_.Recommendation.Variant.CBL_LOW_SCORE:
        return "0";
      case web.public_.Recommendation.Variant.CBL_HIGH_SCORE:
        return "0";
      case web.public_.Recommendation.Variant.RR_LOW_SCORE:
        return "0";
      case web.public_.Recommendation.Variant.RR_HIGH_SCORE:
        return "0";
      case web.public_.Recommendation.Variant.HELLO_PRIVACY:
        return "0";
      default:
        return null;
    }
  };

  export type ByVariant = Record<
    web.public_.Recommendation.Variant,
    Recommendation
  >;
  export namespace ByVariant {
    export type ById = Record<Recommendation.Id, ByVariant>;
    export namespace ById {
      export const fromRecommendationList = (
        recommendations: Recommendation[]
      ) => Table.createIndex(recommendations, [Recommendation.id, "variant"]);
    }
  }
}
