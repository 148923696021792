export type Locale = "en" | "es";

export const availableLocales: Locale[] = ["en", "es"];

export const defaultLocale: Locale = "en";

export const humanizedLocaleLookup: Record<Locale, string> = {
  en: "English",
  es: "Español",
};
