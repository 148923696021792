import format from "./format";

const validateRegex = (regex: RegExp) =>
  Object.assign((str: string) => regex.test(str), {
    regex,
  });

const validate = {
  percentage: validateRegex(/\d+(?:\.\d+)?%/),
  domain: validateRegex(
    /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.[a-z]{2,24})$/i
  ),
  emailUser: validateRegex(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/
  ),
  get email() {
    return validateRegex(
      new RegExp(
        `${this.emailUser.regex.source.slice(
          0,
          -1
        )}@${this.domain.regex.source.slice(1)}`,
        "i"
      )
    );
  },
  name: validateRegex(/[^0-9.@!#]+/), // Disallow email characters + numbers
  addressLine: validateRegex(/[\w.#\-'\s]+/),
  phone(str: string, { strict = false } = {}) {
    return /^([1-9]\d{0,3})?[2-9]\d{9}$/.test(
      strict ? str.replace(/[()\s+-]/g, "") : str.replace(/\D/g, "")
    );
  },
  contactPoint(str: string) {
    return this.phone(str) || this.email(str);
  },
  date(str: string | Date, formatStr: string) {
    const date = new Date(str);
    return (
      !Number.isNaN(date.getTime()) && format.date(date, formatStr) === str
    );
  },
};
validate.contactPoint = validate.contactPoint.bind(validate);

export default validate;
