import { Atomic, Product } from "@atomicfi/transact-javascript";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { track } from "@util/analytics";

import handleProtoURL from "../helpers/handle-proto-url";

export default async function AtomicFiAction() {
  const publicToken = await webRPC.AtomicFi.getSdkConfig({
    bankingServiceProvider:
      web.public_.SdkConfigRequest.BankingServiceProvider.GALILEO,
  }).then(
    handleProtoStatus({
      SUCCESS(response) {
        return response.publicToken;
      },
      _DEFAULT: handleFailedStatus(
        "Failed to setup direct depoist. Please try again later."
      ),
    })
  );

  if (!publicToken) {
    return null;
  }

  Atomic.transact({
    config: {
      publicToken,
      tasks: [{ product: Product.DEPOSIT }, { product: Product.VERIFY }],
      deeplink: {
        step: "search-company",
      },
      theme: {
        brandColor: "#1d1d1d",
      },
    },
    onFinish: (data) => {
      track("Secured Card Web - Atomic complete", {
        taskId: data.taskId,
      });
      handleProtoURL(
        "kikoff://pages/generic?data=IiI%3D&name=secured_card%2Fyou_are_all_set"
      );
    },
    onClose: (data) => {
      track("Secured Card Web - Atomic closed", {
        reson: data.reason,
      });
    },
  });
}
