import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoDate,
} from "@kikoff/utils/src/proto";
import { titleCase } from "@kikoff/utils/src/string";

import { RootState } from "@store";

import amazonPrimeImg from "../../pages/dashboard/subscription-cancellations/assets/amazon_prime.svg";
import disneyPlusImg from "../../pages/dashboard/subscription-cancellations/assets/disney_plus.svg";
import huluImg from "../../pages/dashboard/subscription-cancellations/assets/hulu.svg";
import netflixImg from "../../pages/dashboard/subscription-cancellations/assets/netflix.svg";
import planetFitnessImg from "../../pages/dashboard/subscription-cancellations/assets/planet_fitness.svg";
import spotifyImg from "../../pages/dashboard/subscription-cancellations/assets/spotify.svg";
import { createLoadableSelector, thunk } from "../utils";

import { selectDismissal, selectFeatureFlag } from "./page";
import { selectIsUltimate } from "./shopping";

export import SubscriptionProvider = web.public_.SubscriptionProvider;
import { partition } from "@kikoff/utils/src/array";

import { BasicAddress, parseGoogleAddress } from "@component/inputs/address";
import { getService } from "@util/services";

export type OnlineCancellation = web.public_.ICancellation;
export type MailingCancellation = web.public_.IMailingCancellationAttempt;
export const SUBSCRIPTION_PROVIDERS = Object.values(
  web.public_.SubscriptionProvider
) as OnlineCancellation[];

export const CancellationStatus = web.public_.Cancellation.CancellationStatus;
export const MailingCancellationStatus =
  web.public_.MailingCancellationAttempt.Status;

export enum CancellationType {
  ONLINE = "onlineCancellations",
  MAILING = "mailingCancellations",
}

const initialState = {
  onlineCancellations: null as OnlineCancellation[],
  mailingCancellations: null as MailingCancellation[],
};

export type SubscriptionCancellationState = typeof initialState;

const subscriptionCancellationSlice = createSlice({
  name: "subscriptionCancellation",
  initialState,
  reducers: {
    setCancellations<Type extends CancellationType>(
      state,
      {
        payload,
      }: PayloadAction<{
        cancellations: SubscriptionCancellationState[Type];
        type: Type;
      }>
    ) {
      const key = payload.type;
      if (key) {
        state[key] = payload.cancellations;
      }
    },
    updateCancellation<Type extends CancellationType>(
      state,
      {
        payload,
      }: PayloadAction<{
        cancellation: SubscriptionCancellationState[Type][number];
        type: Type;
      }>
    ) {
      const key = payload.type;
      if (key) {
        const index = state[key]?.findIndex(
          ({ token }) => token === payload.cancellation.token
        );
        if (index !== -1 && index !== undefined) {
          state[key][index] = payload.cancellation;
        }
      }
    },
  },
});

const { actions } = subscriptionCancellationSlice;
export const {} = actions;
export default subscriptionCancellationSlice.reducer;

export const useSubscriptionCancellationEnabled = () => {
  const onlineEnabled = useSelector(
    selectFeatureFlag("subscription_cancellation")
  );
  const mailingEnabled = useSelector(selectFeatureFlag("mailing_cancellation"));
  const enabled = onlineEnabled || mailingEnabled;

  const [isUltimate, loading] = useSelector(selectIsUltimate.load());

  return loading ? null : enabled && isUltimate;
};

export const selectAllSubscriptionCancellations = createLoadableSelector(
  () => (state: RootState) => [
    ...(state.subscriptionCancellation.onlineCancellations || []),
    ...(state.subscriptionCancellation.mailingCancellations || []),
  ],
  {
    selectLoaded: () => (state) =>
      !(
        state.subscriptionCancellation.onlineCancellations?.length &&
        state.subscriptionCancellation.mailingCancellations?.length
      ) && null,
    loadAction: () =>
      thunk((dispatch) =>
        Promise.all([
          dispatch(fetchMailingCancellations()),
          dispatch(fetchOnlineCancellations()),
        ])
      ),
  }
);

export const selectMailingCancellations = createLoadableSelector(
  () => (state: RootState) =>
    state.subscriptionCancellation.mailingCancellations,
  { loadAction: () => fetchMailingCancellations() }
);

export const selectMailingCancellationsDelivered = () => (state: RootState) =>
  state.subscriptionCancellation.mailingCancellations?.filter(
    (cancellation) => {
      const resolved = [
        MailingCancellationStatus.SUCCESS,
        MailingCancellationStatus.PENDING,
      ].includes(cancellation.cancellationStatus);

      const hasSeen = selectDismissal(
        "MAIL_CANCEL_NOTIFY_USER",
        cancellation.token
      )(state);

      if (!resolved && !hasSeen) {
        return SubscriptionCancellation.mailingDelivered(cancellation);
      }
      return false;
    }
  ) ?? [];

export const selectNewCancellationResults = () => (state: RootState) =>
  state.subscriptionCancellation.onlineCancellations?.filter((cancellation) => {
    const resolved = [
      CancellationStatus.SUCCESS,
      CancellationStatus.FAILURE,
    ].includes(cancellation.cancellationStatus);

    const hasSeen = selectDismissal(
      "SUBSCRIPTION_CANCELLATION_RESULT",
      cancellation.token
    )(state);

    return resolved && !hasSeen;
  }) ?? [];

export const selectOnlineCancellationByToken = createLoadableSelector(
  (token: string) => (state: RootState) => {
    return state.subscriptionCancellation.onlineCancellations?.find(
      (cancellation) => cancellation.token === token
    );
  },
  {
    selectLoaded: () => (state) =>
      state.subscriptionCancellation.onlineCancellations,
    loadAction: () => fetchOnlineCancellations(),
  }
);

export const fetchOnlineCancellations = () => {
  return thunk((dispatch) => {
    return webRPC.SubscriptionManagement.getCancellations({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.setCancellations({
              cancellations: data.cancellations,
              type: CancellationType.ONLINE,
            })
          );

          // Clean up local storage of retry credentials for successful cancellations
          const savedCreds = SubscriptionCancellation.getRetryCredentialsMap();
          for (const providerStr of Object.keys(savedCreds)) {
            const cancellation = data.cancellations.find(
              (cancellation) =>
                cancellation.subscriptionProvider ===
                (Number(providerStr) as SubscriptionProvider)
            );
            const cancelled =
              cancellation?.cancellationStatus === CancellationStatus.SUCCESS;

            if (!cancellation || cancelled) {
              delete savedCreds[providerStr];
            }
          }

          SubscriptionCancellation.updateRetryCredentials(savedCreds);

          return data.cancellations;
        },
        _DEFAULT: handleFailedStatus(
          "Failed to fetch subscription cancellations."
        ),
      })
    );
  });
};

export const submitOnlineCancellation = (
  subscriptionProvider: web.public_.SubscriptionProvider,
  credentials: { [key: string]: string }
) => {
  return thunk(() => {
    return webRPC.SubscriptionManagement.initiateCancellation({
      username: credentials.email ?? credentials.username,
      password: credentials.password,
      subscriptionProvider,
    }).then<string>(
      handleProtoStatus({
        SUCCESS(data) {
          return data.cancellationAttemptToken;
        },
        _DEFAULT: handleFailedStatus(
          "Failed to submit subscription cancellation request."
        ),
      })
    );
  });
};

export const pollSubscriptionCancellationStatus = (
  cancellationAttemptToken: string
) => {
  return thunk((dispatch) => {
    return webRPC.SubscriptionManagement.pollCancellationStatus({
      cancellationAttemptToken,
    }).then<{
      status: web.public_.Cancellation.CancellationStatus;
      failureReason?: web.public_.PollCancellationStatusResponse.FailureReason;
      mfaDeliveryAccountName?: string;
    }>(
      handleProtoStatus({
        SUCCESS(data) {
          const needsRefresh = [
            CancellationStatus.LOGGED_IN,
            CancellationStatus.FAILURE,
          ].includes(data.cancellationStatus);
          if (needsRefresh) {
            dispatch(fetchOnlineCancellations());
          }

          return {
            status: data.cancellationStatus,
            failureReason: data.failureReason,
            mfaDeliveryAccountName: data.mfaDeliveryAccountName,
          };
        },
        _DEFAULT: handleFailedStatus(
          "Failed to poll subscription cancellations."
        ),
      })
    );
  });
};

export const submitOnlineCancellationMfa = (
  cancellationAttemptToken: string,
  mfaCode: string
) => {
  return thunk(() => {
    return webRPC.SubscriptionManagement.sendCancellationMfa({
      cancellationAttemptToken,
      mfaCode,
    }).then(
      handleProtoStatus({
        SUCCESS() {},
        _DEFAULT: handleFailedStatus(
          "Failed to submit subscription cancellation MFA code."
        ),
      })
    );
  });
};

// Mailing Cancellation Logic

export const selectMailingCancellationByToken = createLoadableSelector(
  (token: string) => (state: RootState) =>
    state.subscriptionCancellation.mailingCancellations?.find(
      (cancellation) => cancellation.token === token
    ),
  {
    selectLoaded: () => (state) =>
      state.subscriptionCancellation.mailingCancellations,
    loadAction: () => fetchMailingCancellations(),
  }
);

export const startMailingCancellation = (
  institutionInfo: web.public_.StartCancellationLetterRequest.IInstitutionInfo,
  content: string
) => {
  return thunk((dispatch) => {
    return webRPC.MailingCancellation.startCancellationLetter({
      institutionInfo,
      content,
    }).then<string>(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(fetchMailingCancellations());

          return {
            letterMd: data.letter.letterMd,
            mailingCancellationToken: data.mailingCancellationToken,
          };
        },
        _DEFAULT: handleFailedStatus(
          "Failed to start mailing cancellation request."
        ),
      })
    );
  });
};

export const submitMailingCancellation = (mailingCancellationToken: string) => {
  return thunk((dispatch) => {
    return webRPC.MailingCancellation.submitCancellationLetter({
      mailingCancellationToken,
    }).then<string>(
      handleProtoStatus({
        SUCCESS() {
          dispatch(fetchMailingCancellations());
        },
        _DEFAULT: handleFailedStatus(
          "Failed to submit mailing cancellation request."
        ),
      })
    );
  });
};

export const markAsCancelled = (mailingCancellationToken: string) => {
  return thunk((dispatch) => {
    return webRPC.MailingCancellation.markAsCancelled({
      mailingCancellationToken,
    }).then<string>(
      handleProtoStatus({
        SUCCESS() {
          dispatch(fetchMailingCancellations());
        },
        _DEFAULT: handleFailedStatus(
          "Failed to submit mark as cancelled mailing cancellation request."
        ),
      })
    );
  });
};

export const fetchMailingCancellations = () => {
  return thunk((dispatch) => {
    return webRPC.MailingCancellation.listMailingCancellations({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.setCancellations({
              cancellations: data.cancellationAttempts,
              type: CancellationType.MAILING,
            })
          );

          return data.cancellationAttempts;
        },
        _DEFAULT: handleFailedStatus(
          "Failed to fetch subscription cancellations."
        ),
      })
    );
  });
};

export const FetchNearbyInstitutionsAbortedError = () =>
  new Error("Fetch aborted");

export type Institution = {
  address: BasicAddress;
  phone: string;
  email: string;
  name: string;
  key: number;
  distance: number;
};

export function fetchNearbyInstitutions(
  address: string,
  subscriptionInfo: SubscriptionCancellation.SubscriptionInfo,
  signal: AbortController["signal"]
): Promise<Institution[]> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<Institution[]>(async (resolve, reject) => {
    signal.addEventListener("abort", () => {
      reject(FetchNearbyInstitutionsAbortedError);
    });

    try {
      const {
        institutionType,
        title: institutionName,
        institutionEmail,
      } = subscriptionInfo;

      const maps = await getService("googleMaps");

      const geocoder = new maps.Geocoder();
      const geocodeResponse = await new Promise<google.maps.LatLng>(
        (resolve, reject) => {
          geocoder.geocode({ address, region: "us" }, (results, status) => {
            if (status === maps.GeocoderStatus.OK && results.length > 0) {
              resolve(results[0].geometry.location);
            } else {
              reject(new Error("Failed to geocode the provided address."));
            }
          });
        }
      );

      const { lat: userLat, lng: userLng } = geocodeResponse;
      const map = document.createElement("div");
      const service = new maps.places.PlacesService(map);

      const request = {
        query: `"${institutionName}"`,
        location: geocodeResponse,
        radius: 10000,
        type: institutionType,
      };

      const results = await new Promise<google.maps.places.PlaceResult[]>(
        (resolve, reject) => {
          service.textSearch(request, (results, status) => {
            if (status === maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              reject(new Error(`No nearby ${institutionName} found.`));
            }
          });
        }
      );

      if (!results) {
        resolve([]);
        return;
      }

      const userPoint = new maps.LatLng(userLat(), userLng());

      const institutions = await Promise.all(
        results.slice(0, 3).map(async (place, index) => {
          const { geometry } = place;
          const { lat, lng } = geometry.location;

          const point1 = new maps.LatLng(lat(), lng());
          const milesPerMeter = 0.000621371;
          const distanceInMiles =
            maps.geometry.spherical.computeDistanceBetween(point1, userPoint) *
            milesPerMeter;

          const { phone, googleAddressParts } = await new Promise<
            | {
                phone: string;
                googleAddressParts: google.maps.GeocoderAddressComponent[];
              }
            | undefined
          >((resolve, reject) => {
            service.getDetails(
              {
                placeId: place.place_id,
                fields: ["formatted_phone_number", "address_components"],
              },
              (place, status) => {
                if (status === maps.places.PlacesServiceStatus.OK && place) {
                  resolve({
                    phone: place.formatted_phone_number,
                    googleAddressParts: place.address_components,
                  });
                } else {
                  reject(new Error("Failed to fetch place details."));
                }
              }
            );

            signal.addEventListener("abort", () => resolve(undefined));
          });

          if (!phone || !googleAddressParts) return null;

          const { street, number, city, state, zip } = parseGoogleAddress(
            googleAddressParts
          );

          const emailArgs = subscriptionInfo.provider ===
            SubscriptionProvider.PLANET_FITNESS && {
            city,
            state,
            name: institutionName,
          };
          const email = institutionEmail(emailArgs);

          return {
            address: {
              city,
              street,
              state,
              zip,
              number,
            },
            phone,
            email,
            name: institutionName,
            key: index,
            distance: Math.round(distanceInMiles),
          };
        })
      );

      resolve(institutions.filter((institution) => institution !== null));
    } catch (error) {
      reject(error);
    }
  });
}

export type MembershipInfo = {
  email: string;
  phone: string;
  dob: string;
};

export function generateMailingCancellationContent(
  institution: Institution,
  membershipInfo: MembershipInfo,
  userInfo: web.public_.IPersonalInfo
) {
  const content = `Dear ${institution.name},

My name is ${userInfo.givenName} ${userInfo.surname}. Please consider this letter as formal notice of my decision to cancel my membership at ${institution.name}. I request that you process the cancellation as soon as possible and email me the subscription cancellation confirmation at ${membershipInfo.email}.

You can identify my account with the following information:
- Name: ${userInfo.givenName} ${userInfo.surname}
- Email: ${membershipInfo.email}
- Phone: ${membershipInfo.phone}
- Date of Birth: ${membershipInfo.dob}`;

  return content;
}

export namespace SubscriptionCancellation {
  export type SubscriptionInfo = {
    provider: SubscriptionProvider;
    title: string;
    price: string;
    icon: string;
    supported: boolean;
    mail?: boolean;
    estimatedSavings?: string;
    institutionType?: string;
    institutionEmail?: (args: any) => string;
    manualInstruction?: {
      customTitle?: string;
      customSubtitle?: string;
      steps: React.ReactNode[];
    };
    credentialRequirements?: CredentialRequirement[];
    mfaDigitsCount?: number;
  };

  export type CredentialRequirement = {
    type: "username" | "email" | "phone" | "password";
    label?: string;
    optional?: boolean;
  };

  export function wrapCredRequirements(
    credReqs: CredentialRequirement[]
  ): CredentialRequirement[] {
    return credReqs.map((credReq) => ({
      ...credReq,
      label: credReq.label ?? titleCase(credReq.type),
    }));
  }

  type RetryCredential = Partial<
    { [key in CredentialRequirement["type"]]: string }
  >;
  type RetryCredentialsMap = Partial<
    { [key in SubscriptionProvider]: RetryCredential }
  >;

  export function getRetryCredentialsMap() {
    const credsStr =
      localStorage.getItem("subscriptionCancellationRetryCreds") ?? "{}";
    return JSON.parse(credsStr) as RetryCredentialsMap;
  }

  export function saveRetryCredential(
    provider: SubscriptionProvider,
    newCreds: RetryCredential
  ) {
    const creds = getRetryCredentialsMap();
    const sanitizedNewCreds = Object.fromEntries(
      Object.entries(newCreds).filter(([key]) => key !== "password")
    );
    creds[provider] = sanitizedNewCreds;
    updateRetryCredentials(creds);
  }

  export function updateRetryCredentials(
    retryCredentials: RetryCredentialsMap
  ) {
    localStorage.setItem(
      "subscriptionCancellationRetryCreds",
      JSON.stringify(retryCredentials ?? {})
    );
  }

  export const getCancellationStatusLabel = (
    status: web.public_.Cancellation.CancellationStatus
  ) => {
    switch (status) {
      case CancellationStatus.SUCCESS:
        return "Cancellation successful";
      case CancellationStatus.FAILURE:
        return "Cancellation could not be completed";
      default:
        return "Cancellation in progress";
    }
  };

  // Mailing Cancellations
  export const getMailingCancellationStatusLabel = (
    mailingCancellation: MailingCancellation
  ) => {
    switch (mailingCancellation.cancellationStatus) {
      case MailingCancellationStatus.SUCCESS:
        return "Cancellation completed";
      case MailingCancellationStatus.SUBMITTED:
        if (SubscriptionCancellation.mailingDelivered(mailingCancellation))
          return "Cancellation in progress";
        return "Cancellation request submitted";
      default:
        return "Finish submitting your cancellation";
    }
  };

  // A mailing cancellation letter submitted_at more than 2 weeks ago is considered delivered
  export const mailingDelivered = (
    mailingCancellation: MailingCancellation
  ) => {
    if (
      mailingCancellation?.cancellationStatus ===
      MailingCancellationStatus.SUBMITTED
    ) {
      const submittedAt = protoDate(mailingCancellation.submittedAt);
      const now = new Date();

      const MS = 1;
      const SEC = 1000 * MS;
      const MIN = 60 * SEC;
      const HR = 60 * MIN;
      const DAY = 24 * HR;
      const WEEK = 7 * DAY;

      const twoWeeksAgo = new Date(now.getTime() - 2 * WEEK);
      if (submittedAt < twoWeeksAgo) {
        return true;
      }
    }

    return false;
  };

  export const getEndOfMonth = () =>
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

  export const getYearSavings = (
    recurringChargeAmountCents: number,
    recurringChargeCadence: web.public_.MailingCancellationAttempt.RecurringChargeCadence
  ) => {
    if (
      recurringChargeCadence ===
      web.public_.MailingCancellationAttempt.RecurringChargeCadence.MONTHLY
    ) {
      const monthsTilYearEnd = 12 - new Date().getMonth();

      return (recurringChargeAmountCents * monthsTilYearEnd) / 100;
    }
    return recurringChargeAmountCents / 100;
  };
}

export const CANCELLABLE_SUBSCRIPTIONS: SubscriptionCancellation.SubscriptionInfo[] = [
  {
    provider: SubscriptionProvider.HULU,
    title: "Hulu",
    price: "$7.99/mo",
    icon: huluImg,
    supported: true,
    credentialRequirements: SubscriptionCancellation.wrapCredRequirements([
      { type: "email" },
      { type: "password" },
    ]),
    mfaDigitsCount: 6,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://secure.hulu.com/account"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://secure.hulu.com/account
          </KLink>
          .
        </>,
        "Click on Account",
        "Scroll down and click Cancel",
        "Click “Cancel membership”",
      ],
    },
  },
  {
    provider: SubscriptionProvider.AMAZON_PRIME,
    title: "Amazon Prime",
    price: "$139/yr",
    icon: amazonPrimeImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.amazon.com/gp/primecentral"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.amazon.com/gp/primecentral
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "Click the “Manage Membership” button",
        "Click “Update, cancel and more” under the Manage section",
        "Click “End membership”",
        "Scroll down and click “Cancel”",
        "Click “End on [Date]” button",
      ],
    },
  },
  {
    provider: SubscriptionProvider.DISNEY_PLUS,
    title: "Disney+",
    price: "$13.99/mo",
    icon: disneyPlusImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.disneyplus.com/commerce/account?pinned=true"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.disneyplus.com/commerce/account?pinned=true
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "Click on your profile on the Navigation Bar",
        "Click “Account”",
        "Click on your subscription in the “Your Plans & Billing” section",
        "Click “Cancel Subscription”",
        "Click “Cancel Subscription” again",
      ],
    },
  },
  {
    provider: SubscriptionProvider.NETFLIX,
    title: "Netflix",
    price: "$15.49/mo",
    icon: netflixImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.netflix.com/login"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.netflix.com/login
          </KLink>
          .
        </>,
        "Click on your profile on the Navigation Bar",
        "Click Account",
        "Click “Manage Membership”",
        "Click “Cancel Membership”",
      ],
    },
  },
  {
    provider: SubscriptionProvider.PLANET_FITNESS,
    title: "Planet Fitness",
    price: "$15/mo",
    estimatedSavings: "$180 per year",
    icon: planetFitnessImg,
    mail: true,
    institutionType: "gym",
    institutionEmail: ({ city, state, name }) =>
      `${city
        .toLowerCase()
        .replaceAll(
          " ",
          ""
        )}.${state.toLowerCase()}@${name
        .toLowerCase()
        .replaceAll(" ", "")}.com`,
    supported: true,
    manualInstruction: {
      customTitle:
        "To cancel your Planet Fitness membership, you can do it online or in-person at your local branch.",
      customSubtitle:
        "If you want to cancel online, follow the instructions below:",
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.planetfitness.com/login"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.planetfitness.com/login
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "After signing in, click “My Account” on the Navigation Bar",
        "Click “Manage” in Membership Details section",
        "Click “Cancel”",
        "Click “Continue”",
        "Click “Proceed to Payment”",
        "Click “No thanks, I still want to cancel”",
        "Input payment details and click “Pay and Cancel Membership”",
      ],
    },
  },
  {
    provider: SubscriptionProvider.SPOTIFY,
    title: "Spotify",
    price: "$11.99/mo",
    icon: spotifyImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://open.spotify.com/"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://open.spotify.com/
          </KLink>
          .
        </>,
        "Click on your profile on the Navigation Bar",
        "Click Account",
        "Click Cancel subscription",
        "Click “Cancel Premium”",
      ],
    },
  },
];

export const useCancellableSubscriptions = () => {
  return CANCELLABLE_SUBSCRIPTIONS;
};

export const getPartitionedSupportedSubscriptions = (): [
  SubscriptionCancellation.SubscriptionInfo[],
  SubscriptionCancellation.SubscriptionInfo[]
] => {
  const subscriptions = useCancellableSubscriptions();

  const mailingCancellationEnabled = useSelector(
    selectFeatureFlag("mailing_cancellation")
  );

  const subscriptionCancellationEnabled = useSelector(
    selectFeatureFlag("subscription_cancellation")
  );

  return partition(subscriptions, (subscription) =>
    subscription.mail
      ? mailingCancellationEnabled && subscription.supported
      : subscriptionCancellationEnabled && subscription.supported
  );
};

export const selectAvailableCancellations = (): Array<SubscriptionCancellation.SubscriptionInfo> => {
  const [subscriptionCancellations] = useSelector(
    selectAllSubscriptionCancellations.load()
  );

  const [supportedSubscriptions, _] = getPartitionedSupportedSubscriptions();

  const alreadyCancelledProvidersSet = new Set(
    subscriptionCancellations.map(
      (cancellation) => cancellation.subscriptionProvider
    )
  );

  return supportedSubscriptions.filter(
    (subscription) => !alreadyCancelledProvidersSet.has(subscription.provider)
  );
};

export const getCancellableSubscriptionInfo = (
  provider: SubscriptionProvider
) => {
  return CANCELLABLE_SUBSCRIPTIONS.find((sub) => sub.provider === provider);
};

const MISSING_SUBSCRIPIION_SUGGESTIONS = [
  "Starz",
  "Anytime Fitness",
  "Apple One",
  "Paramount+",
  "Google One",
  "Youtube Premium",
];

export const useMissingSubscriptionSuggestions = () => {
  return MISSING_SUBSCRIPIION_SUGGESTIONS;
};
