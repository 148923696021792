/* eslint-disable no-restricted-syntax */
/**
 * @file Contains Object related utility functions
 */
import _deepEqual from "fast-deep-equal";

import UFunction from "./function";
/**
 * This function works similarly to the Lodash _.pick function,
 * but adds the ability to specify property defaults
 *
 * @function
 * @param {Object} obj - The object to be filtered
 * @param {Object|Array} props - List of props to be extracted
 * @returns {Object} Object with filtered properties and includes
 *    specified default properties if they are absent in obj parameter
 */
export const pick = <
  Obj extends Record<string, any>,
  Props extends readonly (keyof Obj)[] | Partial<Obj>
>(
  obj: Obj,
  props: Props
): Pick<
  Obj,
  Props extends readonly (keyof Obj)[] ? Props[number] : keyof Props
> => {
  let newObj: any = {};
  if (props instanceof Array) {
    for (const prop of props) {
      if (prop instanceof Object) {
        newObj = {
          ...newObj,
          ...pick(obj, prop),
        };
      } else if (obj instanceof Object && prop in obj) {
        newObj[prop] = obj[prop];
      }
    }
  } else {
    for (const [prop, value] of Object.entries(props)) {
      if (obj instanceof Object) {
        if (obj[prop] !== undefined || value !== undefined) {
          newObj[prop] = obj[prop] || value;
        }
      } else if (value !== undefined) newObj[prop] = value;
    }
  }
  return newObj;
};

export const typedPick = <
  Obj extends Record<string, any>,
  Props extends keyof Obj
>(
  obj: Obj,
  props: readonly Props[],
  { notNullish = false } = {}
): Pick<Obj, Props> => {
  const res: any = {};
  for (const prop of props) {
    if (notNullish && obj[prop] == null) continue;
    res[prop] = obj[prop];
  }
  return res;
};

export function formToObject(form) {
  return Object.fromEntries(
    Array.from(form.querySelectorAll("input")).map(({ name, value }: any) => [
      name,
      value,
    ])
  );
}

/**
 * Creates a new Object from [obj] without specified properties
 *
 * @function
 * @param {Object} obj - Initial Object
 * @param {Object} props - Properties to be removed
 * @return {Object} Object without specified properties
 */
export const remove = (obj, props) => {
  const newObj = { ...obj };
  for (const prop of props) {
    delete newObj[prop];
  }
  return newObj;
};

export function setAll<T extends readonly any[], U = any>(
  props: T,
  value: U
): { [key in T[number]]: U } {
  const obj: any = {};
  for (const prop of props) {
    obj[prop] = value;
  }
  return obj;
}

export const hasNOfType = (obj, props, type, n = 1) => {
  let count = 0;
  for (const prop of props) {
    switch (typeof obj[prop]) {
      case type:
        count += 1;
        if (count > n) return false;
        break;
      case "undefined":
        break;
      default:
        return false;
    }
  }
  return count === n;
};

export const deepEqual = _deepEqual;

export function objectIndexOf(arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    if (deepEqual(arr[i], obj)) return i;
  }
  return -1;
}

export function deepExtend(obj, props, { keySplit = "|" } = {}) {
  for (const [key, value] of Object.entries(props)) {
    const keys = key.split(keySplit);
    if (typeof value === "object")
      for (const _key of keys) {
        // eslint-disable-next-line no-param-reassign
        if (typeof obj[_key] !== "object") obj[_key] = {};
        deepExtend(obj[_key], value);
      }
    // eslint-disable-next-line no-param-reassign
    else for (const _key of keys) obj[_key] = value;
  }
  return obj;
}

export function getMethods(obj, { includePrivate = false } = {}) {
  const props = new Set<string>();
  let currentObj = obj;
  while (currentObj) {
    Object.getOwnPropertyNames(currentObj).map((item) => props.add(item));
    currentObj = Object.getPrototypeOf(currentObj);
  }

  return [...props].filter(
    (prop) =>
      (includePrivate || prop[0] !== "_") && typeof obj[prop] === "function"
  );
}

export function getEnumKey<T extends { [key: string]: string | number }>(
  Enum: T,
  value: T[keyof T]
) {
  for (const [status, val] of Object.entries(Enum)) {
    if (value === val) return status as keyof T;
  }

  throw new Error(`Key with value "${value}" does not exist`);
}

export function nestedObject(values: { [key: string]: any }) {
  const res = {};

  for (const [path, value] of Object.entries(values)) {
    let current = res;
    const ancestors = path.split(".");
    const prop = ancestors.pop();
    for (const ancestor of ancestors) {
      current[ancestor] = {};
      current = current[ancestor];
    }
    current[prop] = value;
  }

  return res;
}

type Tail<T extends readonly any[]> = ((...x: T) => void) extends (
  h: infer A,
  ...t: infer R
) => void
  ? R
  : never;

type Length<T> = T extends { length: infer R } ? R : never;

type DeepObjectAccess<
  Obj extends { [key: string]: any },
  Props extends readonly any[]
> = Length<Props> extends 0
  ? Obj
  : Props[0] extends string
  ? DeepObjectAccess<Obj[Props[0]], Tail<Props>>
  : Obj;

export function filteredObjectAccess<
  Obj extends { [key: string]: any },
  Props extends readonly any[]
>(obj: Obj, props: Props): DeepObjectAccess<Obj, Props> {
  let current = obj;

  for (const prop of props) if (prop) current = current[prop];

  return current as DeepObjectAccess<Obj, Props>;
}

export function serialize(
  obj: Record<string, any>,
  { ignoreNullish = false } = {}
) {
  const str = [];
  for (const prop in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, prop) &&
      (!ignoreNullish || obj[prop] != null)
    )
      str.push(`${encodeURIComponent(prop)}=${encodeURIComponent(obj[prop])}`);
  return str.join("&");
}

export const transformEntries = <
  Obj extends {},
  Result extends [RecordKey, any]
>(
  obj: Obj,
  transform: (entry: [keyof Obj, Obj[keyof Obj]]) => Result
) =>
  Object.fromEntries(
    Object.entries<Obj[keyof Obj]>(obj || ({} as never)).map(
      transform as any
    ) as any
  ) as TupleToObject<Result>;

export const prefixKeys = <
  Obj extends Record<string, any>,
  Prefix extends string
>(
  obj: Obj,
  prefix: Prefix
) =>
  transformEntries(obj, ([key, value]) => [
    `${prefix}${key as string}` as `${Prefix}${typeof key extends string
      ? typeof key
      : string}`,
    value,
  ]);

export const keysToEnumValues = <E extends Record<string, number | string>>(
  _enum: E
) => <V>(obj: Record<keyof E, V>) =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [_enum[k], v])
  ) as Record<E[keyof E], V>;

export const bindMethods = <T extends {}>(obj: T) => {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "function") obj[key] = value.bind(obj);
  }

  return obj;
};

export const wrapMethods = <Obj extends Record<any, any>>(
  obj: Obj,
  methods: (keyof Obj)[],
  wrapper: (
    method: (...args: any) => any,
    meta: { key: string | number | symbol }
  ) => (...args: any) => any
) => {
  for (const key of methods) {
    const method = obj[key].bind(obj);
    (obj[key] as any) = wrapper(method, { key });
  }
};

export const MaybeWeakMap = typeof WeakMap !== "undefined" ? WeakMap : Map;

export const trimKeys = <
  Obj extends Record<string, any>,
  Prefix extends string,
  Suffix extends string = ""
>(
  obj: Obj,
  prefix: Prefix,
  suffix = "" as Suffix
): {
  [Key in keyof Obj as Key extends `${Prefix}${infer T}${Suffix}`
    ? T
    : never]: Obj[Key];
} =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (!k.startsWith(prefix))
        throw new Error(
          `Found key "${k}" that does not start with prefix "${prefix}"`
        );
      if (!k.endsWith(suffix))
        throw new Error(
          `Found key "${k}" that does not start with suffix "${suffix}"`
        );

      return [k.slice(prefix.length).slice(0, -suffix.length || Infinity), v];
    })
  ) as any;

export type Key = any extends Record<infer T, any> ? T : never;

export type AnyObject =
  | Record<Key, any>
  | Record<string, any>
  | Record<number, any>
  | Record<symbol, any>;

export const deepKey = (() => {
  const createDeepKey = (...replaceArgs: Parameters<string["replace"]>) => (
    obj: AnyObject
  ): AnyObject =>
    obj &&
    ((Array.isArray(obj)
      ? obj.map((v) =>
          typeof v === "object" ? createDeepKey(...replaceArgs)(v) : v
        )
      : Object.fromEntries(
          Object.entries(obj).map(([k, v]) => [
            k.replace(...replaceArgs),
            typeof v === "object" && v !== null
              ? createDeepKey(...replaceArgs)(v as AnyObject)
              : v,
          ])
        )) as AnyObject);

  return {
    toCamel: createDeepKey(/_[a-z]/g, (s) => s[1].toUpperCase()),
    toSnake: createDeepKey(/[A-Z]/g, (s) => `_${s.toLowerCase()}`),
  };
})();

export const inverseMap = <Obj extends Record<any, any>>(obj: Obj) =>
  (transformEntries(obj, ([k, v]) => [v, k]) as unknown) as {
    [Key in keyof Obj as Obj[Key]]: Key;
  };

export namespace UObject {
  export type Values<T> = T[keyof T];
  export type Key<T = any> = T extends Record<infer T, any> ? T : never;
  export type Any =
    | Record<Key, any>
    | Record<string, any>
    | Record<number, any>
    | Record<symbol, any>;
  export const firstValue = <T>(obj: Record<any, T>) =>
    obj ? Object.values(obj).find(Boolean) : undefined;

  export const mut = {
    setByPath: (() => {
      const withConfig = ({ createParents = false } = {}) => <
        Obj extends UObject.Any
      >(
        obj: Obj,
        path: UObject.Key[],
        value: any
      ) =>
        (function next(current: any, i = 0) {
          if (!current)
            throw new Error(
              "Field in path is undefined, if you need to create parents as needed, use setByPath.createParents."
            );
          const property = path[i] as keyof Obj;
          if (i === path.length - 1) {
            current[property] = value;
          } else {
            if (createParents) current[property] ??= {};
            next(current[property], i + 1);
          }
        })(obj);

      return Object.assign(withConfig(), {
        createParents: withConfig({ createParents: true }),
      });
    })(),
  };
  // TODO: This kills editor performance, needs more optimization before we can
  // use this in Select
  export type Path<T extends Any, Prefix extends Key[] = []> = {
    [Key in keyof T]:
      | [...Prefix, Key]
      | (T[Key] extends Any
          ? T[Key] extends T
            ? UObject.Key[]
            : Path<T[Key], [...Prefix, Key]>
          : never);
  }[keyof T];
}

export type Select<T, R = any> = keyof T | ((obj: T) => R);
export declare namespace Select {
  type Subject<S extends Select<any>> = S extends Select<infer T> ? T : never;
  type Result<T, S extends Select<T>> = S extends UFunction.Any
    ? ReturnType<S>
    : S extends keyof T
    ? T[S]
    : never;
}
export function createSelector<S extends Select<any>>(
  select: S
): <Obj extends Select.Subject<S>>(
  obj: Obj
) => Select.Result<Obj, S extends Select<Obj, any> ? S : never> {
  if (typeof select !== "function") {
    const key = select;
    // @ts-expect-error
    select = (obj) => obj[key];
  }

  return select as any;
}

function snakeToCamel(str: string) {
  return str.replace(/_([a-z]|\d+)/g, (_, char: string) => char.toUpperCase());
}
export function snakeToCamelObject<T extends object>(
  obj: T
): TransformSnakeToCamelCase<T> {
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelObject) as any;
  }

  if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = snakeToCamel(key);
      return { ...acc, [camelKey]: snakeToCamelObject(obj[key]) };
    }, {} as any);
  }

  return obj as any;
}

function camelToSnake(str: string) {
  return str.replace(/([A-Z0-9])/g, "_$1").toLowerCase();
}
export function camelToSnakeObject<T extends object>(
  obj: T
): TransformCamelToSnakeCase<T> {
  if (Array.isArray(obj)) {
    return obj.map(camelToSnakeObject) as any;
  }

  if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = camelToSnake(key);
      return { ...acc, [camelKey]: camelToSnakeObject(obj[key]) };
    }, {} as any);
  }

  return obj as any;
}
