import { web } from "@kikoff/proto/src/protos";

/**
 * @serializable Keep this payload small
 */
export type Payment = {
  label: string;
  amountCents: number;
  dueAt: number;
};

export type AutoPayAccount = {
  token?: string;
  autoPay?: web.public_.IAutoPay;
  paymentOptions?: web.public_.IPaymentOption[];
};

export const accountAutoPayEnabled = (account: AutoPayAccount) =>
  account?.autoPay?.settings.state ===
  web.public_.AutoPay.Settings.State.ENABLED;

export const autoPayState = (enabled: boolean) =>
  web.public_.AutoPay.Settings.State[enabled ? "ENABLED" : "DISABLED"];

export const autoPayStatusIs = (
  account: AutoPayAccount,
  status: keyof typeof web.public_.AutoPay.Attempt.Status
) =>
  account?.autoPay?.lastAttempt.status ===
  web.public_.AutoPay.Attempt.Status[status];
