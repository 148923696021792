/* eslint-disable no-param-reassign */

import { Event, EventHint, init } from "@sentry/nextjs";

import { isClient } from "@kikoff/utils/src/general";

const config: Parameters<typeof init>[0] = {
  enabled: ["production", "staging"].includes(process.env.CURRENT_ENV),
  dsn: process.env.SENTRY_DSN,
  environment: process.env.CURRENT_ENV,
  release: process.env.SENTRY_RELEASE,

  beforeSend(event, hint) {
    if (isAnonymousScript(event)) return null;
    if (isTranslatorExtension(event, hint)) return event;

    return event;
  },

  ignoreErrors: [
    "ResizeObserver",
    // Facebook borked
    "fb_xd_fragment",
    // Instagram webview
    "_AutofillCallbackHandler",
    // Pixel errors
    "SecurityError: Blocked a frame",
    // Unhelpful reports (non-breaking)
    "Non-Error promise rejection captured",
    // Unactionable
    "Failed to load script: /_next",
    /Loading (CSS )?chunk .* failed/,
    /window.*\.postMessage/,
    "Request aborted",
    "Network Error",
  ],
  allowUrls: [
    // This should cover all cases, but added kikoff.com just in case
    ...(isClient ? [window.location.host] : []),
    "https://kikoff.com",
  ],
  denyUrls: [
    // TikTik pixel
    "/i18n/pixel/config.js",
    // Google maps
    "maps-api-v3",
  ],
};

export default config;

// Sort out inline script errors, we don't have any
function isAnonymousScript(event: Event): boolean {
  const ignoreStrs = [
    "<anonymous>",
    "<unknown>",
    "<unknown module>",
    "global code",
  ];

  return event.exception?.values?.[0]?.stacktrace?.frames?.some(
    ({ filename }) => {
      const file = filename.toLowerCase();
      for (const ignoreStr of ignoreStrs) {
        if (file.includes(ignoreStr)) return true;
      }
      return false;
    }
  );
}

// Google Translate or Yandex Translate
function isTranslatorExtension(event: Event, hint: EventHint): boolean {
  const isTranslator = !!document.querySelector(
    "html.translated-ltr, html.translated-rtl, ya-tr-span"
  );
  if (!isTranslator) return false;

  const errorMessage =
    typeof hint.originalException === "string"
      ? hint.originalException
      : hint.originalException?.message;
  const potentialErrors = [
    "Failed to execute 'insertBefore'",
    "Failed to execute 'removeChild'",
  ];

  const isTranslateError = potentialErrors.some((err) =>
    errorMessage.includes(err)
  );
  if (!isTranslateError) return false;

  event.fingerprint = ["translator-extension-error"];
  event.tags = {
    ...event.tags,
    lang: document.documentElement.getAttribute("lang") ?? "unknown",
  };

  return true;
}
