/**
 * @file Contains web app state container
 */
import { Action } from "redux";
import { createLogger } from "redux-logger";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

import { DEBUG } from "@kikoff/client-utils/src/general";
import { isClient } from "@kikoff/utils/src/general";

import protoToObject from "./middleware/protoToObject";
import featuresReducer from "./features_reducer";

export type RootState = ReturnType<typeof featuresReducer>;

const reducer: typeof featuresReducer = (state, action) => {
  if (action.type === "RESET") state = undefined;

  return featuresReducer(state, action);
};
export type AppThunk<R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;

declare global {
  export interface Window {
    Cypress;
  }
}

const store = configureStore({
  reducer,
  devTools: DEBUG,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: DEBUG,
      serializableCheck: DEBUG,
    })
      .concat(DEBUG ? [createLogger({ collapsed: true })] : [])
      .concat(protoToObject),
});

export type Dispatch = ThunkDispatch<RootState, null, Action<string>>;

declare module "react-redux" {
  interface DefaultRootState extends RootState {}
  function useDispatch(): Dispatch;
}

export type AppStore = typeof store;
if (isClient && window.Cypress) window.store = store;

export default store;
