import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { RootState } from "@store";

import { thunk } from "../utils";

const initialState = {
  personaLink: null as string | null,
  email: null as string | null,
  forgotLoginInquiryId: null as string | null,
  forgotLoginInquiryStatus: null as web.public_.ForgotLoginResponse.ForgotLoginStatus | null,
  pii: {
    firstName: null as string | null,
    lastName: null as string | null,
    ssn: null as string | null,
    dob: null as string | null,
  },
  phone: null as string | null,
  hasAssociatedSso: false,
};

export type ForgotLoginState = typeof initialState;
export type PiiForgotLoginState = ForgotLoginState["pii"];

const forgotLoginSlice = createSlice({
  name: "forgotLogin",
  initialState,
  reducers: {
    setForgotLogin(
      state,
      { payload }: PayloadAction<web.public_.ForgotLoginResponse>
    ) {
      state.personaLink = payload.personaVerificationLink;
      state.email = payload.email;
      state.forgotLoginInquiryId = payload.forgotLoginInquiryId;
      state.forgotLoginInquiryStatus = payload.forgotLoginInquiryStatus;
      state.phone = payload.phone;
      state.hasAssociatedSso = payload.hasAssociatedSso;
    },
    setPii(state, { payload }: PayloadAction<PiiForgotLoginState>) {
      state.pii = payload;
    },
    updateForgotLoginPhone(
      state,
      { payload }: PayloadAction<ForgotLoginState["phone"]>
    ) {
      state.phone = payload;
    },
  },
});

const { actions } = forgotLoginSlice;
export const {} = actions;
export default forgotLoginSlice.reducer;

export const selectForgotLogin = () => (state: RootState) => state.forgotLogin;

export const fetchForgotLogin = ({
  firstName,
  lastName,
  dob,
  ssn,
}: PiiForgotLoginState) =>
  thunk((dispatch) =>
    webRPC.Auth.forgotLogin({
      firstName,
      lastName,
      dob: format.date(dob),
      ssn: ssn.replace(/\D/g, ""),
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setForgotLogin(data));
          dispatch(actions.setPii({ firstName, lastName, ssn, dob }));
          return data.forgotLoginInquiryStatus;
        },
        _DEFAULT: handleFailedStatus("Failed to connect to chat."),
      })
    )
  );

export const updateForgotLoginPhone = (phone: string) =>
  thunk((dispatch) => dispatch(actions.updateForgotLoginPhone(phone)));

export const updateForgotLoginPiiCompletion = (finishedUpdating: boolean) =>
  thunk((_, getState) => {
    const id = getState().forgotLogin.forgotLoginInquiryId;
    webRPC.Auth.updateForgotLoginPii({
      finishedUpdating,
      forgotLoginInquiryId: id,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return finishedUpdating;
        },
        _DEFAULT: handleFailedStatus("Failed to update PII completion."),
      })
    );
  });
