interface LogoProps extends React.ComponentPropsWithoutRef<"svg"> {
  color?: React.CSSProperties["color"];
}

export default function Logo({
  color = "var(--inverse)",
  style,
  ...props
}: LogoProps) {
  return (
    <svg
      width="75"
      height="24"
      viewBox="0 0 75 24"
      fill="none"
      style={{ color, ...style }}
      {...props}
    >
      <path
        d="M3.79388 0.737976H0V23.58H3.79388V0.737976Z"
        fill="currentColor"
      />
      <path
        d="M28.6842 0.737976H24.9238V23.58H28.6842V0.737976Z"
        fill="currentColor"
      />
      <path
        d="M18.2607 2.26805C18.2607 1.03066 19.3008 0.000109968 20.5757 0.000109968C21.1652 -0.00578056 21.7335 0.225183 22.1582 0.64335C22.583 1.06152 22.8305 1.63349 22.8473 2.23632C22.8642 2.83914 22.6492 3.42468 22.2485 3.86704C21.8479 4.30941 21.2935 4.57328 20.7046 4.60193H20.5757C19.9686 4.60723 19.3841 4.36625 18.9502 3.93171C18.5163 3.49718 18.2684 2.9045 18.2607 2.28341V2.26805ZM22.4254 23.5891H18.6581V6.42098H22.4166L22.4254 23.5891Z"
        fill="currentColor"
      />
      <path
        d="M55.3094 14.9354C55.3094 20.4305 51.8855 24 46.9217 24C41.958 24 38.5605 20.4305 38.5605 14.9354C38.5605 9.44034 41.9501 6.00275 46.9217 6.00275C51.8934 6.00275 55.3094 9.43492 55.3094 14.9354ZM51.4175 14.9354C51.4175 11.7047 50.0402 9.03028 47.086 9.03028H46.7849C43.798 9.03028 42.4877 11.8131 42.4877 14.9354C42.4877 18.0966 43.798 20.9797 46.7849 20.9797H47.086C50.0737 20.9797 51.4175 18.233 51.4175 14.9354Z"
        fill="currentColor"
      />
      <path
        d="M71.9891 3.73487H74.9116V0.738037H70.7566C68.0522 0.738037 66.8276 2.24277 66.8276 4.87381V6.3939H60.9121V5.17186C60.9116 4.79138 61.0588 4.42628 61.3214 4.15682C61.584 3.88736 61.9404 3.73559 62.3124 3.73487H65.241V0.738037H61.0842C58.379 0.738037 57.1553 2.24277 57.1553 4.87381V23.5819H60.9121V9.40156H66.8276V23.5819H70.5844V9.40156H74.9107V6.40293H70.5844V5.17186C70.5842 4.79146 70.7315 4.4265 70.994 4.15708C71.2565 3.88767 71.6128 3.73582 71.9847 3.73487"
        fill="currentColor"
      />
      <path
        d="M3.79395 11.6225L12.194 23.5818H16.9608L8.32947 11.6225L16.5362 0.737976H11.7481L3.79395 11.6225Z"
        fill="currentColor"
      />
      <path
        d="M28.6826 14.6013L34.9972 23.5909H39.4436L32.9444 14.6031L39.1743 6.41644H34.6652L28.6826 14.6013Z"
        fill="currentColor"
      />
    </svg>
  );
}

interface LogoIconProps extends React.ComponentProps<"svg"> {
  size?: React.CSSProperties["width"];
}

export function LogoIcon({ size, ...props }: LogoIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
      {...props}
    >
      <path
        d="M0 64C0 28.6538 28.6538 0 64 0H448C483.346 0 512 28.6538 512 64V448C512 483.346 483.346 512 448 512H64C28.6538 512 0 483.346 0 448V64Z"
        fill="#00C434"
      />
      <path d="M376 126H301.176V386H376V126Z" fill="#005937" />
      <path
        d="M116 126L213.21 256.01L116 386H203.966L301.176 256.01L203.966 126H116Z"
        fill="#005937"
      />
    </svg>
  );
}
