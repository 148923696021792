import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./WithTrailing.module.scss";

declare namespace WithTrailing {
  interface Props extends React.ComponentPropsWithRef<"div"> {
    trailing: React.ReactNode;
    center?: boolean;
  }
}

export default WithTrailing;
function WithTrailing({
  trailing,
  children,
  center,
  className,
  ...props
}: WithTrailing.Props) {
  return (
    <div
      className={combineClasses(
        styles["with-trailing"],
        center && styles.center,
        className
      )}
      {...props}
    >
      <div>{children}</div>
      <div>{trailing}</div>
    </div>
  );
}
