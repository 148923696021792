import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { Promo } from "@feature/user";

export const applyPromo = (request: web.public_.IApplyPromoRequest) => () => {
  return webRPC.Promotions.applyPromo(request).then(
    handleProtoStatus({
      SUCCESS() {},
      _DEFAULT: handleFailedStatus("Failed apply promo"),
    })
  );
};

export const exposeIntroTestPromo = () => () => {
  return webRPC.Promotions.exposeIntroTestOffer({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        return data.promo as Promo;
      },
      _DEFAULT: handleFailedStatus("Failed to get available promos"),
    })
  );
};
