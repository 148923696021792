import React, { useContext } from "react";

import { combineClasses } from "@kikoff/utils/src/string";

export function mergeRefs<T = any>(
  ...refs: (React.MutableRefObject<T> | React.ForwardedRef<T>)[]
): React.RefCallback<T> {
  return (value) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    }
  };
}

// if n === "all", this will perform a filter instead of find, using the same
// function because the majority of the logic is the same. Code for this case
// are tagged with all comment
export function findChild<N extends number | "all" = 0>(
  children: React.ReactNode,
  predicate: (child: React.ReactNode) => boolean,
  n?: N
): N extends number ? React.ReactNode : React.ReactNode[] {
  // all
  const childrenAccumulator: React.ReactNode[] = [];
  const foundChild = (function traverse(_children) {
    const childArray = React.Children.toArray(_children);

    for (const child of childArray) {
      if (predicate(child)) {
        // all
        if (n === "all") childrenAccumulator.push(child);
        else if (!n) return child;
        else if (typeof n === "number") n--;
      }
      if (React.isValidElement(child) && child.props.children) {
        const res = traverse(child.props.children);
        if (res) return res;
      }
    }
  })(children);
  // all
  if (n === "all") return childrenAccumulator;
  return foundChild;
}

export function traverseChildren<T>(
  children: React.ReactNode,
  perform: (child: React.ReactNode, stack: T[]) => T,
  onBacktrack = (child: React.ReactNode, stack: T[], result: T) => {}
) {
  (function traverse(_children, stack) {
    const childArray = [_children].flat(Infinity);
    for (const child of childArray) {
      const res = perform(child, stack);
      const newStack = stack.concat(
        // Do not add breadcrumb if nullish
        res ?? []
      );

      if (!React.isValidElement(child)) continue;
      if (child.props.children) traverse(child.props.children, newStack);
      onBacktrack(child, stack, res);
    }
  })(children, []);
}

export function createPropsProvider<
  Props,
  Ctx extends Partial<Props> = Partial<Props>
>(
  key: string,
  mergers?: Partial<
    {
      [Key in keyof Props]?: (context: Ctx, props: Props) => Props[Key];
    }
  >
) {
  if (createPropsProvider.cache[key]) return createPropsProvider.cache[key];

  const mergerEntries = Object.entries({
    ...mergers,
    ...createPropsProvider.defaultMergers,
  }) as [keyof Props, (context: Ctx, props: any) => any][];
  const Context = React.createContext(
    createPropsProvider.defaultContext as Ctx
  );

  type ProviderProps = Ctx & {
    children: React.ReactNode;
  };

  function PropsProvider({ children, ...props }: ProviderProps) {
    const context = useContext(Context);

    return (
      <Context.Provider value={PropsProvider.merge(context, props as any)}>
        {children}
      </Context.Provider>
    );
  }

  // For PageFlow flattening
  PropsProvider.isProvider = true;

  // Allow any props for props that use generics
  type MergeProps = Record<string, any>;

  PropsProvider.merge = <P extends MergeProps>(context: Ctx, props: P) => {
    const merged = { ...context, ...props };

    for (const [prop, merge] of mergerEntries)
      if (prop in context && prop in props)
        merged[prop] = merge(context, props);

    return merged;
  };

  PropsProvider.useContext = () => useContext(Context);
  PropsProvider.useMerge = <P extends MergeProps>(props: P) =>
    PropsProvider.merge(PropsProvider.useContext(), props);

  PropsProvider.Context = Context;

  createPropsProvider.cache[key] = PropsProvider;
  return PropsProvider;
}

createPropsProvider.defaultMergers = {
  className: (context, props) =>
    combineClasses(context.className, props.className),
  style: (context, props) => ({ ...context.style, ...props.style }),
  propsFor: (context, props) =>
    Object.fromEntries(
      [
        ...new Set([
          ...Object.keys(context.propsFor || {}),
          ...Object.keys(props.propsFor || {}),
        ]),
      ].map((key) => [
        key,
        {
          ...context.propsFor?.[key],
          ...props.propsFor?.[key],
          className: combineClasses(
            context.propsFor?.[key]?.className,
            props.propsFor?.[key]?.className
          ),
        },
      ])
    ),
};

createPropsProvider.defaultContext = {};

// Keep references in dev refresh
createPropsProvider.cache = {};

export const flattenedChildren = (
  elements: React.ReactNode,
  keyPrefix = ""
): React.ReactNode[] =>
  React.Children.toArray(elements).flatMap((element) => {
    if (!React.isValidElement(element)) return element;
    const { type } = element;
    if (type === React.Fragment)
      return flattenedChildren(element.props.children, keyPrefix + element.key);
    if ((type as any).isProvider) {
      const prefix = keyPrefix + element.key;
      // Wrap each child individually in provider
      return flattenedChildren(element.props.children).map((child, i) =>
        React.isValidElement(child)
          ? React.cloneElement(
              element,
              { key: prefix + child.key },
              child.props.children
                ? React.cloneElement(
                    child,
                    {},
                    flattenedChildren(child.props.children)
                  )
                : child
            )
          : React.cloneElement(element, { key: prefix + `.${i}` }, child)
      );
    }
    return React.cloneElement(element, { key: keyPrefix + element.key });
  });

export function renderFirstTruthyNode(
  ...nodes: (React.ReactNode | (() => React.ReactNode))[]
): React.ReactNode {
  for (const node of nodes) {
    const res = typeof node === "function" ? node() : node;
    if (res) {
      return res;
    }
  }

  return null;
}

/**
 * Determines if a given element supports a `ref`.
 * This function checks if the element is either a built-in HTML element (e.g., `div`, `span`)
 * or a custom React component that forwards its ref using `React.forwardRef`.
 *
 * @param element - The element to check. Can be a string representing an HTML tag
 *                  (e.g., "div", "span") or a React component constructor.
 *
 * @returns {boolean} - Returns `true` if the element is either a built-in HTML element
 *                      or a React component that supports refs via `forwardRef`.
 *                      Otherwise, it returns `false`.
 *
 * @example
 * const isRefSupported = supportsRef('div'); // true
 * const isRefSupported = supportsRef(MyForwardRefComponent); // true
 * const isRefSupported = supportsRef(MyRegularComponent); // false
 */
export function supportsRef(
  element: React.JSXElementConstructor<any> | string
) {
  return (
    typeof element === "string" || // built-in HTML elements like div, span, etc
    (typeof element === "object" &&
      (element as React.ExoticComponent).$$typeof ===
        Symbol.for("react.forward_ref")) // For forward-ref components
  );
}
