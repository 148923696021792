import React from "react";

import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import useInitial from "@kikoff/hooks/src/useInitial";
import { combineClasses } from "@kikoff/utils/src/string";

import { useOverlayBackdrop } from "@src/overlay";

import styles from "./darken.module.scss";

interface DarkenBackdropProps {
  noClose?: boolean;
  onClick?(): void;
}

export default function DarkenBackdrop({
  noClose,
  onClick,
}: DarkenBackdropProps) {
  const { show, removeCurrent } = useOverlayBackdrop();
  const initial = useInitial({ rerender: true });

  return (
    <ContainerButton
      onClick={() => {
        if (initial) return;
        if (!show) return;
        if (noClose) return;
        removeCurrent();
        onClick?.();
      }}
      className={combineClasses(styles["backdrop"], show && styles.show)}
    />
  );
}
