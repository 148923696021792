import React, { useContext, useMemo } from "react";

import useUpdate from "./useUpdate";

export class DevTool {
  triggers = new Map<() => void, React.ReactNode>();

  constructor(private update: () => void) {}

  addTrigger(trigger: () => void, label: React.ReactNode) {
    this.triggers.set(trigger, label);
    this.update();
  }

  removeTrigger(trigger: () => void) {
    this.triggers.delete(trigger);
    this.update();
  }
}

const DevToolContext = React.createContext(
  new DevTool(() => {
    throw new Error(
      "DevToolContext: Tried to update outside of DevToolProvider"
    );
  })
);

interface DevToolProviderProps {
  children: ((devtool: DevTool) => React.ReactNode) | React.ReactNode;
}

export const DevToolProvider = ({ children }: DevToolProviderProps) => {
  const update = useUpdate();
  const devtool = useMemo(() => new DevTool(update), []);

  return (
    <DevToolContext.Provider value={devtool}>
      {typeof children === "function" ? children(devtool) : children}
    </DevToolContext.Provider>
  );
};

const useDevTool = () => useContext(DevToolContext);
export default useDevTool;
