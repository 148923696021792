import { cls } from "@kikoff/utils/src/string";

import { Negotiation } from "@feature/negotiations";

import styles from "./NegotiationNotCompletedReasons.module.scss";

interface NegotiationNotCompletedReasonsProps {
  className?: string;
}

export default function NegotiationNotCompletedReasons({
  className,
}: NegotiationNotCompletedReasonsProps) {
  return (
    <div className={cls(styles["reasons"], className)}>
      <ul>
        <li>
          <b>Statute of Limitations:</b> The Statute of Limitations has passed,
          and we do not conduct debt negotiations to reduce the negative impact
          on your credit.
        </li>
        <li>
          <b>Existing Arrangement:</b> You have an existing arrangement with a
          debt settlement company or directly with the creditor or debt owner.
        </li>
        <li>
          <b>Ineligible Debt Servicer:</b> The debt is not serviced by an
          eligible provider ({Negotiation.Debt.creditors().join(", ")}).
        </li>
        <li>
          <b>Ongoing Dispute:</b> You have an existing dispute for this debt
          account.
        </li>
      </ul>
    </div>
  );
}
