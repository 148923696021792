import { sumBy } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import {
  fetchDebtSettlementAccounts,
  selectDebtAccount,
} from "./debt_settlement";

const initialState = {
  pockets: {} as Record<string, web.public_.IPocket>,
  transactionsByPocketToken: {} as Record<
    string,
    {
      transactions: web.public_.IPocketTransaction[];
      nextRequest: web.public_.IListPocketTransactionsRequest;
    }
  >,
  availableBalance: null as number,
};

export type BankingState = typeof initialState;

const bankingSlice = createSlice({
  name: "banking",
  initialState,
  reducers: {
    setPockets(state, { payload }: PayloadAction<BankingState["pockets"]>) {
      state.pockets = payload;
    },
    updatePockets(state, { payload }: PayloadAction<BankingState["pockets"]>) {
      Object.assign(state.pockets, payload);
    },
    addRecentPocketTransaction(
      state,
      { payload }: PayloadAction<Record<string, web.public_.IPocketTransaction>>
    ) {
      for (const [token, transaction] of Object.entries(payload)) {
        state.transactionsByPocketToken[token] ??= {
          transactions: [],
          nextRequest: { pocketToken: token },
        };
        const data = state.transactionsByPocketToken[token];
        data.transactions.unshift(transaction);
      }
    },
    addPocketTransactions(
      state,
      { payload }: PayloadAction<BankingState["transactionsByPocketToken"]>
    ) {
      for (const [token, { transactions, nextRequest }] of Object.entries(
        payload
      )) {
        state.transactionsByPocketToken[token] ??= {
          transactions: [],
          nextRequest,
        };
        const data = state.transactionsByPocketToken[token];
        const existingTokens = new Set(
          data.transactions.map(({ token }) => token)
        );
        const deduped = transactions.filter(
          ({ token }) => !existingTokens.has(token)
        );
        if (deduped.length === 0) return;

        data.transactions.push(...transactions);
        data.nextRequest = nextRequest;

        if (deduped.length !== transactions.length)
          // This means something weird is happening, need to reconcile
          data.transactions.sort(
            (a, b) => b.transactedAt.seconds - a.transactedAt.seconds
          );
      }
    },
    setAvailableBalance(
      state,
      { payload }: PayloadAction<BankingState["availableBalance"]>
    ) {
      state.availableBalance = payload;
    },
  },
});

const { actions } = bankingSlice;
export const { updatePockets } = actions;
export default bankingSlice.reducer;

export const selectPocket = createLoadableSelector(
  (token: string) => (state: RootState) => state.banking.pockets[token],
  {
    // Always fetch debt accounts for now, pockets are only used for those.
    // Later we'll only want to fetch debt accounts if a pocket has a linked
    // debt account.
    loadAction: fetchDebtSettlementAccounts,
  }
);

// eslint-disable-next-line import/export
export declare namespace selectPocketLinkedAccounts {
  type AccountByType = {
    [Type in Exclude<
      keyof typeof web.public_.Pocket.LinkedAccount.Type,
      number
    >]: ReturnType<ReturnType<typeof selectPocketLinkedAccounts.byType[Type]>>;
  };

  type ResolvedAccountList = {
    [Type in keyof AccountByType]: {
      type: typeof web.public_.Pocket.LinkedAccount.Type[Type];
      account: AccountByType[Type];
    };
  }[keyof AccountByType][];
}

// eslint-disable-next-line import/export
export const selectPocketLinkedAccounts = (() => {
  const { Type } = web.public_.Pocket.LinkedAccount;

  type Exhaustive = Record<
    keyof typeof Type,
    (token: string) => (state: RootState) => any
  >;
  const selectAccountByType = (<T extends Exhaustive>(x: T) => x)({
    UNKNOWN: () => () => null as null,
    DEBT_SETTLEMENT_ACCOUNT: selectDebtAccount,
  });

  return Object.assign(
    (pocket: web.public_.IPocket) => (state: RootState) =>
      pocket?.linkedAccounts.map(({ type, token }) => ({
        type,
        account: selectAccountByType[Type[type]](token)(state),
      })) as selectPocketLinkedAccounts.ResolvedAccountList,
    {
      byType: Object.fromEntries(
        Object.entries(selectAccountByType).map(([type, select]) => [
          type,
          (pocket) => (state) => {
            const token = pocket?.linkedAccounts.find(
              (account) => account.type === Type[type]
            )?.token;
            if (!token) return null;
            return select(token)(state);
          },
        ])
      ) as {
        [Type in keyof Exhaustive]: (
          pocket: web.public_.IPocket
        ) => (
          state: RootState
        ) => ReturnType<ReturnType<typeof selectAccountByType[Type]>>;
      },
    }
  );
})();

export const selectPocketTransactions = createLoadableSelector(
  (pocketToken: string) => (state: RootState) =>
    state.banking.transactionsByPocketToken[pocketToken]?.transactions,
  { loadAction: (token) => fetchMorePocketTransactions(token) }
);

export const selectHasMorePocketTransactions = (pocketToken: string) => (
  state: RootState
) => !!state.banking.transactionsByPocketToken[pocketToken]?.nextRequest;

export const fetchPocket = (token: string) => fetchAllPockets();

export const fetchAllPockets = () =>
  thunk((dispatch) =>
    webRPC.Pockets.listPockets({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.setPockets(
              Object.fromEntries(
                data.pockets.map((pocket) => [pocket.token, pocket])
              )
            )
          );
          return data.pockets;
        },
        _DEFAULT: handleFailedStatus("Failed to get buckets."),
      })
    )
  );

/**
 * @deprecated
 */
export const initiatePocketTransfer = (
  pocketToken: string,
  {
    from,
    amountCents,
  }: { from: web.public_.ITransferEntityReference; amountCents: number }
) =>
  thunk((dispatch, getState) =>
    webRPC.Pockets.initiatePocketTransfer({ pocketToken, amountCents }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(fetchPocket(pocketToken));
          dispatch(
            actions.addRecentPocketTransaction({
              [pocketToken]: data.pocketTransaction,
            })
          );
          return data.pocketTransaction;
        },
        _DEFAULT: handleFailedStatus("Failed to initiate bucket transfer."),
      })
    )
  );

export const createPocketDeposit = (
  pocketToken: string,
  {
    from,
    amountCents,
  }: {
    from: { galileoAccountToken: string } | { paymentMethodToken: string };
    amountCents: number;
  }
) =>
  thunk((dispatch) =>
    webRPC.Pockets.createPocketDeposit({
      pocketToken,
      amountCents,
      ...from,
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.addRecentPocketTransaction({
              [pocketToken]: data.pocketTransaction,
            })
          );
          return data.pocketTransaction;
        },
        _DEFAULT: handleFailedStatus("Failed to make deposit."),
      })
    )
  );

export const updatePocket = (
  pocketToken: string,
  options: Omit<web.public_.IUpdatePocketRequest, "pocketToken">
) =>
  thunk((dispatch) =>
    webRPC.Pockets.updatePocket({ pocketToken, ...options }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.updatePockets({ [pocketToken]: data.pocket }));
        },
        _DEFAULT: handleFailedStatus("Failed to update bucket."),
      })
    )
  );

export const fetchMorePocketTransactions = Object.assign(
  (pocketToken: string) =>
    thunk((dispatch, getState) => {
      const transactionData = getState().banking.transactionsByPocketToken[
        pocketToken
      ];

      if (transactionData && !transactionData.nextRequest)
        return Promise.resolve();

      return webRPC.Pockets.listPocketTransactions({
        pocketToken,
        page: 1,
        pageSize: 5,
        ...transactionData?.nextRequest,
      }).then(
        handleProtoStatus({
          SUCCESS(data) {
            const transactionData = {
              transactions: data.pocketTransactions,
              nextRequest: data.nextRequest,
            };
            dispatch(
              actions.addPocketTransactions({
                [pocketToken]: transactionData,
              })
            );
            return transactionData;
          },
          _DEFAULT: handleFailedStatus("Failed to get transactions."),
        })
      );
    }),
  {
    ifNotPresent: (pocketToken: string) =>
      thunk((dispatch, getState) => {
        const transactionData = getState().banking.transactionsByPocketToken[
          pocketToken
        ];

        return Promise.resolve(
          transactionData || dispatch(fetchMorePocketTransactions(pocketToken))
        );
      }),
  }
);

export const fetchGalileoAvailableBalance = () =>
  thunk((dispatch) => {
    return webRPC.Galileo.galileoAvailableBalance({}).then((data) => {
      dispatch(actions.setAvailableBalance(data.amountCents));
    });
  });

export const Pocket = {
  willBeFunded: Object.assign(
    (pocket: web.public_.IPocket) =>
      Pocket.willBeFunded.with(pocket) >= pocket.goalAmountCents,
    {
      with: (pocket: web.public_.IPocket) =>
        pocket.pendingAmountCents +
        pocket.balanceCents +
        // Add up all upcoming scheduled transfers before targetDate
        sumBy(
          pocket.transferSchedules,
          ({ nextTransferAt, destination, status, fixedAmountCents }) =>
            nextTransferAt?.seconds < pocket.targetDate.seconds &&
            destination.pocketToken === pocket.token &&
            status === web.public_.TransferSchedule.Status.ACTIVE
              ? fixedAmountCents
              : 0
        ),
    }
  ),
};
