// @ts-nocheck
/* eslint-disable */
import { isClient } from "@app/../../packages/utils/src/general";

if(isClient && process.env.REALLY_PRODUCTION) {
  !function(w,d,s,t,a) {
      if(w.amzn)return;
      w.amzn=a=function() {
          w.amzn.q.push([arguments,(new Date).getTime()])
      };
      a.q=[];
      a.version="0.0";
      s=d.createElement("script");
      s.src="https://c.amazon-adsystem.com/aat/amzn.js";
      s.id="amzn-pixel";
      s.async=true;
      t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t)
  }(window,document);

  window.amzn("setRegion", "NA");
  window.amzn("addTag", "6e680d11-ca05-4a10-846e-7ed2a42e2f5a");
  window.amzn("trackEvent", "PageView");
}
