export default {
  "/onboarding": {
    authRedirect: true,
  },
  "/onboarding/*": {
    noauth: "/onboarding",
  },
  "/login": {
    authRedirect: true,
  },
  "/dashboard*": {
    noauth: "/login",
  },
  "/credit_monitor/*": {
    noauth: "/login",
  },
  "/cash-card-signup": {
    authRedirect: true,
  },
  "/chatbot": {
    authRedirect: true,
  },
  "/pages/promo/*": {
    authRedirect: true,
  },
};
