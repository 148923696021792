import * as $protobuf from "protobufjs/light";

import protos from "./protodefs.json";

const $root =
  $protobuf.roots.default ||
  ($protobuf.roots.default = new $protobuf.Root().addJSON(protos.nested));

export const web = {
  public_: $root.nested.web.nested.public,
};

export const google = {
  protobuf: $root.nested.google.nested.protobuf,
};

export const kikoff = {
  api: {
    mfa: $root.nested.kikoff.nested.api.nested.mfa,
    hello_privacy: $root.nested.kikoff.nested.api.nested.hello_privacy,
    testing: {
      experiments:
        $root.nested.kikoff.nested.api.nested.testing.nested.experiments,
    },
  },
};

export const views = {
  public_: $root.nested.views.nested.public,
  base: $root.nested.views.nested.base,
  common: $root.nested.views.nested.common,
};
