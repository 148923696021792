/* eslint-disable */
import { isClient } from "@app/../../packages/utils/src/general";

if (isClient && process.env.REALLY_PRODUCTION) {
!function(q,e,v,n,t,s) {
  if(q.qp) return;
  n=q.qp=function() {
    n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);
  };
  n.queue=[];
  t=document.createElement(e);
  t.async=!0;
  t.src=v;
  s=document.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s);
}(window, 'script', 'https://a.quora.com/qevents.js');

window.qp('init', '354d1feeb83b42268f568d7ba66f94fc');
window.qp('track', 'ViewContent');
}
