import { capitalize, orderBy } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import Table from "@kikoff/utils/src/table";

import NewCollection from "@page/dashboard/credit-score/credit-alerts/_views/new/collection";
import NewInquiry from "@page/dashboard/credit-score/credit-alerts/_views/new/inquiry";
import NewTradeline from "@page/dashboard/credit-score/credit-alerts/_views/new/tradeline";
import { useBackendExperiment } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

// eslint-disable-next-line unused-imports/no-unused-imports
import { Bureau } from "./credit";

const initialState = {
  ids: null as CreditAlert.Id[],
  byId: {} as CreditAlert.ById,
  mostRecentIdByChangeTypeByType: {} as CreditAlert.Id.ByChangeType.ByType,
};

export type CreditAlertsState = typeof initialState;

const creditAlertsSlice = createSlice({
  name: "creditAlerts",
  initialState,
  reducers: {
    setCreditAlerts(state, { payload }: PayloadAction<CreditAlert[]>) {
      const creditAlerts = orderBy(
        payload.filter((creditAlert) => CreditAlert.isSupported(creditAlert)),
        ({ reportedDate }) => reportedDate?.seconds,
        "desc"
      );

      state.ids = creditAlerts.map(({ id }) => id);
      state.byId = CreditAlert.ById.fromList(creditAlerts);
      state.mostRecentIdByChangeTypeByType = CreditAlert.Id.ByChangeType.ByType.fromList(
        creditAlerts.toReversed()
      );
    },
  },
});

const { actions } = creditAlertsSlice;
export const {} = actions;
export default creditAlertsSlice.reducer;

export const selectCreditAlerts = createLoadableSelector(
  () => (state: RootState) =>
    state.creditAlerts.ids?.map((id) => state.creditAlerts.byId[id]),
  {
    loadAction: () => fetchCreditAlerts(),
  }
);

export const selectCreditAlert = Object.assign(
  (id: CreditAlert.Id) => (state: RootState) => state.creditAlerts.byId[id],
  {
    mostRecentByChangeTypeByType: (
      changeType: web.public_.CreditMonitor.AlertChangeType,
      type: web.public_.CreditMonitor.AlertType
    ) => (state: RootState) =>
      state.creditAlerts.byId[
        state.creditAlerts.mostRecentIdByChangeTypeByType[type]?.[changeType]
      ],
  }
);

export const fetchCreditAlerts = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.Account.getCreditMonitoringAlerts({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setCreditAlerts(data.creditAlerts));
            return data.creditAlerts;
          },
          _DEFAULT: handleFailedStatus("Failed to load credit alerts."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { ids, byId } = getState().creditAlerts;

        return Promise.resolve(
          ids?.map((id) => byId[id]) || dispatch(fetchCreditAlerts())
        );
      }),
  }
);

export const useCreditAlertsEnabled = () =>
  useBackendExperiment("creditAlertMonitoring") === "candidate";

export namespace CreditAlerts {
  export const bureau: Bureau = "equifax";

  export namespace Bureau {
    export const contactInfo = (bureau: Bureau) => {
      switch (bureau) {
        case "equifax":
          return {
            url:
              "https://www.equifax.com/personal/education/identity-theft/articles/-/learn/fraud-alert-security-freeze-credit-lock/",
            phoneNumber: "18883784329",
          };
        default:
          return {};
      }
    };
  }
}

export type CreditAlert = web.public_.ICreditMonitor;
export namespace CreditAlert {
  const { AlertType, AlertChangeType } = web.public_.CreditMonitor;

  const contentByTypeByChangeType: Partial<
    Record<
      web.public_.CreditMonitor.AlertChangeType,
      Partial<
        Record<
          web.public_.CreditMonitor.AlertType,
          React.FunctionComponent<{ creditAlert: CreditAlert }>
        >
      >
    >
  > = {
    [AlertChangeType.NEW]: {
      [AlertType.TRADE_LINE_ALERT]: NewTradeline,
      [AlertType.INQUIRY_ALERT]: NewInquiry,
      [AlertType.COLLECTION_ALERT]: NewCollection,
    },
  };

  export const content = ({ alertType, alertChangeType }: CreditAlert) =>
    contentByTypeByChangeType[alertChangeType]?.[alertType];

  export const isSupported = (creditAlert: CreditAlert) =>
    !!CreditAlert.content(creditAlert);

  const stringByType = {
    [AlertType.TRADE_LINE_ALERT]: "tradeline",
    [AlertType.INQUIRY_ALERT]: "inquiry",
    [AlertType.COLLECTION_ALERT]: "collection",
  };

  export const typeToString = ({ alertType }: CreditAlert) =>
    stringByType[alertType] || AlertType[alertType];

  export const description = (creditAlert: CreditAlert) => {
    switch (creditAlert.alertChangeType) {
      case AlertChangeType.NEW:
        return `There's a new ${CreditAlert.typeToString(
          creditAlert
        )} on your recent ${capitalize(CreditAlerts.bureau)} credit report.`;
      default:
        return "";
    }
  };

  export type ById = Record<CreditAlert.Id, CreditAlert>;
  export namespace ById {
    export const fromList = (creditAlerts: CreditAlert[]) =>
      Table.createIndex(creditAlerts, ["id"]);
  }

  export type Id = string & {};
  export namespace Id {
    export type ByChangeType = Record<
      web.public_.CreditMonitor.AlertChangeType,
      CreditAlert.Id
    >;
    export namespace ByChangeType {
      export type ByType = Record<
        web.public_.CreditMonitor.AlertType,
        ByChangeType
      >;
      export namespace ByType {
        export const fromList = (creditAlerts: CreditAlert[]) =>
          Table.createIndex(
            creditAlerts,
            ["alertType", "alertChangeType"],
            "id"
          );
      }
    }
  }
}
