/* eslint-disable no-console */

/**
 * @file Contains miscellaneous utility functions
 */

// @ts-ignore
export const isClient = typeof window !== "undefined";

/**
 * Checks for strict equality between value (x) and all checks given
 *
 * @function
 * @param {*} x Value to be checked
 * @param {Array} checks Valid values
 * @return {boolean} Whether the value was strictly equal to any of the checks
 */
export const match = (x: any, checks: any[]) => {
  for (const check of checks) {
    if (x === check) return true;
  }
  return false;
};

export const assert = (condition, message = "Assertion failed") => {
  if (!condition)
    throw typeof Error === "undefined" ? message : new Error(message);
};

export const promiseDelay = (delay: number = 0, value?: any): Promise<any> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(value), delay);
  });

export const pollFunction = async <T>(
  check: () => T,
  interval = 100,
  timeout = 5000
) => {
  const t = setTimeout(() => {
    throw new Error(`Timed out after ${timeout}ms while polling function`);
  }, timeout);
  let value = check();
  while (!value) {
    // eslint-disable-next-line no-await-in-loop
    await promiseDelay(interval);
    value = check();
  }
  clearTimeout(t);
  return value;
};

// eslint-disable-next-line no-sequences
export const rlog = (...args) => (console.log("DEBUG", ...args), args.pop());

rlog.trace = (...args) => {
  console.groupCollapsed(...args);
  console.trace();
  console.groupEnd();
  return args.pop();
};

rlog.if = (condition, ...args) => {
  if (condition) console.log("DEBUG", ...args);
  return args.pop();
};
