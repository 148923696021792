import Router from "next/router";
import Cookie from "js-cookie";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import { negotiateLanguages } from "@fluent/langneg";
import { ReactLocalization } from "@fluent/react";

import { webRPC } from "@kikoff/proto/src/rpc";
import { isClient } from "@kikoff/utils/src/general";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { availableLocales, defaultLocale, Locale } from "@constant/locales";
import { logResponse } from "@util/analytics";

function detectLocale() {
  if (!isClient) {
    return defaultLocale;
  }

  const localeQueryParam = new URLSearchParams(window.location.search).get(
    "locale"
  );
  if (localeQueryParam) {
    return localeQueryParam;
  }

  const localeCookie = getLocaleCookie();
  if (localeCookie) {
    return localeCookie;
  }

  return navigator.language;
}

export function persistLocaleOnRouteUpdate() {
  // locale must be persisted in the url for Facebook to properly capture it.
  if (!isClient) {
    return;
  }

  const url = new URL(window.location.href);
  const foundLocaleQueryParam = url.searchParams.get("locale") as Locale;

  // url param takes priority, set cookie if found
  if (availableLocales.includes(foundLocaleQueryParam)) {
    Cookie.set("locale", foundLocaleQueryParam);
    document.documentElement.setAttribute("lang", foundLocaleQueryParam);
    return;
  }

  // next if no query param, but a cookie found, persist that in url if that
  // is not the defaultLocale.
  const foundLocaleCookie = Cookie.get("locale") as Locale;
  if (
    availableLocales.includes(foundLocaleCookie) &&
    foundLocaleCookie !== defaultLocale
  ) {
    url.searchParams.set("locale", foundLocaleCookie);
    Router.push(url);
    document.documentElement.setAttribute("lang", foundLocaleCookie);
  } else {
    document.documentElement.setAttribute("lang", defaultLocale);
  }
}

function* generateBundles(resources: Object) {
  const currentLocales = negotiateLanguages(
    [detectLocale()],
    availableLocales,
    {
      defaultLocale,
    }
  );

  for (const locale of currentLocales) {
    const bundle = new FluentBundle(locale);
    bundle.addResource(resources[locale]);
    yield bundle;
  }
}

export function getLocaleCookie() {
  return Cookie.get("locale") || defaultLocale;
}

// All states except AZ, CA, IL, OR, TX. Pending compliance research.
// See https://docs.google.com/document/d/16Qk2x5lpnCRxy4q5e5GCQRZHfH4mPrZcSgl6u6oV6dc/edit.
export function restrictedLocaleUserAddress(state?: string) {
  return ["AZ", "CA", "IL", "OR", "TX"].includes(state);
}

// By default, fluent-react uses a <template> element to parse and sanitize
// markup in translations. In some scenarios like server-side rendering (SSR) or
// apps written in React Native, <template> is not available natively. In these
// situations a custom parseMarkup can be passed as a prop to
// <LocalizationProvider>. It will be used by all <Localized> components under it.
//
// https://github.com/projectfluent/fluent.js/wiki/React-Overlays
function parseMarkupSSR(str: string) {
  const parser = new DOMParser();
  return Array.from(parser.parseFromString(str, "text/html").childNodes);
}

/**
 * @deprecated Prefer l10nResources
 */
export function newReactLocalization(resources) {
  return new ReactLocalization(generateBundles(resources), parseMarkupSSR);
}

export function l10nResources(resources: Record<Locale, string>) {
  const loadedResources = Object.keys(resources).reduce((memo, locale) => {
    memo[locale] = new FluentResource(resources[locale]);
    return memo;
  }, {});

  return new ReactLocalization(
    generateBundles(loadedResources),
    parseMarkupSSR
  );
}

export function updateUserAccountLocale(locale: string) {
  return webRPC.Account.updateLocale({ locale })
    .then(
      handleProtoStatus({
        SUCCESS() {
          logResponse("Update user account locale", "SUCCESS");
        },
        _DEFAULT: handleFailedStatus("Failed to update locale."),
      })
    )
    .catch((e) => e);
}
