import Router from "next/router";

import { isClient } from "@kikoff/utils/src/general";

import { track } from "./analytics";
import { Events } from "./events";

declare global {
  interface Window {
    NativeDispatch?: {
      postMessage(body: string): void;
    };
    NativePageIsRoot?: boolean;
    NativeAppFlavor?: string;
  }
}

/**
 * Prefer isWebview page state if possible with:
 * const { isWebview } = useSelector((state) => state.page);
 *
 * If isWebview affects the initial render, using this constant will make SSRed
 * html and client hydration html differ
 */
export const isWebview = isClient && !!window.NativeDispatch;
export const isRootWebview =
  // Legacy bug set window.NativePageIsRoot to null if isRoot is true, need to
  // check for null until fix reaches saturation, set to
  // `window.NativePageIsRoot === true` after 10/25/2023
  isClient && [true, null].includes(window.NativePageIsRoot);
export const mobileFlavor =
  isClient && !!window.NativeAppFlavor ? window.NativeAppFlavor : "enterprise";

/**
 * Prefer appName page state if possible with:
 *
 * import { selectAppName } from "@feature/page";
 * ...
 * const appName = useSelector(selectAppName())
 */
export const appFlavorNames = {
  enterprise: "Kikoff",
  yukikaze: "Kikoff",
  theseus: "Oasis",
};
export const appName = appFlavorNames[mobileFlavor] || "Kikoff";

// We need this because unions with null remove null when strict null checking
// is disabled
declare const nullSymbol: unique symbol;

type NativeEvents = {
  close:
    | {
        continueWithUrl?: string;
      }
    | typeof nullSymbol;
  openPdf: string;
  requestReview: null;
  trackEvent: {
    name: string;
    properties: Record<string, string>;
    userProperties: Record<string, string>;
  };
  logout: null;
  openWebUrl: {
    url: string;
    appBarTitle?: string;
    appBarHidden?: boolean;
  };
  openExternalUrl: string;
  updateBackgroundColor: string;
  invalidate: null;
  notificationPermission: {
    type: string;
  };
};

let closeCalled = false;

type UnionCondition<Subject, Target, True, False> = Subject extends Target
  ? True
  : Target extends Extract<Subject, Target>
  ? True | False
  : False;

export function nativeDispatch<EventName extends keyof NativeEvents>(
  event: EventName,
  ...[payload]: UnionCondition<
    NativeEvents[EventName],
    null | typeof nullSymbol,
    [undefined?],
    [payload: Exclude<NativeEvents[EventName], typeof nullSymbol>]
  >
) {
  if (!isWebview) return false;

  if (event === "close") {
    if (isRootWebview) {
      Router.back();
      return;
    }
    if (closeCalled) return;
    closeCalled = true;
  }

  if (event === "openWebUrl") {
    const urlPayload = payload as NativeEvents["openWebUrl"];

    if (urlPayload.url.startsWith("/")) {
      urlPayload.url = `${window.location.origin}${urlPayload.url}`;
    }
  }

  window.NativeDispatch?.postMessage(
    JSON.stringify({ eventName: event, payload })
  );
  if (event !== "trackEvent")
    track(`native dispatch: ${event}` as keyof Events, {
      payload: JSON.stringify(payload),
    });

  return true;
}

export const nativeDispatchEvent = <
  EventName extends keyof NativeEvents,
  DOMEvent extends React.SyntheticEvent<any, Event>
>(
  event: EventName,
  {
    payload,
    defaultHandler,
    dispatchHandler,
  }: {
    payload?: NativeEvents[EventName];
    defaultHandler?: React.EventHandler<DOMEvent>;
    dispatchHandler?: React.EventHandler<DOMEvent>;
  } = {}
) => (e: DOMEvent) => {
  if (isWebview) {
    e.preventDefault();

    window.NativeDispatch.postMessage(
      JSON.stringify({ eventName: event, payload })
    );

    dispatchHandler?.(e);
  }
  defaultHandler?.(e);
};

export const openWebUrlOrPush = (url) => {
  if (
    !nativeDispatch("openWebUrl", {
      url,
      appBarHidden: true,
    })
  ) {
    Router.push(url);
  }
};

type Page = {
  scOnboarding: null;
};

export function behaviorUrl<PageName extends keyof Page>(
  page: PageName,
  ...[payload]: UnionCondition<
    Page[PageName],
    null | typeof nullSymbol,
    [undefined?],
    [payload: Exclude<Page[PageName], typeof nullSymbol>]
  >
) {
  switch (page) {
    case "scOnboarding":
      return "kikoff://pages/command?data=IiI%3D&name=secured_card%2Foffer_route_to_persona";
    default:
      return "";
  }
}

export const KIKOFF_APP_LINKS = {
  ios: "https://apps.apple.com/us/app/kikoff-build-credit-quickly/id1525159784",
  android:
    "https://play.google.com/store/apps/details?id=com.kikoff&hl=en_US&gl=US&pli=1",
};
