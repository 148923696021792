import { addMonths } from "date-fns";

import { web } from "../../../protos";
import createTimestamp from "../google/Timestamp";

export default function createPocketTransaction(
  token: string,
  status: keyof typeof web.public_.PocketTransaction.Status,
  {
    amountCents = 1_00,
    description = "Pocket transaction",
    transactedAt = createTimestamp(new Date()),
  }: Omit<web.public_.IPocketTransaction, "token" | "status"> = {}
) {
  return web.public_.PocketTransaction.create({
    token,
    status: web.public_.PocketTransaction.Status[status],
    amountCents,
    description,
    transactedAt,
  });
}

export const pocketTransactionsByToken: Record<
  string,
  web.public_.IPocketTransaction[]
> = {};

export const createPocketTransactions = {
  forPocket: Object.assign((token: string) => {}, {
    fromCache: (token: string) => pocketTransactionsByToken[token],
    withStatuses: (
      token: string,
      statuses: (keyof typeof web.public_.PocketTransaction.Status)[],
      fieldsBy?: (
        status: typeof statuses[number],
        i: number
      ) => Parameters<typeof createPocketTransaction>[2]
    ) =>
      statuses.map((status, i) =>
        createPocketTransaction(`${token}:transaction-${i}`, status, {
          transactedAt: createTimestamp(
            addMonths(new Date(), i - statuses.length)
          ),
          ...fieldsBy?.(status, i),
        })
      ),
  }),
};
