import { commonRoutes } from "./common";
import createExperiment from "./createExperiment";

// FIXME: this experiment is a placeholder to apply the common route config.
export default createExperiment({
  variants: {
    only: {
      routes: commonRoutes,
    },
  },
}).weigh({
  only: 100,
});
