import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import { Path } from "@kcirtaptrick/path-parser";

import { isAndroid } from "@kikoff/client-utils/src/dom";
import useError from "@kikoff/hooks/src/useError";
import useInitial from "@kikoff/hooks/src/useInitial";
import { web } from "@kikoff/proto/src/protos";
import { isClient } from "@kikoff/utils/src/general";

import { initPage, selectMobileFlavor } from "@feature/page";
import { userStatusRedirect } from "@feature/user";
import { useIntroPromoCleanup } from "@src/hooks/promos";
import routes from "@src/routes";
import { isRootWebview, isWebview } from "@util/mobile";

import AuthenticationLoader from "./authentication_loader";

function CheckRouteAuth({ Component, pageProps, router }) {
  useIntroPromoCleanup();

  const dispatch = useDispatch();
  const initial = useInitial();
  const error = useError();

  const getLayout = Component.getLayout || ((page) => page);

  const user = useSelector((state) => state.user);
  const [isAuthenticated, setIsAuthenticated] = useState(user.authenticated);
  const featureFlags = useSelector((state) => state.page.featureFlags);

  const authRoute: {
    authRedirect?: boolean;
    noauth?: string;
  } = useMemo(
    () =>
      Object.entries(routes).find(([route]) => {
        const { pathname } = isClient && !initial ? window.location : router;

        return (
          route === pathname ||
          (route.endsWith("*") && pathname.startsWith(route.slice(0, -1)))
        );
      })?.[1] || {},
    [router, isClient, initial]
  );

  const mobileFlavor = useSelector(selectMobileFlavor());
  const redirect = authRoute.authRedirect || authRoute.noauth;
  const auth = (authRoute.noauth && true) || (authRoute.authRedirect && false);
  const routeRequiresAuthentication = typeof auth === "boolean";
  const displayPage =
    (!!isAuthenticated && user.authenticated) === auth &&
    (user.proto?.status !== web.public_.User.Status.ONBOARDING ||
      router.pathname.startsWith("/onboarding"));
  const authenticationPending =
    router.pathname === "/login"
      ? false
      : isAuthenticated !== auth || !displayPage;

  useEffect(() => {
    Object.assign(window, { Path });

    if (user.authenticated === true && router.asPath.split("?")[0] === "/") {
      dispatch(userStatusRedirect());
    }

    if (user.authenticated === null) {
      dispatch(
        initPage({
          isWebview,
          isRootWebview,
          mobileFlavor,
          emphasizeLending: isWebview && isAndroid,
        })
      ).catch((e) => {
        error.throw(e.message);
      });
    }
  }, [user.authenticated, user.mfaRequired]);

  useEffect(() => {
    if (!routeRequiresAuthentication) {
      return;
    }

    (async () => {
      if (user.authenticated) {
        if (auth === false && typeof redirect === "string") {
          Router.replace(redirect);
        } else {
          // Wait for page init (onboarding)
          await dispatch(userStatusRedirect());
        }
      } else if (user.authenticated === false && auth === true) {
        Router.replace(
          `${redirect}?origin=${encodeURIComponent(Router.asPath)}`
        );
      }
    })().then(() => setIsAuthenticated(user.authenticated));
  }, [
    user.proto?.token,
    user.proto?.status,
    user.authenticated,
    user.mfaRequired,
    routeRequiresAuthentication,
  ]);

  if (routeRequiresAuthentication) {
    if (authenticationPending) {
      return <AuthenticationLoader />;
    }

    return getLayout(<Component {...pageProps} />, { featureFlags });
  }

  return getLayout(<Component {...pageProps} />, {});
}

export default CheckRouteAuth;
