import { useEffect, useRef } from "react";

import useUpdate from "./useUpdate";

interface UseInitialOptions {
  rerender?: boolean;
}

export default function useInitial({ rerender }: UseInitialOptions = {}) {
  const isInitial = useRef(true);
  const update = useUpdate();

  useEffect(() => {
    isInitial.current = false;
    if (rerender) update();
  }, []);

  return isInitial.current;
}
