import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@store";

const initialState = {
  activeViewProto: {
    type: "" as "dashboard" | "page",
    name: "" as string,
  },
};

export type ViewProtosState = typeof initialState;

const viewProtosSlice = createSlice({
  name: "viewProtos",
  initialState,
  reducers: {
    setActiveViewProto(
      state,
      { payload }: PayloadAction<ViewProtosState["activeViewProto"]>
    ) {
      state.activeViewProto = payload;
    },
  },
});

export const { setActiveViewProto } = viewProtosSlice.actions;
export default viewProtosSlice.reducer;

export const selectActiveViewProto = () => (state: RootState) =>
  state.viewProtos.activeViewProto;
