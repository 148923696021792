import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./GenericSpinner.module.scss";

interface GenericSpinnerProps {
  className?: string;
  color?: React.CSSProperties["color"];
  size?: React.CSSProperties["width"];
  center?: boolean;
}

export default function GenericSpinner({
  className,
  color = "currentcolor",
  size,
  center,
}: GenericSpinnerProps) {
  return (
    <span
      className={combineClasses(
        styles["generic-spinner"],
        center && styles.center,
        className
      )}
      style={{ fontSize: size }}
    >
      <svg width="1em" height="1em">
        <circle
          cx="0.5em"
          cy="0.5em"
          r="0.4em"
          fill="none"
          strokeWidth="0.1em"
          style={{ stroke: color }}
        />
      </svg>
    </span>
  );
}
