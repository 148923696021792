// @ts-nocheck
/* eslint-disable */
import { isClient } from "@app/../../packages/utils/src/general";

if(isClient && process.env.REALLY_PRODUCTION) {
  !function (t, f, a, x) { 
    if (!document.getElementById(x)) { 
      t.async = 1;
      t.src = a;
      t.id=x;
      f.parentNode.insertBefore(t, f);
    } 
  }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/unip/1552861/tfa.js', 'tb_tfa_script');

  window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: 1552861});
}
