export const raceToSuccess = <T>(promises: Promise<T>[]): Promise<T> =>
  Promise.all(
    promises.map((p) =>
      p.then(
        (val) => {
          throw val;
        },
        (err) => err
      )
    )
  ).then(
    (errors) => {
      throw errors;
    },
    (val) => val
  );
