// Export env vars from this file instead of using them directly, some bundlers
// discourage putting them under process.env, some force it. This file allows us
// to cater to both.

export const API_ORIGIN = process.env.API_ORIGIN;

export const KIKOFF_FRONTEND_ORIGIN = process.env.KIKOFF_FRONTEND_ORIGIN;

export const KIKOFF_GOOGLE_AUTH_CLIENT_ID =
  process.env.KIKOFF_GOOGLE_AUTH_CLIENT_ID;
