import Markdown from "markdown-to-jsx";

import styles from "./Markdown.module.scss";

type KMarkdownProps = React.ComponentProps<typeof Markdown>;

export default function KMarkdown({ children, ...props }: KMarkdownProps) {
  return (
    <Markdown {...props} className={styles["markdown"]}>
      {children}
    </Markdown>
  );
}
