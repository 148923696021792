import React, { useEffect } from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";

import { DEBUG } from "@kikoff/client-utils/src/general";
import { createPropsProvider } from "@kikoff/client-utils/src/react";
import { isClient } from "@kikoff/utils/src/general";
import { combineClasses } from "@kikoff/utils/src/string";

import Identity from "../containers/Identity";

import styles from "./KLink.module.scss";

declare namespace KLink {
  export type Type = "default" | "pdf";
  export type MobileBehavior =
    | "pushWebview"
    // With title
    | `pushWebview:${string}`
    | "openExternalBrowser"
    | ((href: string) => void);
  export type Props<T extends Type> = Omit<
    React.HTMLProps<HTMLAnchorElement>,
    "type"
  > & {
    // TODO: add visual variants and replace all links (Next links + a tags) with this component
    variant?:
      | "native"
      | "unstyled"
      | "container"
      | "primary"
      | "primary-underlined"
      | "underlined";
    newLine?: boolean;
    fit?: boolean;
    type?: T;
    href: string;
    newTab?: boolean;
  } & (T extends "pdf"
      ? {}
      : Omit<LinkProps, "as" | "passHref"> & {
          mobileBehavior?: MobileBehavior;
        });

  export namespace If {
    export type Props<T extends KLink.Type> = {
      when: boolean;
    } & KLink.Props<T>;
  }
}
export default KLink;
function KLink<T extends KLink.Type>(_props: KLink.Props<T>) {
  const {
    variant = "native",
    type,
    // Prevent page crash with undefined url, this is still required
    href: _href = "",
    children,
    className,
    newLine,
    fit,
    prefetch,
    replace,
    scroll,
    shallow,
    locale,
    schemes = {},
    newTab,
    mobileBehavior,
    onClick,
    onMobileAction,
    ...props
  } = KLink.PropsProvider.useMerge(_props);

  let href = _href;
  href = _href;

  // Handle relative routes properly, nextjs has unexpected behavior in nested
  // dynamic routes: . will reference the root of the dynamic route no matter
  // how nested the route is
  const { asPath } = useRouter();
  const getParent = (s: string) => s.slice(0, s.lastIndexOf("/"));
  for (const [prefix, replacement] of [
    ["..", getParent(getParent(asPath))],
    [".", getParent(asPath)],
  ]) {
    if (href.startsWith(prefix)) {
      const url = new URL(
        `${isClient ? window.origin : "https://kikoff.com"}${asPath}`
      );

      const iOfQuery = href.indexOf("?");
      if (iOfQuery !== -1) {
        for (const entry of new URLSearchParams(
          href.slice(iOfQuery)
        ).entries()) {
          url.searchParams.append(...entry);
        }
        href = href.slice(0, iOfQuery);
      }

      url.pathname = `${replacement}${href.slice(prefix.length)}`;
      href = url.href;
    }
  }

  const cls = combineClasses(
    styles[variant],
    newLine && styles["new-line"],
    fit && styles["fit"],
    className
  );

  if (DEBUG)
    useEffect(() => {
      if (!type && href.slice(-3) === "pdf")
        console.warn(
          `KLink with .pdf file extension in href does not have type set "${href}", will default "default". Set the type property to "pdf" or explicitly set it to "default" if this is intentional to prevent this warning`
        );
    }, []);

  if (type === "pdf")
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          onClick?.(e);
          if (onMobileAction?.(href, "openPdf")) e.preventDefault();
        }}
        className={cls}
        {...props}
      >
        {children}
      </a>
    );

  for (const [scheme, handler] of Object.entries(schemes)) {
    if (href.startsWith(scheme))
      return (
        <a
          {...props}
          href={href}
          onClick={(e) => {
            e.preventDefault();

            onClick?.(e);

            handler(href);
          }}
          className={cls}
        >
          {children}
        </a>
      );
  }

  // type === "default"
  return (
    <Link
      href={href}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
    >
      <a
        {...(newTab && { target: "_blank", rel: "noreferrer" })}
        {...props}
        className={cls}
        onClick={(e) => {
          if (mobileBehavior)
            if (onMobileAction?.(href, mobileBehavior)) e.preventDefault();
          onClick?.(e);
        }}
      >
        {children}
      </a>
    </Link>
  );
}

type AllKLinkProps = Omit<
  UnionToIntersection<
    {
      [T in KLink.Type]: KLink.Props<T>;
    }[KLink.Type]
  >,
  "type"
> & { type?: KLink.Type };

KLink.PropsProvider = createPropsProvider<
  AllKLinkProps & {
    schemes?: Record<string, (url: string) => void>;
    onMobileAction?(
      href: string,
      // openPdf is triggered by type="pdf" instead of setting mobileBehavior
      behavior: KLink.MobileBehavior | "openPdf"
    ): boolean;
  }
>("KLink");

KLink.If = <T extends KLink.Type>({ when, ...rest }: KLink.If.Props<T>) => {
  const Component = when ? KLink : (Identity as never);

  return <Component {...rest} />;
};
