import { Integrations } from "@sentry/browser";
import * as Sentry from "@sentry/nextjs";

import { isClient } from "@kikoff/utils/src/general";

import config from "./sentry.common.config";

// https://github.com/getsentry/sentry-javascript/issues/3388
const isAffectByIssue3388 =
  isClient && navigator.userAgent.includes("Chrome/74.0.3729");

Sentry.init({
  ...config,
  integrations: [
    new Integrations.TryCatch({
      requestAnimationFrame: !isAffectByIssue3388,
    }),
  ],
});
