import { useEffect, useState } from "react";
import axios from "axios";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Alert from "@kikoff/components/src/v1/info/Alert";

import styles from "./status_alert.module.scss";

interface StatusAlertProps {}
export default function StatusAlert({}: StatusAlertProps) {
  const [statusData, setStatusData] = useState(null);
  useEffect(() => {
    axios
      .get("https://0dphthl908bv.statuspage.io/api/v2/summary.json")
      .then((res) => {
        setStatusData([
          ...res.data.incidents.map((incident) => ({
            ...incident,
            type: "incident",
          })),
          ...res.data.scheduled_maintenances.map((scheduledMaintenance) => ({
            ...scheduledMaintenance,
            type: "maintenance",
          })),
        ]);
      });
  }, []);
  if (!statusData) return null;
  return (
    <div className={styles["status-alerts"]}>
      {statusData?.map((status, index) => (
        <Alert
          key={index}
          type={{ incident: "error", maintenance: "warning" }[status.type]}
          title={status.name}
          className="my-2"
        >
          {status.incident_updates[0].body}
          <div>
            <KButton
              className="mt-2"
              variant="black"
              size="small"
              onClick={() => {
                setStatusData(
                  statusData.filter((element) => element.id !== status.id)
                );
              }}
            >
              Dismiss
            </KButton>
          </div>
        </Alert>
      ))}
    </div>
  );
}
