import { useState } from "react";
import { useSelector } from "react-redux";
import { NextRouter, useRouter } from "next/router";
import { AnimatePresence, motion } from "framer-motion";

import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import KButton from "@kikoff/components/src/v1/buttons/KButton";
import { cls } from "@kikoff/utils/src/string";

import { selectFeatureFlag } from "@feature/page";
import { useOverlaysController } from "@src/overlay";
import { track } from "@util/analytics";

import questionBubbleImg from "./images/question_bubble.svg";

import styles from "./help_chat.module.scss";

function showHelpButtonOnThisPage(router: NextRouter): boolean {
  // ** add checks here to disable the chat button on a certain page **
  if (router.route.startsWith("/dashboard")) {
    return true;
  }
  return false;
}

export default function HelpChat() {
  const overlays = useOverlaysController();
  const router = useRouter();
  const helpButtonEnabled = useSelector(
    selectFeatureFlag("global_help_chat_button")
  );
  const isWebview = useSelector((state) => state.page.isWebview);

  const [showBubble, setShowBubble] = useState(true);

  if (!showHelpButtonOnThisPage(router) || !helpButtonEnabled || isWebview) {
    return null;
  }

  function buttonClicked() {
    track.tap("chatbot: help chat button", { url: router.route });
    setShowBubble(false);
    overlays.push("chat", {
      context: "SUPPORT",
    });
  }

  return (
    <div className={styles["help-button-wrapper"]}>
      <AnimatePresence>
        {showBubble && (
          <motion.div
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
            exit={{ opacity: 0, transform: "translateY(-24px)" }}
            className={styles["help-button-bubble"]}
          >
            Need help?
            <ContainerButton
              onClick={() => setShowBubble(false)}
              className="pt-0.25"
            >
              
            </ContainerButton>
          </motion.div>
        )}
      </AnimatePresence>
      <KButton
        variant="circle"
        className={cls(styles["help-button"], "bg:green-50 center-child")}
        size="small"
        onClick={buttonClicked}
      >
        <img src={questionBubbleImg.src} alt="Chat" />
      </KButton>
    </div>
  );
}
