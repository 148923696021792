import { useCallback, useState } from "react";

export default function useUpdate() {
  const [updateRef, setUpdate] = useState(() => Symbol("update"));

  return useCallback(() => {
    setUpdate(Symbol("update"));
  }, [updateRef]);
}

// Since async updates are not batched, this can be used to only trigger a
// single update per render. This will not work for multiple async calls
useUpdate.single = () => {
  const update = useUpdate();
  let scheduled = false;

  return () => {
    if (scheduled) return;
    scheduled = true;
    update();
  };
};
