import { useEffect } from "react";

import useInitial from "@kikoff/hooks/src/useInitial";
import { combineClasses } from "@kikoff/utils/src/string";

import { useOverlayLayout } from "@src/overlay";

import DarkenBackdrop from "../backdrop/darken";

import styles from "./modal.module.scss";

interface ModalLayoutProps {
  className?: string;
  onClose?(): void;
  disableAlignment?: boolean;
  children: React.ReactNode;
}

export default function ModalLayout({
  className,
  onClose,
  disableAlignment,
  children,
}: ModalLayoutProps) {
  const layout = useOverlayLayout();
  const initial = useInitial({ rerender: true });

  const prev = layout.getRelative(-1);

  const left = layout.index < layout.current.index;
  const right = layout.index > layout.current.index;
  const enterRight = layout.isCurrent && prev && initial && layout.isPresent;
  const cleared = layout.current.index === -1;

  useEffect(() => {
    if (!layout.isPresent)
      setTimeout(() => {
        layout.safeToRemove();
      }, 400);
  }, [layout.isPresent]);

  return (
    <div
      className={combineClasses(
        styles.modal,
        left && styles.left,
        (enterRight || right) && !cleared && styles.right,
        layout.isPresent && styles.show,
        className
      )}
    >
      <div
        className={combineClasses(styles["modal-body"])}
        onClick={(e) => e.target === e.currentTarget && onClose()}
        style={{
          ...(disableAlignment && { display: "block", height: "100%" }),
        }}
      >
        {children}
      </div>
    </div>
  );
}

ModalLayout.Backdrop = DarkenBackdrop;
