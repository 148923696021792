import Card from "@kikoff/components/src/v1/cards/Card";

import { CreditAlert, CreditAlerts } from "@feature/credit_alerts";

import NewContent from "./content";
import NewHeader from "./header";

interface NewInquiryProps {
  creditAlert: CreditAlert;
}

export default function NewInquiry({ creditAlert }: NewInquiryProps) {
  const details = creditAlert.inquiryAlert;

  return (
    <>
      <NewHeader
        bureau={CreditAlerts.bureau}
        type={CreditAlert.typeToString(creditAlert)}
      />
      <Card className="text:regular+ mt-3 mb-6" raised>
        {details.creditorName}
      </Card>
      <NewContent creditAlert={creditAlert} />
    </>
  );
}
