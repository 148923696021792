import overlayApi, { createOverlayBuilder } from "@kikoff/overlays/src/api";

import ModalLayout from "@component/overlay/layout/modal";
import { RootState } from "@store";
import { track } from "@util/analytics";

import overlayMap from "./overlay_map";

export type OverlayContexts = {
  push: { withPath?: string };
  render: RootState;
};

export const {
  OverlayProvider,
  useOverlay,
  useOverlayLayout,
  useOverlaysController,
  useOverlaysBase,
  useOverlayBackdrop,
  useOverlayExternal,
  OverlaysContext,
} = overlayApi(overlayMap, {
  DefaultLayout: ModalLayout,
}).create<OverlayContexts>({
  hooks: {
    push(key, props, context) {
      track("overlay: push", { key, props, context });
    },
  },
  defaultConfig: {
    validateRenderContext: (state) => !!state.user.proto,
  },
});

export type OverlaysController = ReturnType<typeof useOverlaysController>;

export const buildOverlay = createOverlayBuilder<RootState>();

export type Overlays = typeof overlayMap;

export function decodeOverlayStack(encodedOverlays: string) {
  if (!encodedOverlays) return [];
  try {
    return (
      JSON.parse(
        Buffer.from(decodeURIComponent(encodedOverlays), "base64").toString(
          "ascii"
        )
      ) || []
    );
  } catch {
    return [];
  }
}
