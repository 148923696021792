import React, { useEffect, useState } from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./svg_animations.module.scss";

interface PathAnimationProps {
  className?: string;
  show?: boolean;
  duration?: number;
  size?: string | number;
  color?: string;
  width?: string | number;
  delay?: number;
  delayAfter?: number;
}

export function CheckPathAnimation({
  className,
  duration = 0.3,
  size = "1em",
  color = "white",
  width = "4px",
  show: _show = true,
  delay,
  delayAfter = delay,
}: PathAnimationProps) {
  const [path, setPath] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(
      () => setPath(_show),
      _show ? delay : delayAfter
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [_show]);

  return (
    <svg
      className={combineClasses(styles["check-path-animation"], className)}
      viewBox="5 5 40 40"
      width={size}
      height={size}
    >
      {path && (
        <path
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
          style={{
            animationDuration: `${duration}s`,
            stroke: color,
            strokeWidth: width,
          }}
        />
      )}
    </svg>
  );
}

export const CrossPathAnimation: React.FC<PathAnimationProps> = ({
  className,
  duration = 0.3,
  size = "1em",
  color = "white",
  width = "4px",
  show: _show = true,
  delay,
  delayAfter = delay,
}) => {
  const [path, setPath] = useState<boolean>(false);
  useEffect(() => {
    const timeout = setTimeout(
      () => setPath(_show),
      _show ? delay : delayAfter
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [_show]);
  useEffect(() => {});
  return (
    <svg
      className={combineClasses(styles["cross-path-animation"], className)}
      viewBox="5 5 40 40"
      width={size}
      height={size}
    >
      <polyline
        className="cross1 path"
        points="35,10 10,35"
        style={{
          transitionDuration: `${duration / 2}s`,
          stroke: color,
          strokeWidth: width,
          strokeDashoffset: path && 0,
        }}
      />
      <polyline
        className="cross2 path"
        points="10,10 35,35"
        style={{
          transitionDuration: `${duration / 2}s`,
          transitionDelay: `${duration / 2}s`,
          stroke: color,
          strokeWidth: width,
          strokeDashoffset: path && 0,
        }}
      />
    </svg>
  );
};
