import { motion } from "framer-motion";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./Fade.module.scss";

interface FadeProps extends React.ComponentProps<typeof motion.div> {
  height?: boolean;
}

export default function Fade({
  initial,
  animate,
  exit,
  className,
  children,
  height,
  ...props
}: FadeProps) {
  return (
    <motion.div
      initial={{
        opacity: 0,
        ...(height && { opacity: -0.5, height: 0 }),
        ...(initial as {}),
      }}
      animate={{
        opacity: 1,
        ...(height && { height: "auto" }),
        ...(animate as {}),
      }}
      exit={{
        opacity: 0,
        ...(height && { opacity: -0.5, height: 0 }),
        ...(exit as {}),
      }}
      className={combineClasses(
        styles["fade"],
        height && styles["overflow-hidden"],
        className
      )}
      {...props}
    >
      {children}
    </motion.div>
  );
}
