import { google } from "../../../protos";

export default function createTimestamp(
  input: Date | string | number | google.protobuf.ITimestamp
) {
  const seconds =
    typeof input === "object" && "seconds" in input
      ? input.seconds
      : new Date(input as Date).getTime() / 1000;

  return google.protobuf.Timestamp.create({
    seconds,
  });
}

createTimestamp.now = () => createTimestamp(new Date());
