import Card from "@kikoff/components/src/v1/cards/Card";

import ListTile from "@component/dashboard/credit-score/list_tile";
import { CreditAlert, CreditAlerts } from "@feature/credit_alerts";

import NewContent from "./content";
import NewHeader from "./header";

interface NewCollectionProps {
  creditAlert: CreditAlert;
}

export default function NewCollection({ creditAlert }: NewCollectionProps) {
  const details = creditAlert.collectionAlert;

  return (
    <>
      <NewHeader
        bureau={CreditAlerts.bureau}
        type={CreditAlert.typeToString(creditAlert)}
      />
      <Card className="text:regular+ mt-3 mb-6" raised>
        <ListTile
          title={details.creditorName}
          subtitle="Collection account"
          propsFor={{ subtitle: { className: "color:error" } }}
          separator={false}
          noPadding
        />
      </Card>
      <NewContent creditAlert={creditAlert} />
    </>
  );
}
