/**
 * @file Contains Protobuf related utility functions (and wrappers)
 */

import type { common } from "protobufjs";

import { msIn } from "./date";

export const handleProtoStatus = <T extends { [key: string]: any }>(
  handlers: {
    [key: string]: (data: T) => any;
  },
  Status?
) => (proto: T) => {
  Status ??= proto.$type.Status;
  // Check for unhandled statuses in dev
  if (process.env.NODE_ENV === "development" && !handlers._DEFAULT) {
    const statuses = Object.keys(Status).filter((key) => !(key in handlers));
    if (statuses.length)
      throw new Error(`Unhandled statuses: ${statuses.join(", ")}`);
  }

  const status = Status[proto.status];

  return (
    handlers[status] ||
    handlers._DEFAULT ||
    (() => {
      throw new Error(`Unhandled status ${status}`);
    })
  )(proto);
};

export const catchRequest = (
  statusHandlers?,
  { callback = (err: Error) => {}, useDefaults = true } = {}
) => (err) => {
  const { response: { status } = {} }: { response?: { status? } } = err;
  const defaults = {};
  const handlers = {
    ...(useDefaults && defaults),
    ...statusHandlers,
  }[status];
  handlers?.({ err, defaults });
  callback(err);
};

export const basicAddressToProto = (proto, basicAddress) =>
  proto
    .setAddressLine1(basicAddress.street)
    .setAddressLine2(basicAddress.number)
    .setCity(basicAddress.city)
    .setState(basicAddress.state)
    .setZip(basicAddress.zip);

export const handleFailedStatus = (
  defaultMessage = "Something went wrong.",
  setError?: (error: Error) => void
) => <T extends { error?: { description?: string | null } | null }>(
  data: T
) => {
  const error = new Error(data.error?.description || defaultMessage);
  if (setError) {
    setError(error);
    return;
  }
  throw error;
};

export const protoTime = (timestamp: common.ITimestamp) => {
  if (!timestamp) return 0;
  const { seconds } = timestamp;
  return (typeof seconds === "number" ? seconds : seconds?.low) * msIn.second;
};

export const protoDate = (timestamp: common.ITimestamp) =>
  new Date(protoTime(timestamp));
