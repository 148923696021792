import Url from "url-parse";

import { views } from "@kikoff/proto/src/protos";

import { handleURL } from "@src/kikoff_url";
import { track } from "@util/analytics";
import { Events } from "@util/events";

import { ViewProtos } from "../types";

export class NavigationHistory {
  static KEY = "proto-navigation-history";

  static ROOT = "kikoff://dashboards/generic?name=secured_card&data=IiI=";

  history: string[];

  constructor() {
    this.history =
      JSON.parse(sessionStorage.getItem(NavigationHistory.KEY)) || [];
  }

  push(url: string) {
    this.history.push(url);
    this.storeHistory();
  }

  clear(url: string) {
    this.history = [NavigationHistory.ROOT, url];
    this.storeHistory();
  }

  replace(url: string) {
    this.history[this.history.length - 1] = url;
    this.storeHistory();
  }

  pop(handleURL = true) {
    // To pop we need to:
    //   1. Pop the current screen from the history
    //   2. Navigate to the next screen from the history
    this.history.pop();
    this.storeHistory();

    if (handleURL) {
      this.gotoLastPage();
    }
  }

  home() {
    this.history = [];
    this.storeHistory();

    handleURL(NavigationHistory.ROOT);
  }

  gotoLastPage() {
    const gotoURL = this.history[this.history.length - 1];

    if (gotoURL) {
      handleURL(gotoURL);
    } else {
      handleURL(NavigationHistory.ROOT);
    }
  }

  storeHistory() {
    sessionStorage.setItem(NavigationHistory.KEY, JSON.stringify(this.history));
  }
}

export default function handleProtoURL(url: string, proto?: ViewProtos) {
  if (!url) {
    return null;
  }

  const { protocol, hostname, pathname, query } = new Url(url, true);
  const navigationHistory = new NavigationHistory();
  const action = pathname.split("/")[1];

  const fullyDecodeURL = (url) => {
    let decodedURL = url;
    let previousDecodedURL;

    do {
      previousDecodedURL = decodedURL;
      decodedURL = decodeURIComponent(decodedURL);
    } while (decodedURL !== previousDecodedURL);

    return decodedURL;
  };

  const syncHistory = (url, query) => {
    switch (query.navigation) {
      case "replace":
        navigationHistory.replace(url);
        break;
      case "clear":
      case "replace_root":
        navigationHistory.clear(url);
        break;
      case "inline":
        break;
      default:
        navigationHistory.push(url);
    }
  };

  // Track tap event
  if (proto && "eventProperty" in proto) {
    if (
      proto.eventProperty?.trackedActions.includes(
        views.base.EventProperty.TrackedAction.TAP
      )
    ) {
      track(
        `${proto.eventProperty.name}: Tap - WEB` as keyof Events,
        proto.eventProperty.additionalProperties
      );
    }
  }

  if (protocol === "kikoff:") {
    // Handle the navigation deeplinks separately from handleURL to keep proper state
    if (hostname === "navigation") {
      if (action === "pop") {
        return navigationHistory.pop();
      }

      if (action === "home") {
        return navigationHistory.home();
      }

      // The `push` action adds an array of URLs onto the stack via the `urls` querystring parameter
      if (action === "push") {
        if (!query["urls"]) {
          return;
        }

        let decodedURL;

        try {
          decodedURL = fullyDecodeURL(query["urls"]);
        } catch (e) {
          return;
        }

        JSON.parse(decodedURL).forEach((url) => {
          const { hostname, pathname, query } = new Url(url, true);

          if (hostname === "tabs") {
            if (pathname.split("/")[1] === "root") {
              syncHistory(NavigationHistory.ROOT, query);
            }
          } else {
            syncHistory(url, query);
          }
        });

        navigationHistory.gotoLastPage();
      }
    } else if (hostname === "tabs") {
      if (action === "root") {
        syncHistory(NavigationHistory.ROOT, query);
        return navigationHistory.pop();
      }
    } else if (hostname === "webview" || hostname === "pdf") {
      window.open(query.url, "_blank");
      // Don't push command pages onto navigation history
      // Don't push atomic onto navigation history
    } else if (action !== "command" && hostname !== "atomic_fi") {
      syncHistory(url, query);
    }
  }

  return handleURL(url);
}
