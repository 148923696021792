import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk, RootState } from "@store";
import analytics, { track } from "@util/analytics";

import { thunk } from "../utils";

import { selectActiveSubscription, updateOrders } from "./shopping";
import { fetchUser } from "./user";

const initialState = {
  selectedPlan: null as web.public_.SubscriptionPlan,
  activeSubscription: null as web.public_.IUserSubscription,
};

export type SubscriptionState = typeof initialState;

const subscriptionSlice = createSlice({
  name: "creditLine",
  initialState,
  reducers: {
    setSelectedPlan(
      state,
      { payload }: PayloadAction<SubscriptionState["selectedPlan"]>
    ) {
      state.selectedPlan = payload;
    },
    setActiveSubscription(
      state,
      { payload }: PayloadAction<SubscriptionState["activeSubscription"]>
    ) {
      state.activeSubscription = payload;
    },
  },
});

export const {
  setActiveSubscription,
  setSelectedPlan,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

export const selectUserSubscription = () => (state: RootState) =>
  state.subscription.activeSubscription;

export const openSubscriptionAndCreditLine = (
  autopayEnabled: boolean,
  subscriptionPlanTitle: web.public_.SubscriptionPlan,
  autopay: web.public_.AutoPay.ISettings,
  productToken: string,
  firstPaymentDate: null | google.protobuf.ITimestamp
): AppThunk<Promise<web.public_.OpenSubscriptionAndCreditLineResponse>> => (
  dispatch
) => {
  return webRPC.Subscription.openSubscriptionAndCreditLine({
    autorenew: autopayEnabled,
    subscriptionPlanTitle,
    autoPay: autopay,
    productToken,
    firstPaymentDate,
  }).then(
    handleProtoStatus({
      async SUCCESS(data) {
        await dispatch(setSelectedPlan(data.userSubscription.plan));
        await dispatch(setActiveSubscription(data.userSubscription));
        await dispatch(fetchUser.creditLine());
        analytics.convert("Credit Account Open");
        track("Subscription refactor - openSubscriptionAndCreditLine success");
      },
      _DEFAULT: handleFailedStatus("Failed to checkout"),
    })
  );
};

export const renewSubscriptionAndCreditLine = (
  subscriptionPlanTitle: web.public_.SubscriptionPlan,
  productToken: string
): AppThunk<Promise<web.public_.RenewSubscriptionAndCreditLineResponse>> => (
  dispatch
) => {
  return webRPC.Subscription.renewSubscriptionAndCreditLine({
    subscriptionPlanTitle,
    productToken,
  }).then(
    handleProtoStatus({
      async SUCCESS(data) {
        await dispatch(setSelectedPlan(data.userSubscription.plan));
        await dispatch(setActiveSubscription(data.userSubscription));
        await dispatch(fetchUser.creditLine());
        analytics.convert("Credit Account Open");
      },
      _DEFAULT: handleFailedStatus("Failed to renew credit line"),
    })
  );
};

export const fetchSubscription = (): AppThunk<
  Promise<web.public_.UserSubscription>
> => async (dispatch) =>
  webRPC.Subscription.getUserSubscription({}).then(
    handleProtoStatus({
      SUCCESS({ userSubscription }) {
        if (userSubscription) {
          dispatch(setActiveSubscription(userSubscription));
        }

        return userSubscription;
      },
      _DEFAULT: handleFailedStatus("Failed to get user subscription info"),
    })
  );

export const updateAutoRenewalStatus = (
  status: keyof typeof web.public_.AutoRenew.Status
) =>
  thunk((dispatch, getState) =>
    webRPC.Subscription.updateAutoRenewalStatus({
      userSubscription: {
        autoRenew: {
          status: web.public_.AutoRenew.Status[status],
        },
        token: selectActiveSubscription()(getState()).token,
      },
    }).then(
      handleProtoStatus({
        SUCCESS() {
          dispatch(updateOrders());
          track("credit line: closed upfront payment account");
        },
        _DEFAULT: handleFailedStatus("Failed to update subscription status"),
      })
    )
  );

export type Plan = "basic" | "premium" | "ultimate";

const { SubscriptionPlan } = web.public_;

export namespace Plan {
  export const toProto = {
    basic: SubscriptionPlan.BASIC,
    premium: SubscriptionPlan.PREMIUM,
    ultimate: SubscriptionPlan.ULTIMATE,
  };
  export const byProto: Partial<Record<web.public_.SubscriptionPlan, Plan>> = {
    [SubscriptionPlan.BASIC]: "basic",
    [SubscriptionPlan.PREMIUM]: "premium",
    [SubscriptionPlan.ULTIMATE]: "ultimate",
  };
  const nameByPlan = {
    basic: "Basic",
    premium: "Premium",
    ultimate: "Ultimate",
  };
  export const name = (plan: Plan | web.public_.SubscriptionPlan) =>
    nameByPlan[byProto[plan] ?? plan];

  export const hierarchy: Record<Plan, number> = {
    basic: 0,
    premium: 1,
    ultimate: 2,
  };
}
