export const commonRoutes = {
  "/": "/_v2",
  "/credit-account": "/_v2/credit-account",
  "/about": "/_v2/about",
  "/faq": "/_v2/faq",
  "/card-faq": "/_v2/card-faq",
  "/pricing": "/_v2/pricing",
  "/team": "/_v2/team",
  "/equifax-updates-faq": "/_v2/equifax-updates-faq",
  "/rent-reporting": "/_v2/rent-reporting",
  "/disputes": "/_v2/disputes",
};
