import React from "react";

import {
  ComponentFromLazy,
  LayoutComponent,
  OverlayComponent,
  OverlaysSchema,
  PropsFromLazy,
} from "../types";

import { PushArgs } from "./createOverlaysContext";

export default function createOverlayComponentContext<
  Overlays extends OverlaysSchema,
  DefaultLayoutType extends LayoutComponent,
  PushContext
>() {
  return React.createContext(
    <ComponentType extends OverlayComponent>(Component: ComponentType) => ({
      removeSelf(returnValue?: ComponentType["returnValueSchema"]): void {
        throw new Error(
          "OverlaysContext: Attempted to remove self outside of OverlayContextComponentProvider"
        );
      },
      updateOwnProps(
        props: Partial<React.ComponentProps<ComponentType>>
      ): void {
        throw new Error(
          "OverlaysContext: Attempted to set own props outside of OverlayContextComponentProvider"
        );
      },
      pushSelf(
        ...[props, context]: PushArgs<
          PropsFromLazy<React.ComponentProps<ComponentType>>,
          PushContext
        >
      ): Promise<ComponentType["returnValueSchema"]> {
        throw new Error(
          "OverlaysContext: Attempted to push self outside of OverlayContextComponentProvider"
        );
      },
      withLayoutProps(
        props: Partial<
          React.ComponentProps<
            ComponentType["Layout"] extends LayoutComponent
              ? ComponentType["Layout"]
              : DefaultLayoutType
          >
        >,
        dependencies: any[]
      ): void {
        throw new Error(
          "OverlaysContext: Attempted to set layout props outside of OverlayContextComponentProvider"
        );
      },
      withBackdropProps(
        props: Partial<
          React.ComponentProps<
            (ComponentType["Layout"] extends LayoutComponent
              ? ComponentType["Layout"]
              : DefaultLayoutType)["Backdrop"]
          >
        >,
        dependencies: any[]
      ): void {
        throw new Error(
          "OverlaysContext: Attempted to set backdrop props outside of OverlayContextComponentProvider"
        );
      },
      isPresent: true,
      safeToRemove(): void {
        throw new Error(
          'OverlaysContext: "safeToRemove" called outside of OverlayContextComponentProvider'
        );
      },
      getRelative(
        delta: number
      ): {
        key: keyof Overlays;
        props: PropsFromLazy<Overlays[keyof Overlays]>;
        id: number;
        Component: ComponentFromLazy<Overlays[keyof Overlays]>;
        isPresent: boolean;
      } {
        throw new Error(
          "OverlaysContext: Attempted to get relative overlay outside of OverlayContextComponentProvider"
        );
      },
      id: -1,
      index: -1,
      isCurrent: false,
    })
  );
}
